<!-- BEGIN: Waiting for confirmation modal -->
<div class="fixed z-50 inset-0 overflow-y-auto" [ngClass]="isWaitingForConfirmationShown == true ? 'block' : 'hidden'" aria-labelledby="modal-title" role="dialog" aria-modal="true">
    <div class="flex items-center md:items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div class="fixed inset-0 bg-[#0f172acc] backdrop-blur-md transition-opacity" aria-hidden="true"></div>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <div class="relative inline-block align-bottom bg-secondary-dark p-6 rounded-[20px] text-left overflow-hidden transform transition-all sm:my-8 sm:align-middle sm:max-w-md">
            <div class="flex justify-between items-center">
                <div class="flex justify-between items-center">
                    <h2 class="font-bold text-primary-light text-base lg:text-lg 2xl:text-xl">Waiting for pools loading
                    </h2>
                </div>
                <!-- <div (click)="toggleModal(false)" class="bg-secondary-black cursor-pointer h-8 w-8 md:h-10 md:w-10 rounded-lg flex justify-center items-center">
                    <img src="assets/icons/x-light.svg" class="m-auto w-4 h-4 md:w-5 md:h-5" alt="bx-finance" />
                </div> -->
            </div>
            <hr class="border-grey border-b-2 border-solid my-3" />
            <div class="w-full flex justify-center my-8">
                <div class="loader animate-spin h-20 w-20"></div>
            </div>

            <div class="text-secondary flex justify-center font-semibold text-lg mt-2 2xl:text-xl text-center">
                Please wait pools are loading from contract</div>
        </div>
    </div>
</div>
<!-- END: Waiting for confirmation modal -->