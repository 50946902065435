import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  constructor(
    private http: HttpClient
  ) { }

  /**
   * Logins authentication service
   * @param params
   * @returns
   */
  public login(params: any) {
    return this.http.post(`${environment.API_BASE_URL}user/login`, params);
  }

  public logout(){
    return this.http.get(`${environment.API_BASE_URL}user/logout`)
  }

  public getUser(walletAddress: string) {
    return this.http.get(`${environment.API_BASE_URL}user/get-user/${walletAddress}`);
  }

  public updateUser(params: any) {
    return this.http.post(`${environment.API_BASE_URL}user/update-user`, params);
  }

  public clearAllUserSession(walletAddress: string) {
    return this.http.get(`${environment.API_BASE_URL}user/clear-session/${walletAddress}`);
  }
}
