import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.css']
})
export class NotFoundComponent implements OnInit {


  storage: any;
  public isScrolled: boolean = false;

  constructor( private router: Router) { }

  ngOnInit(): void {
    this.storage.remove('active_menu');
  
  }
  @HostListener("window:scroll")
  scrollEvent() {
    window.scrollY >= 50 ? (this.isScrolled = true) : (this.isScrolled = false);
  }
  contactUs(){
    this.router.navigate(['/'], { fragment: 'contact-us' });
  }

}
