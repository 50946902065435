import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import {
  MetaMaskService,
  StorageService,
  WalletConnectService,
} from 'ng-blockchainx';
import { ToastrService } from 'ngx-toastr';
import { of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CommonContractService } from '../services/common-contract.service';
import { Router } from '@angular/router';
import detectEthereumProvider from '@metamask/detect-provider';
import { AuthenticationService } from '../services/authentication.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ImageValidationService } from '../services/image-validation.service';
import { emailDomainValidator } from 'src/app/validators/email-domain-validator';

@Component({
  selector: 'app-new-token-navbar',
  templateUrl: './new-token-navbar.component.html',
  styleUrls: ['./new-token-navbar.component.css']
})
export class NewTokenNavbarComponent implements OnInit {

  public isMobileNavExpanded: boolean = false;
  public isScrolled: boolean = false;
  public isTokenDropdownExpanded: boolean = false;
  public isNftDropdownExpanded: boolean = false;
  public isEarnDropdownExpanded: boolean = false;
  public isCommunityDropdownExpanded: boolean = false;
  public isContactUsDropdownExpanded: boolean = false;
  public isMobTokenExpanded: boolean = false;
  public isMobNftExpanded: boolean = false;
  public isMobEarnExpanded: boolean = false;
  public isMobCommunityExpanded: boolean = false;
  public isMobContactExpanded: boolean = false;
  public isConnectWalletOpened: boolean = false;
  public isMetaMaskConnected: boolean = false;
  public isMetamaskInstalled: boolean = false;
  public showWrongNetworkPopup: boolean = false;
  public connectedChainId: string = '';
  public walletAddress: string = '';
  public metaMaskSubscription: any;
  public innerWidth!: number;
  public isChooseNetworkShown: boolean = false;
  public isChooseWrongNetworkShown: boolean = false;
  public ethWeb3: any;
  public userNativeBalance: any;
  public currentNetworkName: any = '';
  public currentNetworkSymbol: any = '';
  public currentNativeCurrency: any = '-';
  public loader: boolean = false;
  public isOnInit: boolean = false;
  public connectObservable: any;
  public userNativeBalanceObservable: any;
  public isPageLoaded: boolean = false;
  public isCreateAccountOpened: boolean = false;
  public networkDetails = {
    chainId: this.commonContractService.networkDetails.chainId,
  };
  public account: any;
  observable: any;
  // public walletType: any;
  public createAccountForm: FormGroup | any;
  public filePreview: any = 'assets/images/profile-avatar.png';
  public submitted: boolean = false;
  public selectedFile: any;
  public processing: boolean = false;
  public allowedDomains: string[] = [
    'yopmail.com',
    'mailinator.com',
    'maildrop.cc',
    'mailnesia.com',
    'sharklasers.com',
    'mailcatch.com',
    'my10minutemail.com',
    'trashmail.fr',
    'trashmail.se'
  ];
  public invalidSessionPopup: boolean = false;
  constructor(
    private router: Router,
    private toastr: ToastrService,
    private metaMaskService: MetaMaskService,
    private storage: StorageService,
    private commonContractService: CommonContractService,
    private authService: AuthenticationService,
    private walletConnectService: WalletConnectService,
    private fb: FormBuilder,
    private imageValidator: ImageValidationService
  ) {
    this.loader = true;
   }

  async ngOnInit() {
    this.createAccountForm = this.fb.group({
      name: ['', [Validators.required, Validators.maxLength(15)]],
      email: ['', [Validators.required, Validators.email, Validators.pattern(/^[^\s@]+@[^\s@]+\.[^\s@]+$/), emailDomainValidator(this.allowedDomains)]],
      wallet_address: ['', Validators.required],
      profile: ['']
    });
    var walletMethod1: boolean = false;
    var walletMethod2: boolean = false;
    this.loader = true;
    this.connectObservable =
      this.commonContractService.connectObservable.subscribe(
        (response: boolean) => {
          if (response) {
            this.isConnectWalletOpened = true;
          }
        }
      );
    this.userNativeBalanceObservable =
      this.commonContractService.userNativeBalance.subscribe((res: any) => {
        this.userNativeBalance = res;
      });

    this.metaMaskService.setSupportedChains(this.networkDetails.chainId);

    // this.account = this.storage.get('account');

    this.observable =
      await this.commonContractService.accountObservable.subscribe(
        async (res: string) => {
          if (res) {
            this.account = await res;
            this.loader = false;
            this.createAccountForm.patchValue({
              'wallet_address': this.account.walletAddress
            });
          }
        }
      );

    this.walletConnectListener();
    if (this.storage.get('walletType') == 2) {
      let account: any = this.storage.get('account');
      account = {
        account: [account.walletAddress],
        chainId: account.chainId,
      };
      this.connectionHandler(account);
      this.validateNetwork(account.chainId);
      this.setNetworkDetails(account);
      this.commonContractService.setAccount({
        walletAddress: account.account[0],
        chainId: account.chainId,
      });
      // to check login,register or already an user
      await this.manageAccount();
    } else {
      walletMethod1 = true;
    }

    let isMetaMaskConnected = this.storage.get('isWalletConnected');

    if (
      (this.account != null || isMetaMaskConnected == true) &&
      (this.storage.get('walletType') == 1 ||
        this.storage.get('walletType') == null)
    ) {
      this.storage.set('walletType', 1);
      this.walletConnection();
    } else {
      walletMethod2 = true;
    }
    if (walletMethod1 && walletMethod2) {
      this.loader = false;
    }
  }


    /**
   * getter function
   */
    get f() {
      return this.createAccountForm.controls;
    }
  
    onKeyDown(event: KeyboardEvent): void {
      const firstChar = (event.target as HTMLInputElement).value.trim()[0];
      if (event.code === 'Space' && !firstChar) {
        event.preventDefault();
      }
    }
  
    /**
     * Determines whether file selected on
     * @param event
     * @returns
     */
    public onFileSelected(event: any) {
      if (!this.imageValidator.validateFile(event.target.files[0].name)) {
        this.toastr.error('This format is not supported');
        return;
      }
      if (event.target.files.length > 0) {
        const file = event.target.files[0];
        this.selectedFile = file;
        const reader = new FileReader();
        reader.onload = (e: any) => {
          this.filePreview = e.target.result;
        };
        reader.readAsDataURL(file);
      }
    }
  
    /**
     * Creates account
     */
    public createAccount() {
      this.submitted = true;
      if (this.createAccountForm.valid) {
        this.processing = true;
        const formData = new FormData();
        formData.append('name', this.createAccountForm.value.name);
        formData.append('email', this.createAccountForm.value.email);
        formData.append('wallet_address', this.createAccountForm.value.wallet_address);
        formData.append('profile', this.selectedFile);
        this.updateUser(formData)
          .then(async (response: any) => {
            console.log('update user response', response);
            this.processing = false;
            this.isCreateAccountOpened = false;
            this.toastr.success('Account created successfully');
          })
          .catch((err) => {
            this.processing = false;
            this.toastr.error(err.data.error.message);
          });
      }
    }
  
    /**
     * Wallet Connect popup opens (QR Code)
     */
    public openWalletConnect() {
      this.walletConnectService.openWalletConnectModal();
    }
  
    public walletConnectListener() {
      this.walletConnectService.connectionListener.subscribe(async (response: any) => {
        if (response.code == 250700) {
          this.logout();
        } else {
          if (response.data['account']) {
            this.closeConnect();
            this.storage.set('walletType', 2);
            this.connectionHandler(response.data);
            this.validateNetwork(response.data.chainId);
            this.setNetworkDetails(response.data);
            this.commonContractService.setAccount({
              walletAddress: this.walletAddress,
              chainId: response.data.chainId,
            });
            // to check login,register or already an user
            await this.manageAccount();
            // window.location.reload();
          }
        }
      });
    }
  
    /**
     * Logouts navbar new component
     */
    public async logout() {
      this.authService.logout().subscribe(
        {
          next: async (response: any) => {
            this.setNativeCurrencyNull();
            this.isMetaMaskConnected = false;
            this.storage.remove('access_token');
            this.storage.set('isWalletConnected', this.isMetaMaskConnected);
            // wallet connect disconnect
            this.storage.remove('walletconnect');
            this.storage.remove('walletType');
            window.location.reload();
          },
          error: (error) => {
            console.log('error', error);
            this.toastr.error(error.data.error.message);
          }
        }
      )
    }
  
    /**
     * connect metamask
     */
    async connectMetaMask() {
      console.log('into meatmask function');
      let provider: any = await detectEthereumProvider();
      // if the provider is not detected, detectEthereumProvider resolves to null
      if (!provider) {
        this.toastr.info('Please install MetaMask or Use DAPP browser');
      }
      // try {
      //   const connection:any = await this.metaMaskService.connectMetaMask().catch((error:any) =>{
      //     console.log(error);
      //   });
      //   if(connection) {
      //     console.log('connect', connection);
      //     this.walletAddress = connection.data[0];
      //     this.storage.set('address', connection);
      //     this.isMetaMaskConnected = true;
      //     this.walletConnection();
      //     window.location.reload();
  
      //   }
      // } catch (exception) {
      //     console.log('exception',exception);
  
      // }
  
      try {
        console.log('into meatmask try');
        this.walletConnection();
        const connection: any = await this.metaMaskService
          .connectMetaMask()
          .catch((error: any) => {
            console.log(error);
            this.toastr.error(error.data.message);
          });
        if (connection) {
          this.storage.set('connection', connection);
          console.log('connection : ', connection)
          this.walletConnection();
          this.isMetaMaskConnected = true;
          this.storage.set('isWalletConnected', this.isMetaMaskConnected);
          window.location.reload();
        }
      } catch (exception) {
        console.log('Exception ', exception);
      }
    }
  
    walletConnection() {
      this.metaMaskSubscription =
        this.metaMaskService.connectionListener.subscribe(
          async (response: any) => {
            if (response.code == 250601) {
              // window.location.reload();
  
              this.connectionHandler(response.data);
              this.validateNetwork(response.data.chainId);
              this.setNetworkDetails(response.data);
              this.commonContractService.setAccount({
                walletAddress: this.walletAddress,
                chainId: response.data.chainId,
              });
  
              // if(!this.isOnInit){
              //   this.isOnInit=true
              //   this.ngOnInit()
              // }
  
              // to check login,register or already an user
              await this.manageAccount();
            }
  
            // to change account
            if (response.code == 250611) {
              // to check login,register or already an user
              await this.manageAccount();
              window.location.reload();
              this.setNetworkDetails(response.data);
            }
  
            if (response.code == 250610) {
              // to check login,register or already an user
              await this.manageAccount();
              // chain changed
              this.storage.set('account', {
                walletAddress: this.walletAddress,
                chainId: response.data.chainId,
              });
              let jsonAccount = {
                walletAddress: this.walletAddress,
                chainId: response.data.chainId,
              }
  
              // await this.router.navigate(['']);
              await window.location.reload();
              await this.setNetworkDetails(response.data);
            }
            if (response.code == 250512) {
              this.setNativeCurrencyNull();
            }
  
            if (response.code == 250641) {
              this.isChooseWrongNetworkShown = true;
            }
          }
        );
    }
  
    /**
     * Manages account
     */
    private async manageAccount() {
      // login or register api call
      if (!this.storage.get('access_token')) {
        await this.login({ wallet_address: this.walletAddress })
          .then(async (response: any) => {
            this.storage.set(
              'access_token',
              response.data.session.session_token
            );
            await this.commonContractService._isUserAuth(
              response.data.session.session_token
            );
            this.getUser(this.walletAddress)
              .then(async (result: any) => {
                const user = result.data;
                if (!user.hasOwnProperty('name')) {
                  this.isCreateAccountOpened = true;
                }
              });
          })
          .catch((err) => {
            this.toastr.error(err.data?.message);
          });
      } else {
        this.getUser(this.walletAddress)
          .then(async (result: any) => {
            const user = result.data;
            const isNotAnUser = this.imageValidator.isEmptyObject(user);
            if (isNotAnUser) {
              await this.login({ wallet_address: this.walletAddress })
                .then(async (response: any) => {
                  this.storage.set(
                    'access_token',
                    response.data.session.session_token
                  );
                  await this.commonContractService._isUserAuth(
                    response.data.session.session_token
                  );
                })
                .catch((err) => {
                  this.toastr.error(err.message);
                });
            }
            if (!user.hasOwnProperty('name')) {
              this.isCreateAccountOpened = true;
            }
          })
          .catch((err) => {
            // need to open invalid session popup
            if (err.data.status === 401) {
              this.invalidSessionPopup = true;
            }
            this.toastr.error(err.data?.message);
          });
      }
    }
  
    /**
     * Logins navbar component
     * @param params
     * @returns
     */
    private async login(params: any) {
      return new Promise((resolve, reject) => {
        this.authService.login(params).subscribe({
          next: (response: any) => {
            resolve({ status: true, data: response.data });
          },
          error: (error) => {
            reject({ status: false, data: error });
          },
        });
      });
    }
  
    /**
     * get user details
     * @param params
     * @returns
     */
    private async getUser(params: any) {
      return new Promise((resolve, reject) => {
        this.authService.getUser(params).subscribe({
          next: (response: any) => {
            resolve({ status: true, data: response.data });
          },
          error: (error) => {
            reject({ status: false, data: error });
          },
        });
      });
    }
  
  
    /**
     * Updates user
     * @param params
     * @returns
     */
    private async updateUser(params: any) {
      return new Promise((resolve, reject) => {
        this.authService.updateUser(params).subscribe({
          next: (response: any) => {
            resolve({ status: true, data: response.data });
          },
          error: (error) => {
            reject({ status: false, data: error });
          },
        });
      });
    }
  
    validateNetwork(chainId: string) {
      if (environment.ALLOWED_NETWORKS.indexOf(chainId) == -1) {
        this.showWrongNetworkPopup = true;
      }
    }
  
    async changeNetwork(index: any) {
      try {
        // this.loader=true
        // this.isChooseWrongNetworkShown = false
        const chainInfo = await this.metaMaskService.chainDetails(
          environment.ALLOWED_NETWORKS[index]
        );
        console.log('chain info', chainInfo);
        const chainIdToHex = this.commonContractService.ethWeb3.utils.toHex(
          chainInfo.chainId
        );
        const addTokenParam = {
          method: 'wallet_addEthereumChain',
          params: [
            {
              chainId: chainIdToHex,
              chainName: chainInfo.name,
              nativeCurrency: chainInfo.nativeCurrency,
              rpcUrls: chainInfo.rpc,
              blockExplorerUrls: chainInfo.explorers.url,
            },
          ],
        };
        console.log('addTokenParam', addTokenParam);
        this.metaMaskService.addNetwork(addTokenParam);
        if (chainInfo) {
          this.metaMaskService.changeNetwork(environment.ALLOWED_NETWORKS[index]);
        }
      } catch (error) {
        this.toastr.error('error');
      }
    }
  
    connectionHandler(account: any) {
      this.connectedChainId = account.chainId;
      this.walletAddress = account.account[0];
  
      this.storage.set('account', {
        walletAddress: this.walletAddress,
        chainId: this.connectedChainId,
      });
      let jsonAccount = {
        walletAddress: this.walletAddress,
        chainId: this.connectedChainId,
      }
      this.commonContractService.sendWalletAddress(this.walletAddress);
      //  this.nativeBalance();
      this.isMetaMaskConnected = true;
      this.storage.set('isWalletConnected', this.isMetaMaskConnected);
    }
  
    async setNetworkDetails(response: any) {
      let networkDetailsIndex = this.networkDetails.chainId.indexOf(
        response.chainId
      );
      if (networkDetailsIndex >= 0) {
        await this.commonContractService.currentNetworkDetails(
          networkDetailsIndex
        );
        this.currentNativeCurrency =
          this.commonContractService.currentNativeCurrency;
        this.storage.set('nativeCurrency', this.currentNativeCurrency);
        this.currentNetworkName = this.commonContractService.currentNetworkName;
        this.currentNetworkSymbol =
          this.commonContractService.currentNetworkSymbol;
        this.isChooseNetworkShown = false;
      } else {
        // // chain changed
        this.currentNetworkName = 'NotSupport';
        this.currentNetworkSymbol = '/assets/icons/navbar/unsupported.svg';
        this.currentNativeCurrency = '';
        this.isChooseNetworkShown = true;
      }
    }
  
    out() {
      this.isEarnDropdownExpanded = !this.isEarnDropdownExpanded;
    }
  
    // async nativeBalance() {
    //   try {
    //     let userNativeBalance =
    //       await this.commonContractService.getNativeCurrencyBalance();
    //     this.userNativeBalance = parseFloat(userNativeBalance).toFixed(4);
    //   } catch {
    //     return;
    //   }
    // }
  
    async ngOnDestroy() {
      this.connectObservable?.unsubscribe();
      this.userNativeBalanceObservable?.unsubscribe();
      this.observable?.unsubscribe();
    }
    setNativeCurrencyNull() {
      this.storage.remove('connection');
      this.storage.remove('account');
      this.storage.set('nativeCurrency', '-');
      this.storage.set('isWalletConnected', false);
    }
  
    toggleMobileNav() {
      this.isMobileNavExpanded = !this.isMobileNavExpanded;
    }
    @HostListener('window:scroll')
    scrollEvent() {
      window.scrollY >= 50 ? (this.isScrolled = true) : (this.isScrolled = false);
    }
    toggleTokenDropdown() {
      this.isTokenDropdownExpanded = !this.isTokenDropdownExpanded;
    }
    toggleNftDropdown() {
      this.isNftDropdownExpanded = !this.isNftDropdownExpanded;
    }
    toggleEarnDropdown() {
      this.isEarnDropdownExpanded = !this.isEarnDropdownExpanded;
    }
    /**
     * Dropdown Close
     * @param {string} type
     */
    close(type: string) {
      switch (type) {
        case 'mob-token':
          this.isMobTokenExpanded = false;
          break;
        case 'mob-nft':
          this.isMobNftExpanded = false;
          break;
        case 'mob-earn':
          this.isMobEarnExpanded = false;
          break;
        case 'mob-community':
          this.isMobCommunityExpanded = false;
          break;
        case 'mob-contact':
          this.isMobContactExpanded = false;
          break;
      }
    }
    closeConnect() {
      this.isConnectWalletOpened = false;
      this.isCreateAccountOpened = false;
    }
    chooseNetwork() {
      this.isChooseNetworkShown = true;
    }
    closeChooseNetwork() {
      this.isChooseNetworkShown = false;
    }
    contactUs() {
      this.router.navigate(['/'], { fragment: 'contact-us' });
    }
    goToLink(url: string) {
      window.open(url, '_blank');
    }

}
