<div [ngClass]="{ 'bg-secondary-black': isScrolled }">
  <nav (clickOutside)="isMobileNavExpanded = false"
    class="fixed top-0 inset-x-0 z-50 border-[#D9D9D9]/50 border-b-[.75px] border-solid">
    <div class="container mx-auto">
      <div class="relative flex items-center justify-between h-20 lg:h-20 py-8 lg:py-12">
        <div class="absolute inset-y-0 left-4 flex items-center md:hidden">
          <!-- Mobile menu button-->
          <button (click)="toggleMobileNav()" type="button"
            class="inline-flex items-center justify-center p-2 rounded-md hover:text-white focus:outline-none mobile-toggle"
            aria-controls="mobile-menu" aria-expanded="false">
            <span class="sr-only">Open main menu</span>
            <svg class="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="#fff"
              aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
            </svg>

            <svg class="hidden h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
              stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
        <div class="w-full flex items-center justify-between gap-1 xl:gap-2 2xl:gap-4 px-4">
          <div class="flex-shrink-0 flex md:items-center lg:w-28 xl:w-40">
            <a routerLink="/">
              <img class="block lg:hidden h-8 w-auto m-logo sm:ml-12 md:ml-0" src="assets/images/logo-sm.svg"
                alt="bx-finance" />
            </a>
            <a routerLink="/">
              <img class="lg:block hidden w-full h-full" src="assets/images/logo.svg" alt="bx-finance" /></a>
          </div>
          <div class="hidden md:block lg:w-6/12 xl:w-3/6">
            <div class="flex justify-center items-center gap-2 2xl:gap-3 sibling-fade">
              <!-- Current: "text-white bg-primary", Default: "text-secondary hover:bg-primary hover:text-white" -->

              <div (clickOutside)="isTokenDropdownExpanded = false" class="relative dropdown" routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }">
                <!-- <a  class="cursor-pointer text-secondary hover:text-white hover:bg-primary px-5 py-2 rounded-full font-medium">Token</a>  -->
                <a (click)="toggleTokenDropdown()"
                  class="cursor-pointer rounded-lg text-white px-2 xl:px-3 2xl:px-4 py-2 text-xs xl:text-base mi--font-regular nav__menu--item">Token</a>
                <div [ngClass]="{
                    hidden: !isTokenDropdownExpanded,
                    block: 'isTokenDropdownExpanded'
                  }" class="dropdown-menu absolute top-12 bg-secondary-dark p-5 rounded-xl mt-2 w-[25rem]">
                  <div class="grid grid-cols-12 gap-4 lg:gap-6">
                    <div class="col-span-12">
                      <a routerLink="/token-generator" routerLinkActive="active"
                        [routerLinkActiveOptions]="{ exact: true }">
                        <div class="flex items-center gap-3 pb-4">
                          <img src="assets/landing-new/icons/navbar/token-generator.svg" class="w-12 h-12"
                            alt="bx-finance" />
                          <span class="cursor-pointer w-[60%]">
                            <h2 class="mi--font-regular text-white text-sm tracking-[1px]">
                              Token Generator
                            </h2>
                            <p class="font-bold text-secondary text-xs lg:text-sm">
                              Create an ERC20 Token in a minute
                            </p>
                          </span>
                        </div>
                      </a>
                      <a routerLink="/migration" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
                        <div class="flex items-center gap-3 pb-4">
                          <img src="assets/landing-new/icons/navbar/token-migrator.svg" class="w-12 h-12"
                            alt="bx-finance" />
                          <div class="flex items-start w-full justify-between gap-2">
                            <span class="cursor-pointer w-[60%]">
                              <h2 class="mi--font-regular text-white text-sm tracking-[1px]">
                                Token Migrator
                              </h2>
                              <p class="font-bold text-secondary text-xs lg:text-sm">
                                Convert old tokens to new tokens
                              </p>
                            </span>
                            <!-- <span class="inline-flex items-center justify-center px-2 py-1 text-xs font-medium text-[#d9d9d9] bg-[#4E1FA3]/30 rounded">Coming Soon</span>  -->
                          </div>
                        </div>
                      </a>
                      <a routerLink="/airdrop" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
                        <div class="flex items-center gap-3 pb-4">
                          <img src="assets/landing-new/icons/navbar/airdrop-tokens.svg" class="w-12 h-12"
                            alt="bx-finance" />
                          <div class="flex items-start w-full justify-between gap-2">
                            <span class="cursor-pointer w-[60%]">
                              <h2 class="mi--font-regular text-white text-sm tracking-[1px]">
                                Airdrop Tokens
                              </h2>
                              <p class="font-bold text-secondary text-xs lg:text-sm">
                                Send tokens to multiple users
                              </p>
                            </span>
                            <!-- <span
                              class="inline-flex items-center justify-center px-2 py-1 text-xs font-medium text-[#d9d9d9] bg-[#4E1FA3]/30 rounded">Coming
                              Soon</span> -->
                          </div>
                        </div>
                      </a>
                      <a routerLink="/launchpad" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
                        <div class="flex items-center gap-3 pb-4">
                          <img src="assets/landing-new/icons/navbar/token-launchpad.svg" class="w-12 h-12"
                            alt="bx-finance" />
                          <div class="flex items-start w-full justify-between gap-2">
                            <span class="cursor-pointer w-[60%]">
                              <h2 class="mi--font-regular text-white text-sm tracking-[1px]">
                                Token Launchpad
                              </h2>
                              <p class="font-bold text-secondary text-xs lg:text-sm">
                                Get in early on latest gems
                              </p>
                            </span>
                            <!-- <span
                              class="inline-flex items-center justify-center px-2 py-1 text-xs font-medium text-[#d9d9d9] bg-[#4E1FA3]/30 rounded">Coming
                              Soon</span> -->
                          </div>
                        </div>
                      </a>
                      <a routerLink="/locker" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
                        <div class="flex items-center gap-3 pb-4">
                          <img src="assets/landing-new/icons/navbar/token-locker.svg" class="w-12 h-12"
                            alt="bx-finance" />
                          <div class="flex items-start w-full justify-between gap-2">
                            <span class="cursor-pointer w-[60%]">
                              <h2 class="mi--font-regular text-white text-sm tracking-[1px]">
                                Token/LP Locker
                              </h2>
                              <p class="font-bold text-secondary text-xs lg:text-sm">
                                Lock your tokens securely
                              </p>
                            </span>
                            <!-- <span
                              class="inline-flex items-center justify-center px-2 py-1 text-xs font-medium text-[#d9d9d9] bg-[#4E1FA3]/30 rounded">Coming
                              Soon</span> -->
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div (clickOutside)="isNftDropdownExpanded = false" class="relative dropdown" routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }">
                <a (click)="toggleNftDropdown()"
                  class="cursor-pointer rounded-lg text-white px-2 xl:px-3 2xl:px-4 py-2 text-xs xl:text-base mi--font-regular nav__menu--item">NFT</a>
                <div [ngClass]="{
                    hidden: !isNftDropdownExpanded,
                    block: 'isNftDropdownExpanded'
                  }" class="dropdown-menu absolute top-12 bg-secondary-dark p-5 rounded-xl mt-2 w-[25rem]">
                  <div class="grid grid-cols-12 gap-4 lg:gap-6">
                    <div class="col-span-12">
                      <a href="javascript:">
                        <div class="flex items-center gap-3 pb-4">
                          <img src="assets/landing-new/icons/navbar/nft-generator.svg" class="w-12 h-12"
                            alt="bx-finance" />
                          <div class="flex items-start w-full justify-between gap-2">
                            <span class="cursor-pointer w-[60%]">
                              <h2 class="mi--font-regular text-white text-sm tracking-[1px]">
                                NFT Collection
                              </h2>
                              <p class="font-bold text-secondary text-xs 2xl:text-sm">
                                Explore NFT collections.
                              </p>
                            </span>
                            <span
                              class="inline-flex items-center justify-center px-2 py-1 text-xs font-medium text-[#d9d9d9] bg-[#4E1FA3]/30 rounded">Coming
                              Soon</span>
                          </div>
                        </div>
                      </a>
                      <a routerLink="/nft-single-mint" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
                        <div class="flex items-center gap-3 pb-4">
                          <img src="assets/landing-new/icons/navbar/nft-generator.svg" class="w-12 h-12"
                            alt="bx-finance" />
                          <div class="flex items-start w-full justify-between gap-2">
                            <span class="cursor-pointer w-[60%]">
                              <h2 class="mi--font-regular text-white text-sm tracking-[1px]">
                                NFT Generator
                              </h2>
                              <p class="font-bold text-secondary text-xs 2xl:text-sm">
                                Mint NFT's in minutes.
                              </p>
                            </span>
                            <!-- <span
                              class="inline-flex items-center justify-center px-2 py-1 text-xs font-medium text-[#d9d9d9] bg-[#4E1FA3]/30 rounded">Coming
                              Soon</span> -->
                          </div>
                        </div>
                      </a>
                      <a routerLink="/nft-bulk-mint" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
                        <div class="flex items-center gap-3 pb-4">
                          <img src="assets/landing-new/icons/navbar/airdrop-tokens.svg" class="w-12 h-12"
                            alt="bx-finance" />
                          <div class="flex items-start w-full justify-between gap-2">
                            <span class="cursor-pointer w-[60%]">
                              <h2 class="mi--font-regular text-white text-sm tracking-[1px]">
                                NFT Bulk Mint
                              </h2>
                              <p class="font-bold text-secondary text-xs 2xl:text-sm">
                                Launch collections in minutes.
                              </p>
                            </span>
                            <!-- <span
                              class="inline-flex items-center justify-center px-2 py-1 text-xs font-medium text-[#d9d9d9] bg-[#4E1FA3]/30 rounded">Coming
                              Soon</span> -->
                          </div>
                        </div>
                      </a>
                      <div class="flex items-center gap-3 pb-4">
                        <img src="assets/landing-new/icons/navbar/nft-launchpad.svg" class="w-12 h-12"
                          alt="bx-finance" />
                        <div class="flex items-start w-full justify-between gap-2">
                          <span class="cursor-pointer w-[60%]">
                            <h2 class="mi--font-regular text-white text-sm tracking-[1px]">
                              NFT Launchpad
                            </h2>
                            <p class="font-bold text-secondary text-xs 2xl:text-sm">
                              Access latest NFT Projects.
                            </p>
                          </span>
                          <span
                            class="inline-flex items-center justify-center px-2 py-1 text-xs font-medium text-[#d9d9d9] bg-[#4E1FA3]/30 rounded">Coming
                            Soon</span>
                        </div>
                      </div>
                      <div class="flex items-center gap-3 pb-4">
                        <img src="assets/landing-new/icons/navbar/nft-locker.svg" class="w-12 h-12" alt="bx-finance" />
                        <div class="flex items-start w-full justify-between gap-2">
                          <span class="cursor-pointer w-[60%]">
                            <h2 class="mi--font-regular text-white text-sm tracking-[1px]">
                              NFT Locker
                            </h2>
                            <p class="font-bold text-secondary text-xs 2xl:text-sm">
                              Safely lock your NFTs.
                            </p>
                          </span>
                          <span
                            class="inline-flex items-center justify-center px-2 py-1 text-xs font-medium text-[#d9d9d9] bg-[#4E1FA3]/30 rounded">Coming
                            Soon</span>
                        </div>
                      </div>
                      <a href="javascript:">
                        <div class="flex items-center gap-3">
                          <img src="assets/landing-new/icons/navbar/airdrop-nfts.svg" class="w-12 h-12"
                            alt="bx-finance" />
                          <div class="flex items-start w-full justify-between gap-2">
                            <span class="cursor-pointer w-[60%]">
                              <h2 class="mi--font-regular text-white text-sm tracking-[1px]">
                                Airdrop NFTs
                              </h2>
                              <p class="font-bold text-secondary text-xs 2xl:text-sm">
                                Distribute NFTs easily.
                              </p>
                            </span>
                            <span
                              class="inline-flex items-center justify-center px-2 py-1 text-xs font-medium text-[#d9d9d9] bg-[#4E1FA3]/30 rounded">Coming
                              Soon</span>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="relative dropdown" (clickOutside)="isEarnDropdownExpanded = false" routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }">
                <a (click)="toggleEarnDropdown()"
                  class="cursor-pointer rounded-lg text-white px-2 xl:px-3 2xl:px-4 py-2 text-xs xl:text-base mi--font-regular nav__menu--item">Earn</a>
                <div class="absolute top-12 bg-secondary-dark p-5 rounded-xl mt-2 w-[26rem] dropdown-menu">
                  <a routerLink="/farming" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
                    <div class="flex items-center gap-3 pb-4">
                      <img src="assets/landing-new/icons/navbar/farming.svg" class="w-12 h-12" alt="bx-finance" />
                      <span class="cursor-pointer w-[50%]">
                        <h2 class="mi--font-regular text-white text-sm tracking-[1px]">
                          Farming
                        </h2>
                        <p class="font-bold text-secondary text-xs lg:text-sm">
                          Access latest farming pools.
                        </p>
                      </span>
                      <!-- <span
                        class="inline-flex items-center justify-center px-2 py-1 text-xs font-medium text-[#d9d9d9] bg-[#4E1FA3]/30 rounded">Coming
                        Soon</span> -->
                    </div>
                  </a>
                  <a routerLink="/stake" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
                    <div class="flex items-center gap-3">
                      <img src="assets/landing-new/icons/navbar/staking.svg" class="w-12 h-12" alt="bx-finance" />
                      <span placement="top" class="cursor-pointer w-[50%]">
                        <h2 class="mi--font-regular text-white text-sm tracking-[1px]">
                          Staking
                        </h2>
                        <p class="font-bold text-secondary text-xs lg:text-sm">
                          Access latest staking pools.
                        </p>
                      </span>
                      <!-- <span
                        class="inline-flex items-center justify-center px-2 py-1 text-xs font-medium text-[#d9d9d9] bg-[#4E1FA3]/30 rounded">Coming
                        Soon</span> -->
                    </div>
                  </a>
                </div>
              </div>
              <div class="relative dropdown" (click)="this.isCommunityDropdownExpanded = false"
                routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
                <a
                  class="cursor-pointer rounded-lg text-white px-2 xl:px-3 2xl:px-4 py-2 text-xs xl:text-base mi--font-regular nav__menu--item">Community</a>
                <div [ngClass]="{
                    hidden: !isCommunityDropdownExpanded,
                    block: 'isCommunityDropdownExpanded'
                  }" class="absolute top-12 bg-secondary-dark p-5 rounded-xl mt-2 w-[20rem] dropdown-menu">
                  <a (click)="goToLink('https://t.me/+tdk1G16Fs8RhZGJl')" routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }">
                    <div class="flex items-center gap-3 pb-4">
                      <img src="assets/landing-new/icons/social-media/telegram-square.svg" class="w-12 h-12 rounded-lg"
                        alt="bx-finance" />
                      <span class="cursor-pointer">
                        <h2 class="mi--font-regular text-white text-sm tracking-[1px]">
                          Telegram
                        </h2>
                        <!-- <p class="font-bold text-secondary text-xs lg:text-sm">Earn double reward on
                          BX-Finance</p> -->
                      </span>
                    </div>
                  </a>
                  <a (click)="goToLink('https://discord.gg/5BBkdEQrVT')" routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }">
                    <div class="flex items-center gap-3 pb-4">
                      <img src="assets/landing-new/icons/social-media/discord-square.svg" class="w-12 h-12 rounded-lg"
                        alt="bx-finance" />
                      <span placement="top" class="cursor-pointer">
                        <h2 class="mi--font-regular text-white text-sm tracking-[1px]">
                          Discord
                        </h2>
                        <!-- <p class="font-bold text-secondary text-xs lg:text-sm">Easy Stake and High
                          Returns</p> -->
                      </span>
                    </div>
                  </a>
                  <a (click)="goToLink('https://twitter.com/bxfinance')" routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }">
                    <div class="flex items-center gap-3">
                      <img src="assets/landing-new/icons/social-media/twitter-square.svg" class="w-12 h-12 rounded-lg"
                        alt="bx-finance" />
                      <span placement="top" class="cursor-pointer">
                        <h2 class="mi--font-regular text-white text-sm tracking-[1px]">
                          Twitter
                        </h2>
                        <!-- <p class="font-bold text-secondary text-xs lg:text-sm">Easy Stake and High
                          Returns</p> -->
                      </span>
                    </div>
                  </a>
                </div>
              </div>
              <div class="relative dropdown" (click)="this.isContactUsDropdownExpanded = false"
                routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
                <a
                  class="cursor-pointer rounded-lg text-white px-2 xl:px-3 2xl:px-4 py-2 text-xs xl:text-base mi--font-regular nav__menu--item">Contact</a>
                <div [ngClass]="{
                    hidden: !isContactUsDropdownExpanded,
                    block: 'isContactUsDropdownExpanded'
                  }" class="absolute top-12 bg-secondary-dark p-5 rounded-xl mt-2 w-[20rem] dropdown-menu">
                  <a (click)="contactUs()" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
                    <div class="flex items-center gap-3 pb-4">
                      <img src="assets/icons/talk.svg" class="w-12 h-12 rounded-lg" alt="bx-finance" />
                      <span class="cursor-pointer">
                        <h2 class="mi--font-regular text-white text-sm tracking-[1px]">
                          Talk to us
                        </h2>
                      </span>
                    </div>
                  </a>
                  <a (click)="
                      goToLink(
                        'https://docs.google.com/forms/d/e/1FAIpQLScqhWg7-7PNFhjSJJud99TKSBGb4BiJJZhOvmp4I3qOMlUStw/viewform'
                      )
                    " routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
                    <div class="flex items-center gap-3 pb-4">
                      <img src="assets/icons/audit.svg" class="w-12 h-12 rounded-lg" alt="bx-finance" />
                      <span placement="top" class="cursor-pointer">
                        <h2 class="mi--font-regular text-white text-sm tracking-[1px]">
                          Request for Audit Report
                        </h2>
                      </span>
                    </div>
                  </a>
                </div>
              </div>
              <!-- <div class="dropdown">
                <a (click)="contactUs()"
                  class="cursor-pointer rounded-lg text-white px-2 xl:px-3 2xl:px-4 py-2 text-xs xl:text-base mi--font-regular nav__menu--item">Contact</a>
              </div> -->
              <div class="dropdown">
                <a href="https://docs.blockchainx.finance/bx-finance/" target="_blank"
                  class="cursor-pointer rounded-lg text-white px-2 xl:px-3 2xl:px-4 py-2 text-xs xl:text-base mi--font-regular nav__menu--item">Docs</a>
              </div>
            </div>
          </div>

          <button *ngIf="!isMetaMaskConnected" (click)="this.isConnectWalletOpened = true"
            class="bg-[#4E1FA3]/30 border-[#4E1FA3] border border-solid flex justify-center items-center hover:shadow-btn transition ease-in-out duration-700 text-white sy--font-medium py-2 px-4 md:py-2 md:px-8 rounded-lg focus:outline-none text-xs md:text-sm xl:text-lg">
            Connect Wallet
          </button>
          <button *ngIf="isMetaMaskConnected" type="button"
            class="bg-[#4E1FA3]/30 border-[#4E1FA3] border-solid border text-white sy--font-medium p-2 xl:px-4 rounded-lg focus:outline-none text-xs xl:text-sm hidden lg:flex gap-2 items-center"
            (click)="chooseNetwork()">
            <img class="w-6 h-6 rounded-full" src="{{ currentNetworkSymbol }}" alt="bx-finance" />
            <span class="truncate w-20 xl:w-24 2xl:w-32" tooltipClass="tooltip-truncate"
              tooltip="{{ currentNetworkName }}" placement="top">
              {{ currentNetworkName }}</span>
          </button>
          <!-- <button *ngIf="isMetaMaskConnected" type="button"
            class="bg-[#4E1FA3]/30 border-[#4E1FA3] border-solid border text-white sy--font-medium p-2 xl:px-4 rounded-lg focus:outline-none w-32 lg:w-40 xl:w-48 text-xs xl:text-sm hidden lg:flex gap-2 items-center">
            <img class="w-6 h-6 rounded-full" src="{{currentNetworkSymbol}}" alt="bx-finance">
            <span class="truncate" tooltipClass="tooltip-truncate" tooltip="{{currentNetworkName}}" placement="top">
              {{currentNetworkName}}</span>
          </button> -->

          <button *ngIf="isMetaMaskConnected && !showWrongNetworkPopup" type="button"
            class="bg-secondary-dark border border-[#4E1FA3] border-solid hidden lg:flex items-center text-white sy--font-medium px-2 text-sm rounded-lg focus:outline-none truncate">
            <div style="filter: drop-shadow(0 0 20px #4e1fa3)"
              class="w-8 h-8 border-l-0 border bg-[#4E1FA3] border-[#4E1FA3] border-solid rounded-md m-1">
              <svg xmlns="http://www.w3.org/2000/svg" class="m-2 w-4 h-4" viewBox="0 0 26.154 22.885">
                <path id="Icon_awesome-wallet" data-name="Icon awesome-wallet"
                  d="M23.559,7.154H4.087a.817.817,0,1,1,0-1.635H23.7a.817.817,0,0,0,.817-.817A2.452,2.452,0,0,0,22.067,2.25H3.269A3.269,3.269,0,0,0,0,5.519V21.865a3.269,3.269,0,0,0,3.269,3.269h20.29a2.529,2.529,0,0,0,2.595-2.452V9.606A2.529,2.529,0,0,0,23.559,7.154ZM21.25,17.779a1.635,1.635,0,1,1,1.635-1.635A1.635,1.635,0,0,1,21.25,17.779Z"
                  transform="translate(0 -2.25)" fill="#ffffff" />
              </svg>
            </div>
            <div>
              <p class="w-20 xl:w-24 2xl:w-32 truncate text-primary-light">
                {{ walletAddress.slice(0, 5) }}...{{
                walletAddress.slice(walletAddress.length - 5)
                }}
              </p>
              <p class="text-xs text-white font-normal text-right">
                {{ userNativeBalance }} {{ currentNativeCurrency }}
              </p>
            </div>
          </button>
          <div class="flex gap-2" *ngIf="isMetaMaskConnected">
            <!-- <button type="button" *ngIf="isMetaMaskConnected" class="block lg:hidden" (click)="chooseNetwork()">
              <img src="assets/icons/globe-light.svg" class="w-6 h-6" alt="bx-finance">
            </button> -->
            <button *ngIf="isMetaMaskConnected" type="button" placement="bottom" (click)="chooseNetwork()"
              class="block lg:hidden" tooltip="<p class='truncate text-primary-light'>{{
                currentNetworkName
              }}</p>">
              <img src="{{ currentNetworkSymbol }}" class="w-5 h-5 m-2 rounded-full" alt="" />
            </button>

            <button *ngIf="isMetaMaskConnected && !showWrongNetworkPopup" type="button" placement="bottom"
              class="block lg:hidden" tooltip="<p class='w-20 xl:w-24 2xl:w-32 truncate text-primary-light'>{{
                walletAddress.slice(0, 5)
              }}...{{
                walletAddress.slice(walletAddress.length - 5)
              }}</p><p class='text-xs text-white font-normal'>{{
                userNativeBalance
              }} {{ currentNativeCurrency }}</p>">
              <img src="assets/icons/connect-wallet.svg" class="w-5 h-5 m-2" alt="" />
            </button>
            <button type="button" *ngIf="isMetaMaskConnected" (click)="logout()">
              <img src="assets/icons/log-out.svg" class="w-5 h-5 lg:w-8 lg:h-8 m-2" alt="" />
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Mobile menu, show/hide based on menu state. -->
    <div [ngClass]="{
        hidden: !isMobileNavExpanded,
        'block overflow-y-auto': isMobileNavExpanded
      }" class="md:hidden" id="mobile-menu">
      <div class="bg-secondary-dark">
        <!-- Current: "bg-primary text-white", Default: "text-secondary hover:bg-primary hover:text-white" -->
        <div routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
          <a (clickOutside)="close('mob-token')" (click)="isMobTokenExpanded = !isMobTokenExpanded" [ngClass]="{
              'bg-secondary-dark text-secondary': !isMobTokenExpanded,
              'bg-secondary-dark text-white rounded-md': 'isMobTokenExpanded'
            }" class="flex justify-between items-center p-3 rounded-md text-base font-medium" aria-current="page">
            Token
            <img src="assets/icons/chevron-down-light.svg" class="w-5 h-5" alt="bx-finance" />
          </a>
          <div [ngClass]="{
              hidden: !isMobTokenExpanded,
              'block bg-secondary-dark rounded-md': 'isMobTokenExpanded'
            }">
            <span class="cursor-pointer">
              <a routerLink="/token-generator">
                <div class="flex items-center px-3 py-3 gap-3" routerLinkActive="active"
                  [routerLinkActiveOptions]="{ exact: true }">
                  <img src="assets/landing-new/icons/navbar/token-generator.svg" class="w-6 h-6" alt="bx-finance" />
                  <div class="flex items-center justify-between w-full">
                    <p class="text-white hover:text-white block rounded-md text-base font-medium">
                      Token Generator
                    </p>
                  </div>
                </div>
              </a>
            </span>
            <span class="cursor-pointer">
              <a routerLink="/migration">
                <div class="flex items-center px-3 py-3 gap-3" routerLinkActive="active"
                  [routerLinkActiveOptions]="{ exact: true }">
                  <img src="assets/landing-new/icons/navbar/token-migrator.svg" class="w-6 h-6" alt="bx-finance" />
                  <!-- <div class="text-white hover:text-white block  rounded-md text-base font-medium">
                    Token Migrator
                  </div> -->
                  <div class="flex items-center justify-between w-full">
                    <p class="text-white hover:text-white block rounded-md text-base font-medium">
                      Token Migrator
                    </p>
                  </div>
                </div>
              </a>
            </span>
            <span class="cursor-pointer">
              <a routerLink="/airdrop">
                <div class="flex items-center px-3 py-3 gap-3" routerLinkActive="active"
                  [routerLinkActiveOptions]="{ exact: true }">
                  <img src="assets/landing-new/icons/navbar/airdrop-tokens.svg" class="w-6 h-6" alt="bx-finance" />
                  <!-- <div class="text-white hover:text-white block  rounded-md text-base font-medium">
                    Airdrop Tokens
                  </div> -->
                  <div class="flex items-center justify-between w-full">
                    <p class="text-white hover:text-white block rounded-md text-base font-medium">
                      Airdrop Tokens
                    </p>
                  </div>
                </div>
              </a>
            </span>
            <span class="cursor-pointer">
              <a href="javascript:">
                <div class="flex items-center px-3 py-3 gap-3" routerLinkActive="active"
                  [routerLinkActiveOptions]="{ exact: true }">
                  <img src="assets/landing-new/icons/navbar/token-launchpad.svg" class="w-6 h-6" alt="bx-finance" />
                  <div class="flex items-center justify-between w-full">
                    <p class="text-white hover:text-white block rounded-md text-base font-medium">
                      Token Launchpad
                    </p>
                    <span
                      class="inline-flex items-center justify-center px-2 py-1 text-xs font-medium text-[#d9d9d9] bg-[#4E1FA3]/30 rounded">Coming
                      Soon</span>
                  </div>
                </div>
              </a>
            </span>
            <span class="cursor-pointer">
              <div class="flex items-center px-3 py-3 gap-3">
                <img src="assets/landing-new/icons/navbar/token-locker.svg" class="w-6 h-6" alt="bx-finance" />
                <!-- <div>
                  <h2 class="text-white hover:text-white block  rounded-md text-base font-medium">Token LP
                    Locker</h2>
                </div> -->
                <div class="flex items-center justify-between w-full">
                  <p class="text-white hover:text-white block rounded-md text-base font-medium">
                    Token/LP Locker
                  </p>
                  <span
                    class="inline-flex items-center justify-center px-2 py-1 text-xs font-medium text-[#d9d9d9] bg-[#4E1FA3]/30 rounded">Coming
                    Soon</span>
                </div>
              </div>
            </span>
          </div>
        </div>

        <div routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
          <a (clickOutside)="close('mob-nft')" (click)="isMobNftExpanded = !isMobNftExpanded" [ngClass]="{
              'bg-secondary-dark text-secondary': !isMobNftExpanded,
              'bg-secondary-dark text-white rounded-md': 'isMobNftExpanded'
            }" class="flex justify-between items-center p-3 rounded-md text-base font-medium" aria-current="page">
            <!-- <a [ngClass]="{'bg-secondary-black text-secondary' : !isMobNftExpanded, 'bg-secondary-light text-white rounded-md' : 'isMobNftExpanded'}" class="flex justify-between items-center p-3 rounded-md text-base font-medium" aria-current="page"> -->
            NFT
            <img src="assets/icons/chevron-down-light.svg" class="w-5 h-5" alt="bx-finance" />
          </a>
          <div [ngClass]="{
              hidden: !isMobNftExpanded,
              'block bg-secondary-dark rounded-md': 'isMobNftExpanded'
            }">
            <span class="cursor-pointer">
              <a>
                <div class="flex items-center px-3 py-3 gap-3" routerLinkActive="active"
                  [routerLinkActiveOptions]="{ exact: true }">
                  <img src="assets/landing-new/icons/navbar/nft-generator.svg" class="w-6 h-6" alt="bx-finance" />
                  <div class="flex items-center justify-between w-full">
                    <p class="text-white hover:text-white block rounded-md text-base font-medium">
                      NFT Collection
                    </p>
                    <span
                      class="inline-flex items-center justify-center px-2 py-1 text-xs font-medium text-[#d9d9d9] bg-[#4E1FA3]/30 rounded">Coming
                      Soon</span>
                  </div>
                </div>
              </a>
            </span>

            <span class="cursor-pointer">
              <a>
                <div class="flex items-center px-3 py-3 gap-3" routerLinkActive="active"
                  [routerLinkActiveOptions]="{ exact: true }">
                  <img src="assets/landing-new/icons/navbar/nft-generator.svg" class="w-6 h-6" alt="bx-finance" />
                  <div class="flex items-center justify-between w-full">
                    <p class="text-white hover:text-white block rounded-md text-base font-medium">
                      NFT Generator
                    </p>
                    <span
                      class="inline-flex items-center justify-center px-2 py-1 text-xs font-medium text-[#d9d9d9] bg-[#4E1FA3]/30 rounded">Coming
                      Soon</span>
                  </div>
                </div>
              </a>
            </span>
            <span>
              <a class="cursor-pointer">
                <div class="flex items-center px-3 py-3 gap-3" routerLinkActive="active"
                  [routerLinkActiveOptions]="{ exact: true }">
                  <img src="assets/landing-new/icons/navbar/airdrop-tokens.svg" class="w-6 h-6" alt="bx-finance" />

                  <div class="flex items-center justify-between w-full">
                    <p class="text-white hover:text-white block rounded-md text-base font-medium">
                      NFT Bulk Mint
                    </p>
                    <span
                      class="inline-flex items-center justify-center px-2 py-1 text-xs font-medium text-[#d9d9d9] bg-[#4E1FA3]/30 rounded">Coming
                      Soon</span>
                  </div>
                </div>
              </a>
            </span>
            <span class="cursor-pointer">
              <a href="javascript:">
                <div class="flex items-center px-3 py-3 gap-3">
                  <img src="assets/landing-new/icons/navbar/nft-launchpad.svg" class="w-6 h-6" alt="bx-finance" />
                  <div class="flex items-center justify-between w-full">
                    <p class="text-white hover:text-white block rounded-md text-base font-medium">
                      NFT Launchpad
                    </p>
                    <span
                      class="inline-flex items-center justify-center px-2 py-1 text-xs font-medium text-[#d9d9d9] bg-[#4E1FA3]/30 rounded">Coming
                      Soon</span>
                  </div>
                </div>
              </a>
            </span>

            <span class="cursor-pointer">
              <a href="javascript:">
                <div class="flex items-center px-3 py-3 gap-3" routerLinkActive="active"
                  [routerLinkActiveOptions]="{ exact: true }">
                  <img src="assets/landing-new/icons/navbar/nft-locker.svg" class="w-6 h-6" alt="bx-finance" />
                  <div class="flex items-center justify-between w-full">
                    <p class="text-white hover:text-white block rounded-md text-base font-medium">
                      NFT Locker
                    </p>
                    <span
                      class="inline-flex items-center justify-center px-2 py-1 text-xs font-medium text-[#d9d9d9] bg-[#4E1FA3]/30 rounded">Coming
                      Soon</span>
                  </div>
                </div>
              </a>
            </span>
            <span class="cursor-pointer">
              <a href="javascript:">
                <div class="flex items-center px-3 py-3 gap-3" routerLinkActive="active"
                  [routerLinkActiveOptions]="{ exact: true }">
                  <img src="assets/landing-new/icons/navbar/airdrop-nfts.svg" class="w-6 h-6" alt="bx-finance" />
                  <div class="flex items-center justify-between w-full">
                    <p class="text-white hover:text-white block rounded-md text-base font-medium">
                      Airdrop NFTs
                    </p>
                    <span
                      class="inline-flex items-center justify-center px-2 py-1 text-xs font-medium text-[#d9d9d9] bg-[#4E1FA3]/30 rounded">Coming
                      Soon</span>
                  </div>
                </div>
              </a>
            </span>
          </div>
        </div>

        <div routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
          <a (clickOutside)="close('mob-earn')" (click)="isMobEarnExpanded = !isMobEarnExpanded" [ngClass]="{
              'bg-secondary-dark text-secondary': !isMobEarnExpanded,
              'bg-secondary-dark text-white rounded-md': 'isMobEarnExpanded'
            }" class="flex justify-between items-center p-3 rounded-md text-base font-medium" aria-current="page">
            <!-- <a [ngClass]="{'bg-secondary-black text-secondary' : !isMobEarnExpanded, 'bg-secondary-light text-white rounded-md' : 'isMobEarnExpanded'}" class="flex justify-between items-center p-3 rounded-md text-base font-medium" aria-current="page"> -->
            Earn
            <img src="assets/icons/chevron-down-light.svg" class="w-5 h-5" alt="bx-finance" />
          </a>
          <div [ngClass]="{
              hidden: !isMobEarnExpanded,
              'block bg-secondary-dark rounded-md': 'isMobEarnExpanded'
            }">
            <span class="cursor-pointer">
              <a href="javascript:">
                <div class="flex items-center px-3 py-3 gap-3" routerLinkActive="active"
                  [routerLinkActiveOptions]="{ exact: true }">
                  <img src="assets/landing-new/icons/navbar/farming.svg" class="w-6 h-6" alt="bx-finance" />

                  <div class="flex items-center justify-between w-full">
                    <p class="text-white hover:text-white block rounded-md text-base font-medium">
                      Farming
                    </p>
                    <span
                      class="inline-flex items-center justify-center px-2 py-1 text-xs font-medium text-[#d9d9d9] bg-[#4E1FA3]/30 rounded">Coming
                      Soon</span>
                  </div>
                </div>
              </a>
            </span>
            <span trigger="click" placement="top" class="cursor-pointer">
              <a href="javascript:">
                <div class="flex items-center px-3 py-3 gap-3" routerLinkActive="active"
                  [routerLinkActiveOptions]="{ exact: true }">
                  <img src="assets/landing-new/icons/navbar/staking.svg" class="w-6 h-6" alt="bx-finance" />

                  <div class="flex items-center justify-between w-full">
                    <p class="text-white hover:text-white block rounded-md text-base font-medium">
                      Staking
                    </p>
                    <span
                      class="inline-flex items-center justify-center px-2 py-1 text-xs font-medium text-[#d9d9d9] bg-[#4E1FA3]/30 rounded">Coming
                      Soon</span>
                  </div>
                </div>
              </a>
            </span>
          </div>
        </div>

        <div routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
          <a (clickOutside)="close('mob-community')" (click)="isMobCommunityExpanded = !isMobCommunityExpanded"
            [ngClass]="{
              'bg-secondary-dark text-secondary': !isMobCommunityExpanded,
              'bg-secondary-dark text-white rounded-md':
                'isMobCommunityExpanded'
            }" class="flex justify-between items-center p-3 rounded-md text-base font-medium" aria-current="page">
            <!-- <a [ngClass]="{'bg-secondary-black text-secondary' : !isMobEarnExpanded, 'bg-secondary-light text-white rounded-md' : 'isMobEarnExpanded'}" class="flex justify-between items-center p-3 rounded-md text-base font-medium" aria-current="page"> -->
            Community
            <img src="assets/icons/chevron-down-light.svg" class="w-5 h-5" alt="bx-finance" />
          </a>
          <div [ngClass]="{
              hidden: !isMobCommunityExpanded,
              'block bg-secondary-dark rounded-md': 'isMobCommunityExpanded'
            }">
            <span class="cursor-pointer">
              <a (click)="goToLink('https://t.me/+tdk1G16Fs8RhZGJl')" routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }">
                <div class="flex items-center px-3 py-3 gap-3">
                  <img src="assets/landing-new/icons/social-media/telegram-square.svg" class="w-6 h-6"
                    alt="bx-finance" />
                  <div class="text-white hover:text-white block rounded-md text-base font-medium">
                    Telegram
                  </div>
                </div>
              </a>
            </span>
            <span class="cursor-pointer">
              <a (click)="goToLink('https://discord.gg/5BBkdEQrVT')" routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }">
                <div class="flex items-center px-3 py-3 gap-3">
                  <img src="assets/landing-new/icons/social-media/discord-square.svg" class="w-6 h-6"
                    alt="bx-finance" />
                  <div class="text-white hover:text-white block rounded-md text-base font-medium">
                    Discord
                  </div>
                </div>
              </a>
            </span>
            <span class="cursor-pointer">
              <a (click)="goToLink('https://twitter.com/bxfinance')" routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }">
                <div class="flex items-center px-3 py-3 gap-3">
                  <img src="assets/landing-new/icons/social-media/twitter-square.svg" class="w-6 h-6"
                    alt="bx-finance" />
                  <div class="text-white hover:text-white block rounded-md text-base font-medium">
                    Twitter
                  </div>
                </div>
              </a>
            </span>
          </div>
        </div>

        <div routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
          <a (clickOutside)="close('mob-contact')" (click)="isMobContactExpanded = !isMobContactExpanded" [ngClass]="{
              'bg-secondary-dark text-secondary': !isMobContactExpanded,
              'bg-secondary-dark text-white rounded-md': 'isMobContactExpanded'
            }" class="flex justify-between items-center p-3 rounded-md text-base font-medium" aria-current="page">
            Contact Us
            <img src="assets/icons/chevron-down-light.svg" class="w-5 h-5" alt="bx-finance" />
          </a>
          <div [ngClass]="{
              hidden: !isMobContactExpanded,
              'block bg-secondary-dark rounded-md': 'isMobContactExpanded'
            }">
            <span class="cursor-pointer">
              <a (click)="contactUs()">
                <div class="flex items-center px-3 py-3 gap-3" routerLinkActive="active"
                  [routerLinkActiveOptions]="{ exact: true }">
                  <img src="assets/icons/talk.svg" class="w-6 h-6" alt="bx-finance" />
                  <div class="text-white hover:text-white block rounded-md text-base font-medium">
                    Talk to us
                  </div>
                </div>
              </a>
            </span>
            <span trigger="click" placement="top" class="cursor-pointer">
              <a (click)="
                  goToLink(
                    'https://docs.google.com/forms/d/e/1FAIpQLScqhWg7-7PNFhjSJJud99TKSBGb4BiJJZhOvmp4I3qOMlUStw/viewform'
                  )
                ">
                <div class="flex items-center px-3 py-3 gap-3" routerLinkActive="active"
                  [routerLinkActiveOptions]="{ exact: true }">
                  <img src="assets/icons/audit.svg" class="w-6 h-6" alt="bx-finance" />
                  <div class="text-white hover:text-white block rounded-md text-base font-medium">
                    Request for Audit Report
                  </div>
                </div>
              </a>
            </span>
          </div>
        </div>

        <div routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
          <a href="https://docs.blockchainx.finance/bx-finance/" target="_blank" [ngClass]="{
              'bg-secondary-dark text-secondary': !isMobCommunityExpanded,
              'bg-secondary-dark text-white rounded-md':
                'isMobCommunityExpanded'
            }" class="flex justify-between items-center p-3 rounded-md text-base font-medium" aria-current="page">
            <!-- <a [ngClass]="{'bg-secondary-black text-secondary' : !isMobCommunityExpanded, 'bg-secondary-light text-white rounded-md' : 'isMobCommunityExpanded'}" class="flex justify-between items-center p-3 rounded-md text-base font-medium" aria-current="page"> -->
            Docs
          </a>
        </div>
      </div>
    </div>
  </nav>
</div>

<div *ngIf="isConnectWalletOpened" class="fixed z-[99] inset-0 overflow-y-auto" aria-labelledby="modal-title"
  role="dialog" aria-modal="true">
  <div class="flex items-center lg:items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
    <div class="fixed inset-0 bg-[#000]/80 bg-opacity-75 backdrop-blur-lg transition-opacity" aria-hidden="true"></div>
    <!-- This element is to trick the browser into centering the modal contents. -->
    <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
    <div
      class="relative inline-block align-bottom border-primary p-6 bg-[#191A1E] rounded-xl text-left overflow-hidden transform transition-all sm:my-8 sm:align-middle sm:max-w-md sm:w-[32rem]">
      <div class="flex items-center justify-between pb-4 border-[#D9D9D9]/50 border-b-[.75px] border-solid">
        <h2 class="text__yg--gradient font-semibold text-sm md:text-base  mi--font-regular lg:!leading-[1.35] pb-2">
          Connect Wallet
        </h2>
        <div class="float-right ml-auto cursor-pointer">
          <!-- graphic -->
          <svg (click)="closeConnect()" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="#fff"
            class="w-6 h-6 ml-auto">
            <path stroke="#D9D9D9"
              d="M6.28 5.22a.75.75 0 0 0-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 1 0 1.06 1.06L10 11.06l3.72 3.72a.75.75 0 1 0 1.06-1.06L11.06 10l3.72-3.72a.75.75 0 0 0-1.06-1.06L10 8.94 6.28 5.22z">
            </path>
          </svg>
        </div>
      </div>
      <div class="grid grid-cols-12 gap-4 pt-4">
        <div class="col-span-12">
          <p class="text-base font-normal text-[#d9d9d9]">
            Connect with one of our available wallet providers or create a new
            one.
          </p>
          <ul class="my-4 space-y-4">
            <li>
              <button type="button" (click)="connectMetaMask()"
                class="flex items-center w-full p-3 text-base font-normal text-[#d9d9d9] rounded-lg bg-[#1F2024] border-[#1F2024] group hover:bg-[#4E1FA3]/30 hover:border-[#4E1FA3] border border-solid transition ease-in-out duration-700">
                <svg aria-hidden="true" class="w-5 h-5" viewBox="0 0 40 38" fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path d="M39.0728 0L21.9092 12.6999L25.1009 5.21543L39.0728 0Z" fill="#E17726" />
                  <path d="M0.966797 0.0151367L14.9013 5.21656L17.932 12.7992L0.966797 0.0151367Z" fill="#E27625" />
                  <path d="M32.1656 27.0093L39.7516 27.1537L37.1004 36.1603L27.8438 33.6116L32.1656 27.0093Z"
                    fill="#E27625" />
                  <path d="M7.83409 27.0093L12.1399 33.6116L2.89876 36.1604L0.263672 27.1537L7.83409 27.0093Z"
                    fill="#E27625" />
                  <path
                    d="M17.5203 10.8677L17.8304 20.8807L8.55371 20.4587L11.1924 16.4778L11.2258 16.4394L17.5203 10.8677Z"
                    fill="#E27625" />
                  <path
                    d="M22.3831 10.7559L28.7737 16.4397L28.8067 16.4778L31.4455 20.4586L22.1709 20.8806L22.3831 10.7559Z"
                    fill="#E27625" />
                  <path d="M12.4115 27.0381L17.4768 30.9848L11.5928 33.8257L12.4115 27.0381Z" fill="#E27625" />
                  <path d="M27.5893 27.0376L28.391 33.8258L22.5234 30.9847L27.5893 27.0376Z" fill="#E27625" />
                  <path d="M22.6523 30.6128L28.6066 33.4959L23.0679 36.1282L23.1255 34.3884L22.6523 30.6128Z"
                    fill="#D5BFB2" />
                  <path d="M17.3458 30.6143L16.8913 34.3601L16.9286 36.1263L11.377 33.4961L17.3458 30.6143Z"
                    fill="#D5BFB2" />
                  <path d="M15.6263 22.1875L17.1822 25.4575L11.8848 23.9057L15.6263 22.1875Z" fill="#233447" />
                  <path d="M24.3739 22.1875L28.133 23.9053L22.8184 25.4567L24.3739 22.1875Z" fill="#233447" />
                  <path d="M12.8169 27.0049L11.9606 34.0423L7.37109 27.1587L12.8169 27.0049Z" fill="#CC6228" />
                  <path d="M27.1836 27.0049L32.6296 27.1587L28.0228 34.0425L27.1836 27.0049Z" fill="#CC6228" />
                  <path
                    d="M31.5799 20.0605L27.6165 24.0998L24.5608 22.7034L23.0978 25.779L22.1387 20.4901L31.5799 20.0605Z"
                    fill="#CC6228" />
                  <path
                    d="M8.41797 20.0605L17.8608 20.4902L16.9017 25.779L15.4384 22.7038L12.3988 24.0999L8.41797 20.0605Z"
                    fill="#CC6228" />
                  <path d="M8.15039 19.2314L12.6345 23.7816L12.7899 28.2736L8.15039 19.2314Z" fill="#E27525" />
                  <path d="M31.8538 19.2236L27.2061 28.2819L27.381 23.7819L31.8538 19.2236Z" fill="#E27525" />
                  <path
                    d="M17.6412 19.5088L17.8217 20.6447L18.2676 23.4745L17.9809 32.166L16.6254 25.1841L16.625 25.1119L17.6412 19.5088Z"
                    fill="#E27525" />
                  <path
                    d="M22.3562 19.4932L23.3751 25.1119L23.3747 25.1841L22.0158 32.1835L21.962 30.4328L21.75 23.4231L22.3562 19.4932Z"
                    fill="#E27525" />
                  <path
                    d="M27.7797 23.6011L27.628 27.5039L22.8977 31.1894L21.9414 30.5138L23.0133 24.9926L27.7797 23.6011Z"
                    fill="#F5841F" />
                  <path
                    d="M12.2373 23.6011L16.9873 24.9926L18.0591 30.5137L17.1029 31.1893L12.3723 27.5035L12.2373 23.6011Z"
                    fill="#F5841F" />
                  <path
                    d="M10.4717 32.6338L16.5236 35.5013L16.4979 34.2768L17.0043 33.8323H22.994L23.5187 34.2753L23.48 35.4989L29.4935 32.641L26.5673 35.0591L23.0289 37.4894H16.9558L13.4197 35.0492L10.4717 32.6338Z"
                    fill="#C0AC9D" />
                  <path
                    d="M22.2191 30.231L23.0748 30.8354L23.5763 34.8361L22.8506 34.2234H17.1513L16.4395 34.8485L16.9244 30.8357L17.7804 30.231H22.2191Z"
                    fill="#161616" />
                  <path
                    d="M37.9395 0.351562L39.9998 6.53242L38.7131 12.7819L39.6293 13.4887L38.3895 14.4346L39.3213 15.1542L38.0875 16.2779L38.8449 16.8264L36.8347 19.1742L28.5894 16.7735L28.5179 16.7352L22.5762 11.723L37.9395 0.351562Z"
                    fill="#763E1A" />
                  <path
                    d="M2.06031 0.351562L17.4237 11.723L11.4819 16.7352L11.4105 16.7735L3.16512 19.1742L1.15488 16.8264L1.91176 16.2783L0.678517 15.1542L1.60852 14.4354L0.350209 13.4868L1.30098 12.7795L0 6.53265L2.06031 0.351562Z"
                    fill="#763E1A" />
                  <path
                    d="M28.1861 16.2485L36.9226 18.7921L39.7609 27.5398L32.2728 27.5398L27.1133 27.6049L30.8655 20.2912L28.1861 16.2485Z"
                    fill="#F5841F" />
                  <path
                    d="M11.8139 16.2485L9.13399 20.2912L12.8867 27.6049L7.72971 27.5398H0.254883L3.07728 18.7922L11.8139 16.2485Z"
                    fill="#F5841F" />
                  <path
                    d="M25.5283 5.17383L23.0847 11.7736L22.5661 20.6894L22.3677 23.4839L22.352 30.6225H17.6471L17.6318 23.4973L17.4327 20.6869L16.9139 11.7736L14.4707 5.17383H25.5283Z"
                    fill="#F5841F" />
                </svg>
                <span class="inline-flex text-white font-semibold flex-1 ml-3 whitespace-nowrap">MetaMask</span>
                <span
                  class="inline-flex items-center justify-center px-4 py-2 ml-3 text-xs font-medium text-[#d9d9d9] bg-[#4E1FA3]/30 border-[#4E1FA3] border border-solid rounded-lg">Connect</span>
              </button>
            </li>
            <!-- <li>
              <button type="button" (click)="openWalletConnect()"
                class="flex items-center w-full p-3 text-base font-normal text-[#d9d9d9] rounded-lg bg-[#1F2024] border-[#1F2024] group hover:bg-[#4E1FA3]/30 hover:border-[#4E1FA3] border border-solid transition ease-in-out duration-700">
                <svg aria-hidden="true" class="w-5 h-5" viewBox="0 0 512 512" version="1.1"
                  xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                  <defs>
                    <radialGradient cx="0%" cy="50%" fx="0%" fy="50%" r="100%" id="radialGradient-1">
                      <stop stop-color="#5D9DF6" offset="0%"></stop>
                      <stop stop-color="#006FFF" offset="100%"></stop>
                    </radialGradient>
                  </defs>
                  <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g id="logo">
                      <rect id="base" fill="url(#radialGradient-1)" x="0" y="0" width="512" height="512" rx="256">
                      </rect>
                      <path
                        d="M169.209772,184.531136 C217.142772,137.600733 294.857519,137.600733 342.790517,184.531136 L348.559331,190.179285 C350.955981,192.525805 350.955981,196.330266 348.559331,198.676787 L328.82537,217.99798 C327.627045,219.171241 325.684176,219.171241 324.485851,217.99798 L316.547278,210.225455 C283.10802,177.485633 228.89227,177.485633 195.453011,210.225455 L186.951456,218.549188 C185.75313,219.722448 183.810261,219.722448 182.611937,218.549188 L162.877976,199.227995 C160.481326,196.881474 160.481326,193.077013 162.877976,190.730493 L169.209772,184.531136 Z M383.602212,224.489406 L401.165475,241.685365 C403.562113,244.031874 403.562127,247.836312 401.165506,250.182837 L321.971538,327.721548 C319.574905,330.068086 315.689168,330.068112 313.292501,327.721609 C313.292491,327.721599 313.29248,327.721588 313.29247,327.721578 L257.08541,272.690097 C256.486248,272.103467 255.514813,272.103467 254.915651,272.690097 C254.915647,272.690101 254.915644,272.690105 254.91564,272.690108 L198.709777,327.721548 C196.313151,330.068092 192.427413,330.068131 190.030739,327.721634 C190.030725,327.72162 190.03071,327.721606 190.030695,327.721591 L110.834524,250.181849 C108.437875,247.835329 108.437875,244.030868 110.834524,241.684348 L128.397819,224.488418 C130.794468,222.141898 134.680206,222.141898 137.076856,224.488418 L193.284734,279.520668 C193.883897,280.107298 194.85533,280.107298 195.454493,279.520668 C195.454502,279.520659 195.45451,279.520651 195.454519,279.520644 L251.65958,224.488418 C254.056175,222.141844 257.941913,222.141756 260.338618,224.488222 C260.338651,224.488255 260.338684,224.488288 260.338717,224.488321 L316.546521,279.520644 C317.145683,280.107273 318.117118,280.107273 318.71628,279.520644 L374.923175,224.489406 C377.319825,222.142885 381.205562,222.142885 383.602212,224.489406 Z"
                        id="WalletConnect" fill="#FFFFFF" fill-rule="nonzero"></path>
                    </g>
                  </g>
                </svg>
                <span class="inline-flex text-white font-semibold flex-1 ml-3 whitespace-nowrap">Wallet Connect</span>
                <span
                  class="inline-flex items-center justify-center px-4 py-2 ml-3 text-xs font-medium text-[#d9d9d9] bg-[#4E1FA3]/30 border-[#4E1FA3] border border-solid rounded-lg">Connect</span>
              </button>
            </li> -->
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- BEGIN: Create your Account Modal -->
<div *ngIf="isCreateAccountOpened" class="fixed z-50 inset-0 overflow-y-auto" aria-labelledby="modal-title"
  role="dialog" aria-modal="true">
  <div class="flex items-center lg:items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
    <div class="fixed inset-0 bg-[#000]/10 bg-opacity-05 backdrop-blur-lg transition-opacity" aria-hidden="true"></div>
    <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
    <div
      class="relative inline-block align-middle border-primary p-6 bg-[#191A1E] rounded-xl text-left overflow-hidden transform transition-all md:my-8 w-full sm:w-10/12 lg:w-8/12 xl:w-7/12 2xl:w-6/12">
      <div class="flex items-left">
        <div class="flex flex-col">
          <h2 class="font-semibold text-sm md:text-base lg:text-lg mi--font-regular lg:!leading-[1.35] pb-2">
            <span class="text__yg--gradient flex">Create your Account</span>
          </h2>
          <p class="text-[#D9D9D9] text-sm lg:text-base ep--font-light lg:!leading-[1.75]">
            Please Enter your info to Create Account
          </p>
        </div>
        <!-- <div class="float-right ml-auto cursor-pointer">
          <svg (click)="closeConnect()" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="#fff"
            class="w-6 h-6 ml-auto">
            <path stroke="#D9D9D9"
              d="M6.28 5.22a.75.75 0 0 0-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 1 0 1.06 1.06L10 11.06l3.72 3.72a.75.75 0 1 0 1.06-1.06L11.06 10l3.72-3.72a.75.75 0 0 0-1.06-1.06L10 8.94 6.28 5.22z">
            </path>
          </svg>
        </div> -->
      </div>
      <hr class="border--b" />
      <div class="grid grid-cols-12 gap-4 pt-4">
        <div class="col-span-12">
          <div class="col-span-12">
            <form autocomplete="off" [formGroup]="createAccountForm" (ngSubmit)="createAccount()"
              enctype="multipart/form-data">
              <div class="grid grid-cols-12 gap-4 lg:gap-8">
                <div class="col-span-12 sm:col-span-5 md:col-span-4">
                  <div class="relative image-upload mb-2 sm:mb-0">
                    <label for="file-input">
                      <img [src]="filePreview" class="rounded-xl w-full h-full md:h-56 object-cover" alt="Avatar" />
                      <div
                        class="absolute inline-flex items-center justify-center w-8 h-8 text-xs font-bold text-white bg-secondary-dark border-2 border-[#a06afff5] rounded-lg -bottom-2 -right-2 dark:border-gray-900">
                        <svg viewBox="0 0 24 24" width="16" height="16" stroke="#a06afff5" stroke-width="2" fill="none"
                          stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1">
                          <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                          <polyline points="17 8 12 3 7 8"></polyline>
                          <line x1="12" y1="3" x2="12" y2="15"></line>
                        </svg>
                      </div>
                    </label>
                    <input id="file-input" type="file" accept="image/jpeg,image/jpg,image/png,image/gif,image/webp"
                      formControlName="profile" (change)="onFileSelected($event)" />
                  </div>
                  <!-- <button type="button" class="relative inline-flex items-center p-3 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                    <svg class="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z"></path><path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z"></path></svg>
                    <span class="sr-only">Notifications</span>
                    <div class="absolute inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-white bg-red-500 border-2 border-white rounded-full -top-2 -right-2 dark:border-gray-900">20</div>
                  </button> -->
                </div>
                <div class="col-span-12 sm:col-span-7 md:col-span-8">
                  <div class="mb-6 sm:mb-8 form-group">
                    <label for="name" class="input--label mb-2">Name*</label>
                    <input (keydown)="onKeyDown($event)" type="text" formControlName="name"
                      placeholder="Enter your name" class="input--field" required />
                    <div class="input--error" *ngIf="
                        (f.name.touched || submitted) && f.name.errors?.required
                      ">
                      This field is required *
                    </div>
                  </div>
                  <div class="mb-6 sm:mb-8 form-group">
                    <label for="email" class="input--label mb-2">Email*</label>
                    <input autocomplete="no" type="email" id="c" formControlName="email" placeholder="Enter your email"
                      class="form-control" class="input--field" required />
                    <div class="input--error" *ngIf="
                        (f.email.touched || submitted) &&
                        f.email.errors?.required
                      ">
                      This field is required *
                    </div>
                    <div class="input--error" *ngIf="
                        (f.email.touched || submitted) &&
                        (f.email.errors?.email ||
                          f.email.errors?.emailDomain ||
                          f.email.errors?.pattern)
                      ">
                      Enter valid email address
                    </div>
                  </div>
                </div>
              </div>
              <div class="grid grid-cols-12 gap-4 lg:gap-8 mt-4">
                <div class="col-span-12">
                  <div class="mb-6 sm:mb-8 form-group">
                    <label for="email" class="input--label mb-2">Wallet Address*</label>
                    <input type="text" id="walletAddress" formControlName="wallet_address" class="form-control"
                      class="input--field" required [readonly]="true" />
                    <div class="input--error" *ngIf="
                        (f.wallet_address.touched || submitted) &&
                        f.wallet_address.errors?.required
                      ">
                      This field is required *
                    </div>
                  </div>
                </div>
              </div>
              <div class="flex flex-col sm:flex-row gap-8 justify-between my-4 sm:my-4">
                <!-- <button type="button" (click)="closeConnect()"
                  class="btn__primary hover:bg-[#4E1FA3]/30 border border-solid border-[#4e1fa3] w-full shadow-none">Cancel</button> -->
                <button type="submit" *ngIf="!processing" [disabled]="!createAccountForm.valid"
                  [ngClass]="{ 'opacity-50': !createAccountForm.valid }"
                  class="order-first sm:order-last btn__primary bg-[#4E1FA3]/30 border border-solid border-[#4e1fa3] w-full shadow-none">
                  <span>Create your Account</span>
                </button>
                <button type="button" *ngIf="processing"
                  class="order-first sm:order-last btn__primary bg-[#4E1FA3]/30 border border-solid border-[#4e1fa3] w-full cursor-not-allowed shadow-none"
                  disabled="">
                  <svg class="animate-spin -ml-1 mr-3 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none"
                    viewBox="0 0 24 24">
                    <circle class="opacity-0" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                    <path class="opacity-75" fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
                    </path>
                  </svg>
                  Processing...
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- End: Create your Account Modal -->

<!-- BEGIN: Choose Network Connect Modal -->
<div class="fixed z-50 inset-0 overflow-y-auto" [ngClass]="isChooseNetworkShown == true ? 'block' : 'hidden'"
  aria-labelledby="modal-title" role="dialog" aria-modal="true">
  <div class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
    <div class="fixed inset-0 bg-[#0f172acc] backdrop-blur-md transition-opacity" aria-hidden="true"></div>

    <!-- This element is to trick the browser into centering the modal contents. -->
    <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
    <div
      class="relative inline-block align-bottom bg-secondary-dark shadow-md p-6 rounded-xl text-left overflow-hidden transform transition-all sm:my-8 sm:align-middle w-full px-8 sm:w-[38rem] sm:max-w-2xl">
      <div class="flex justify-between items-center">
        <div class="flex justify-between items-center">
          <h2 class="mi--font-regular text-white text-sm tracking-[1px] 2xl:text-xl">
            Choose Network
          </h2>
        </div>
        <div (click)="closeChooseNetwork()"
          class="bg-secondary-black cursor-pointer w-8 h-8 md:h-10 md:w-10 rounded-lg flex justify-center items-center">
          <img src="assets/icons/x-light.svg" class="m-auto w-4 h-4 md:w-5 md:h-5" alt="bx-finance" />
        </div>
      </div>
      <hr class="border-grey border-b-2 border-solid my-3" />
      <div class="grid grid-cols-12 items-center gap-4 mt-4">
        <div class="col-span-12 md:col-span-6">
          <div tooltipClass="tooltip-truncate" tooltip="Coming Soon" placement="top"
            class="bg-secondary-black flex items-center gap-3 px-4 py-3 rounded-lg border border-secondary-dark cursor-pointer hover:scale-105 hover:shadow-primary-hover hover:border-primary hover:border ease-out duration-300">
            <img src="assets/icons/navbar/ethereum.svg" class="w-6 h-6 rounded-full" alt="bx-finance" />
            <p class="text-white text-sm md:text-lg font-normal text-center">
              Ethereum MainNet
            </p>
          </div>
        </div>
        <div class="col-span-12 md:col-span-6">
          <div (click)="changeNetwork(8)"
            class="bg-secondary-black flex items-center gap-3 px-4 py-3 rounded-lg border border-secondary-dark cursor-pointer hover:scale-105 hover:shadow-primary-hover hover:border-primary hover:border ease-out duration-300">
            <img src="assets/icons/navbar/bsc.svg" class="w-6 h-6 rounded-full" alt="bx-finance" />
            <p class="text-white text-sm md:text-lg font-normal text-center">
              BSC MainNet
            </p>
          </div>
        </div>
        <div class="col-span-12 md:col-span-6">
          <div (click)="changeNetwork(9)"
            class="bg-secondary-black flex items-center gap-3 px-4 py-3 rounded-lg border border-secondary-dark cursor-pointer hover:scale-105 hover:shadow-primary-hover hover:border-primary hover:border ease-out duration-300">
            <img src="assets/icons/navbar/polygon.svg" class="w-6 h-6 rounded-full" alt="bx-finance" />
            <p class="text-white text-sm md:text-lg font-normal text-center">
              Polygon MainNet
            </p>
          </div>
        </div>
        <div class="col-span-12 md:col-span-6">
          <div (click)="changeNetwork(10)"
            class="bg-secondary-black flex items-center gap-3 px-4 py-3 rounded-lg border border-secondary-dark cursor-pointer hover:scale-105 hover:shadow-primary-hover hover:border-primary hover:border ease-out duration-300">
            <img src="assets/icons/navbar/avalanchee.svg" class="w-6 h-6 rounded-full" alt="bx-finance" />
            <p class="text-white text-sm md:text-lg font-normal text-center">
              Avalanche MainNet
            </p>
          </div>
        </div>
        <div class="col-span-12 md:col-span-6">
          <div (click)="changeNetwork(11)"
            class="bg-secondary-black flex items-center gap-3 px-4 py-3 rounded-lg border border-secondary-dark cursor-pointer hover:scale-105 hover:shadow-primary-hover hover:border-primary hover:border ease-out duration-300">
            <img src="assets/icons/navbar/fantom.svg" class="w-6 h-6 rounded-full" alt="bx-finance" />
            <p class="text-white text-sm md:text-lg font-normal text-center">
              Fantom Opera MainNet
            </p>
          </div>
        </div>
        <div class="col-span-12 md:col-span-6">
          <div (click)="changeNetwork(12)"
            class="bg-secondary-black flex items-center gap-3 px-4 py-3 rounded-lg border border-secondary-dark cursor-pointer hover:scale-105 hover:shadow-primary-hover hover:border-primary hover:border ease-out duration-300">
            <img src="assets/icons/navbar/coronos.svg" class="w-6 h-6 rounded-full" alt="bx-finance" />
            <p class="text-white text-sm md:text-lg font-normal text-center">
              Cronos MainNet
            </p>
          </div>
        </div>
        <div class="col-span-12 md:col-span-6">
          <div (click)="changeNetwork(13)"
            class="bg-secondary-black flex items-center gap-3 px-4 py-3 rounded-lg border border-secondary-dark cursor-pointer hover:scale-105 hover:shadow-primary-hover hover:border-primary hover:border ease-out duration-300">
            <img src="assets/icons/navbar/moonbeam.png" class="w-6 h-6 rounded-full" alt="bx-finance" />
            <p class="text-white text-sm md:text-lg font-normal text-center">
              Moonbeam MainNet
            </p>
          </div>
        </div>
        <div class="col-span-12 md:col-span-6">
          <div tooltipClass="tooltip-truncate" tooltip="Coming Soon" placement="top"
            class="bg-secondary-black flex items-center gap-3 px-4 py-3 rounded-lg border border-secondary-dark cursor-pointer hover:scale-105 hover:shadow-primary-hover hover:border-primary hover:border ease-out duration-300">
            <img src="assets/icons/navbar/arbitrum.png" class="w-6 h-6 rounded-full" alt="bx-finance" />
            <p class="text-white text-sm md:text-lg font-normal text-center">
              Arbitrum
            </p>
          </div>
        </div>
        <div class="col-span-12 md:col-span-6">
          <div tooltipClass="tooltip-truncate" tooltip="Coming Soon" placement="top"
            class="bg-secondary-black flex items-center gap-3 px-4 py-3 rounded-lg border border-secondary-dark cursor-pointer hover:scale-105 hover:shadow-primary-hover hover:border-primary hover:border ease-out duration-300">
            <img src="assets/icons/navbar/arbitrum-nova.png" class="w-6 h-6 rounded-full" alt="bx-finance" />
            <p class="text-white text-sm md:text-lg font-normal text-center">
              Arbitrum Nova
            </p>
          </div>
        </div>

        <div class="col-span-12 md:col-span-6">
          <div tooltipClass="tooltip-truncate" tooltip="Coming Soon" placement="top"
            class="bg-secondary-black flex items-center gap-3 px-4 py-3 rounded-lg border border-secondary-dark cursor-pointer hover:scale-105 hover:shadow-primary-hover hover:border-primary hover:border ease-out duration-300">
            <img src="assets/icons/navbar/gnosis.png" class="w-6 h-6 rounded-full" alt="bx-finance" />
            <p class="text-white text-sm md:text-lg font-normal text-center">
              Gnosis
            </p>
          </div>
        </div>

        <div class="col-span-12 md:col-span-6">
          <div tooltipClass="tooltip-truncate" tooltip="Coming Soon" placement="top"
            class="bg-secondary-black flex items-center gap-3 px-4 py-3 rounded-lg border border-secondary-dark cursor-pointer hover:scale-105 hover:shadow-primary-hover hover:border-primary hover:border ease-out duration-300">
            <img src="assets/icons/navbar/moonriver.png" class="w-6 h-6 rounded-full" alt="bx-finance" />
            <p class="text-white text-sm md:text-lg font-normal text-center">
              Moonriver
            </p>
          </div>
        </div>
        <div class="col-span-12 md:col-span-6">
          <div tooltipClass="tooltip-truncate" tooltip="Coming Soon" placement="top"
            class="bg-secondary-black flex items-center gap-3 px-4 py-3 rounded-lg border border-secondary-dark cursor-pointer hover:scale-105 hover:shadow-primary-hover hover:border-primary hover:border ease-out duration-300">
            <img src="assets/icons/navbar/harmonyone.jpg" class="w-6 h-6 rounded-full" alt="bx-finance" />
            <p class="text-white text-sm md:text-lg font-normal text-center">
              Harmony
            </p>
          </div>
        </div>
        <div class="col-span-12 md:col-span-6">
          <div tooltipClass="tooltip-truncate" tooltip="Coming Soon" placement="top"
            class="bg-secondary-black flex items-center gap-3 px-4 py-3 rounded-lg border border-secondary-dark cursor-pointer hover:scale-105 hover:shadow-primary-hover hover:border-primary hover:border ease-out duration-300">
            <img src="assets/icons/navbar/optimism.png" class="w-6 h-6 rounded-full" alt="bx-finance" />
            <p class="text-white text-sm md:text-lg font-normal text-center">
              Optimism
            </p>
          </div>
        </div>
        <!-- <div class="col-span-12 md:col-span-6">
          <div tooltipClass="tooltip-truncate" tooltip="Coming Soon" placement="top"
            class="bg-secondary-black flex items-center gap-3 px-4 py-3 rounded-lg border border-secondary-dark cursor-pointer hover:scale-105 hover:shadow-primary-hover hover:border-primary hover:border ease-out duration-300">
            <img src="assets/icons/navbar/moonbeam.png" class="w-6 h-6 rounded-full" alt="bx-finance">
            <p class="text-white text-sm md:text-lg font-normal text-center">Moonbeam</p>
          </div>
        </div> -->
        <div class="col-span-12 md:col-span-6">
          <div tooltipClass="tooltip-truncate" tooltip="Coming Soon" placement="top"
            class="bg-secondary-black flex items-center gap-3 px-4 py-3 rounded-lg border border-secondary-dark cursor-pointer hover:scale-105 hover:shadow-primary-hover hover:border-primary hover:border ease-out duration-300">
            <img src="assets/icons/navbar/fuse.png" class="w-6 h-6 rounded-full" alt="bx-finance" />
            <p class="text-white text-sm md:text-lg font-normal text-center">
              Fuse
            </p>
          </div>
        </div>
        <div class="col-span-12 md:col-span-6">
          <div tooltipClass="tooltip-truncate" tooltip="Coming Soon" placement="top"
            class="bg-secondary-black flex items-center gap-3 px-4 py-3 rounded-lg border border-secondary-dark cursor-pointer hover:scale-105 hover:shadow-primary-hover hover:border-primary hover:border ease-out duration-300">
            <img src="assets/icons/navbar/kava.png" class="w-6 h-6 rounded-full" alt="bx-finance" />
            <p class="text-white text-sm md:text-lg font-normal text-center">
              Kava
            </p>
          </div>
        </div>
        <div class="col-span-12 md:col-span-6">
          <div tooltipClass="tooltip-truncate" tooltip="Coming Soon" placement="top"
            class="bg-secondary-black flex items-center gap-3 px-4 py-3 rounded-lg border border-secondary-dark cursor-pointer hover:scale-105 hover:shadow-primary-hover hover:border-primary hover:border ease-out duration-300">
            <img src="assets/icons/navbar/metis.png" class="w-6 h-6 rounded-full object-cover" alt="bx-finance" />
            <p class="text-white text-sm md:text-lg font-normal text-center">
              Metis
            </p>
          </div>
        </div>
        <div class="col-span-12 md:col-span-6">
          <div tooltipClass="tooltip-truncate" tooltip="Coming Soon" placement="top"
            class="bg-secondary-black flex items-center gap-3 px-4 py-3 rounded-lg border border-secondary-dark cursor-pointer hover:scale-105 hover:shadow-primary-hover hover:border-primary hover:border ease-out duration-300">
            <img src="assets/icons/navbar/telos.png" class="w-6 h-6 rounded-full" alt="bx-finance" />
            <p class="text-white text-sm md:text-lg font-normal text-center">
              Telos EVM
            </p>
          </div>
        </div>
        <div class="col-span-12 md:col-span-6">
          <div tooltipClass="tooltip-truncate" tooltip="Coming Soon" placement="top"
            class="bg-secondary-black flex items-center gap-3 px-4 py-3 rounded-lg border border-secondary-dark cursor-pointer hover:scale-105 hover:shadow-primary-hover hover:border-primary hover:border ease-out duration-300">
            <img src="assets/icons/navbar/celo.jpg" class="w-6 h-6 rounded-full" alt="bx-finance" />
            <p class="text-white text-sm md:text-lg font-normal text-center">
              Celo
            </p>
          </div>
        </div>
        <div class="col-span-12 md:col-span-6">
          <div tooltipClass="tooltip-truncate" tooltip="Coming Soon" placement="top"
            class="bg-secondary-black flex items-center gap-3 px-4 py-3 rounded-lg border border-secondary-dark cursor-pointer hover:scale-105 hover:shadow-primary-hover hover:border-primary hover:border ease-out duration-300">
            <img src="assets/icons/navbar/bobaavax.png" class="w-6 h-6 rounded-full" alt="bx-finance" />
            <p class="text-white text-sm md:text-lg font-normal text-center">
              Boba Avax
            </p>
          </div>
        </div>

        <div class="col-span-12">
          <div class="flex justify-between items-center">
            <h2 class="font-bold text-white text-sm md:text-base lg:text-lg 2xl:text-xl">
              Test Net
            </h2>
          </div>
        </div>
        <div class="col-span-12 md:col-span-6">
          <div (click)="changeNetwork(0)"
            class="bg-secondary-black flex items-center gap-3 px-4 py-3 rounded-lg border border-secondary-dark cursor-pointer hover:scale-105 hover:shadow-primary-hover hover:border-primary hover:border ease-out duration-300">
            <img src="assets/icons/navbar/bsc-testnet.png" class="w-6 h-6 rounded-full" alt="bx-finance" />
            <p class="text-white text-sm md:text-lg font-normal text-center">
              BSC TestNet
            </p>
          </div>
        </div>
        <div class="col-span-12 md:col-span-6">
          <div (click)="changeNetwork(1)"
            class="bg-secondary-black flex items-center gap-3 px-4 py-3 rounded-lg border border-secondary-dark cursor-pointer hover:scale-105 hover:shadow-primary-hover hover:border-primary hover:border ease-out duration-300">
            <img src="assets/icons/navbar/ethereum.svg" class="w-6 h-6 rounded-full" alt="bx-finance" />
            <p class="text-white text-sm md:text-lg font-normal text-center">
              Ethereum Goerli TestNet
            </p>
          </div>
        </div>
        <div class="col-span-12 md:col-span-6">
          <div (click)="changeNetwork(2)"
            class="bg-secondary-black flex items-center gap-3 px-4 py-3 rounded-lg border border-secondary-dark cursor-pointer hover:scale-105 hover:shadow-primary-hover hover:border-primary hover:border ease-out duration-300">
            <img src="assets/icons/navbar/polygon.png" class="w-6 h-6 rounded-full" alt="bx-finance" />
            <p class="text-white text-sm md:text-lg font-normal text-center">
              Mumbai TestNet
            </p>
          </div>
        </div>
        <div class="col-span-12 md:col-span-6">
          <div (click)="changeNetwork(3)"
            class="bg-secondary-black flex items-center gap-3 px-4 py-3 rounded-lg border border-secondary-dark cursor-pointer hover:scale-105 hover:shadow-primary-hover hover:border-primary hover:border ease-out duration-300">
            <img src="assets/icons/navbar/avalanchee.svg" class="w-6 h-6 rounded-full" alt="bx-finance" />
            <p class="text-white text-sm md:text-lg font-normal text-center">
              Avalanche TestNet
            </p>
          </div>
        </div>
        <div class="col-span-12 md:col-span-6">
          <div (click)="changeNetwork(4)"
            class="bg-secondary-black flex items-center gap-3 px-4 py-3 rounded-lg border border-secondary-dark cursor-pointer hover:scale-105 hover:shadow-primary-hover hover:border-primary hover:border ease-out duration-300">
            <img src="assets/icons/navbar/fantom.svg" class="w-6 h-6 rounded-full" alt="bx-finance" />
            <p class="text-white text-sm md:text-lg font-normal text-center">
              Fantom Opera TestNet
            </p>
          </div>
        </div>
        <div class="col-span-12 md:col-span-6">
          <div (click)="changeNetwork(5)"
            class="bg-secondary-black flex items-center gap-3 px-4 py-3 rounded-lg border border-secondary-dark cursor-pointer hover:scale-105 hover:shadow-primary-hover hover:border-primary hover:border ease-out duration-300">
            <img src="assets/icons/navbar/coronos.svg" class="w-6 h-6 rounded-full" alt="bx-finance" />
            <p class="text-white text-sm md:text-lg font-normal text-center">
              Cronos TestNet
            </p>
          </div>
        </div>

        <!-- <div class="col-span-12 md:col-span-6">
                    <div
                        class="bg-secondary-black flex items-center gap-3 px-4 py-3 rounded-lg border border-secondary-dark cursor-pointer hover:scale-105 hover:shadow-primary-hover hover:border-primary hover:border ease-out duration-300">
                        <img src="assets/icons/navbar/harmonyone.jpg" class="w-6 h-6 rounded-full" alt="bx-finance">
                        <p class="text-white text-sm md:text-lg font-normal text-center">Harmony</p>
                    </div>
                </div> -->
        <div class="col-span-12 md:col-span-6">
          <div (click)="changeNetwork(6)"
            class="bg-secondary-black flex items-center gap-3 px-4 py-3 rounded-lg border border-secondary-dark cursor-pointer hover:scale-105 hover:shadow-primary-hover hover:border-primary hover:border ease-out duration-300">
            <img src="assets/icons/navbar/moonbeam.png" class="w-6 h-6 rounded-full" alt="bx-finance" />
            <p class="text-white text-sm md:text-lg font-normal text-center">
              Moonbeam TestNet
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- end: Choose Network Connect Modal -->

<div class="fixed z-50 inset-0 overflow-y-auto" *ngIf="loader" aria-labelledby="modal-title" role="dialog"
  aria-modal="true">
  <div class="flex items-center md:items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
    <div class="fixed inset-0 bg-[#0f172acc] backdrop-blur-md transition-opacity" aria-hidden="true"></div>
    <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
    <div
      class="relative inline-block align-bottom p-6 rounded-xl text-left overflow-hidden transform transition-all sm:my-8 sm:align-middle sm:max-w-3xl sm:w-[28rem] token-create-success">
      <div class="grid grid-cols-12 gap-4 gap-y-6 sm:gap-6 lg:gap-8 mt-4">
        <div class="col-span-12 relative">
          <img class="h-24 w-24 mx-auto object-contain animate-bounce" src="assets/images/logo-sm.svg"
            alt="bx-finance" />
        </div>
      </div>
    </div>
  </div>
</div>

<!-- <app-invalid-session-popup [invalidSessionPopup]="invalidSessionPopup"></app-invalid-session-popup> -->

<div data-dial-init class="fixed left-6 bottom-6 group z-50">
  <!-- <div id="speed-dial-menu-default" class="flex flex-col items-center hidden mb-4 space-y-2">
    <a href="https://www.producthunt.com/posts/bx-finance?utm_source=badge-top-post-topic-badge&utm_medium=badge&utm_souce=badge-bx&#0045;finance" target="_blank"><img src="https://api.producthunt.com/widgets/embed-image/v1/top-post-topic-badge.svg?post_id=382607&theme=light&period=weekly&topic_id=501" alt="BX&#0032;Finance - A&#0032;Complete&#0032;No&#0032;Code&#0032;Web3&#0032;Ecosystem | Product Hunt" style="width: 250px; height: 54px;" width="250" height="54" /></a>
  </div> -->
  <button type="button" data-dial-toggle="speed-dial-menu-default" aria-controls="speed-dial-menu-default"
    aria-expanded="false"
    class="animate-float flex items-center justify-center text-white bg-blue-700 rounded-full hover:bg-blue-800 dark:bg-blue-600 dark:hover:bg-blue-700 focus:ring-4 focus:ring-blue-300 focus:outline-none dark:focus:ring-blue-800">
    <a href="https://www.producthunt.com/posts/bx-finance?utm_source=badge-top-post-topic-badge&utm_medium=badge&utm_souce=badge-bx&#0045;finance"
      target="_blank"><img
        src="https://api.producthunt.com/widgets/embed-image/v1/top-post-topic-badge.svg?post_id=382607&theme=light&period=weekly&topic_id=501"
        alt="BX&#0032;Finance - A&#0032;Complete&#0032;No&#0032;Code&#0032;Web3&#0032;Ecosystem | Product Hunt"
        class="w-40 sm:w-48 md:52 lg:w-64" /></a>
  </button>
</div>
