import { unsupported } from '@angular/compiler/src/render3/view/util';
import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Console } from 'console';
import { MetaMaskService, StorageService, WalletConnectService } from 'ng-blockchainx';
import { ToastrService } from 'ngx-toastr';
import { of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CommonContractService } from '../services/common-contract.service';
import { Router } from '@angular/router';
import detectEthereumProvider from '@metamask/detect-provider';
import { AuthenticationService } from '../services/authentication.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css'],
})
export class NavbarComponent implements OnInit, OnDestroy {
  public isMetamaskInstalled: boolean = false;
  public isScrolled: boolean = false;
  public isMetaMaskConnected: boolean = false;
  public showWrongNetworkPopup: boolean = false;
  public connectedChainId: string = '';
  public walletAddress: string = '';
  public metaMaskSubscription: any;
  public innerWidth!: number;
  public isMobileNavExpanded: boolean = false;
  public isTokenDropdownExpanded: boolean = false;
  public isEarnDropdownExpanded: boolean = false;
  public isNftDropdownExpanded: boolean = false;
  public isCommunityDropdownExpanded: boolean = false;
  public isMobTokenExpanded: boolean = false;
  public isMobNftExpanded: boolean = false;
  public isMobEarnExpanded: boolean = false;
  public isMobCommunityExpanded: boolean = false;
  public isChooseNetworkShown: boolean = false;
  public isChooseWrongNetworkShown: boolean = false;
  public ethWeb3: any;
  public userNativeBalance: any;
  public currentNetworkName: any = '';
  public currentNetworkSymbol: any = '';
  public currentNativeCurrency: any = '-';
  public loader: boolean = false;
  public isOnInit: boolean = false;
  public connectObservable: any;
  public userNativeBalanceObservable: any;
  public networkDetails = {

    chainId: this.commonContractService.networkDetails.chainId
  };
  public account: any;
  observable: any;

  constructor(
    private router: Router,
    private toastr: ToastrService,
    private metaMaskService: MetaMaskService,
    private storage: StorageService,
    private commonContractService: CommonContractService,
    private authService: AuthenticationService,
    private walletConnectService: WalletConnectService,
  ) { }

  async ngOnInit() {
    let provider: any = await detectEthereumProvider();
    // if the provider is not detected, detectEthereumProvider resolves to null
    if (!provider) {
      this.toastr.info('Please install MetaMask!');
    }
    this.connectObservable =
      this.commonContractService.connectObservable.subscribe(
        (response: boolean) => {
          if (response) {
            this.connectMetaMask();
          }
        }
      );
    this.userNativeBalanceObservable =
      this.commonContractService.userNativeBalance.subscribe((res: any) => {
        this.userNativeBalance = res;
      });

    this.loader = true;

    this.metaMaskService.setSupportedChains(
      this.networkDetails.chainId
    );

    // this.account = this.storage.get('account');

    this.observable =
      await this.commonContractService.accountObservable.subscribe(
        async (res: string) => {
          if (res) {
            this.account = await res;
            console.log('wallet details', this.account);
          }
        }
      );

    let isMetaMaskConnected = this.storage.get('isWalletConnected');
    if (this.account != null || isMetaMaskConnected == 'true') {
      this.walletConnection();
    }

    this.loader = false;
    // this.openWalletConnect();
    // this.walletConnectListener();
  }

  // public walletConnectListener() {
  //   this.walletConnectService.connectionListener.subscribe((response: any) => {
  //     if (response.code == 250700) {
  //       this.logout();
  //     } else {
  //       if (response.data['account']) {
  //         console.log('Account', response.data);
  //         this.storage.set('walletType', 2);
  //         this.connectionHandler(response.data);
  //         this.validateNetwork(response.data.chainId);
  //         this.setNetworkDetails(response.data);
  //         this.userNativeBalanceObservable =
  //           this.commonContractService.userNativeBalance.subscribe((res: any) => {
  //             this.userNativeBalance = res;
  //           });
  //         this.commonContractService.setAccount({
  //           walletAddress: this.walletAddress,
  //           chainId: response.data.chainId,
  //         });
  //         if (!this.storage.get('access_token')) {
  //           console.log('access token 1');
  //           const accessToken: any = this.login({ wallet_address: this.walletAddress }).catch(err => {
  //             this.toastr.error(err.message);
  //           });
  //           if (accessToken.status) {
  //             this.storage.set('access_token', accessToken.data.session.session_token);
  //           }
  //         }
  //         //window.location.reload();
  //       }
  //     }
  //   });
  // }


  logout() {
    this.setNativeCurrencyNull();
    this.isMetaMaskConnected = false;
    this.storage.remove('access_token');
    this.storage.set('isWalletConnected', this.isMetaMaskConnected);
    // // wallet connect disconnect
    // this.storage.remove('walletconnect');
    // this.storage.remove('walletType');
    window.location.reload();
  }

  /**
   * Wallet Connect popup opens (QR Code)
   */
  // public openWalletConnect() {
  //   this.walletConnectService.openWalletConnectModal();
  // }

  /**
   * connect metamask
   */
  async connectMetaMask() {
    // try {
    //   const connection:any = await this.metaMaskService.connectMetaMask().catch((error:any) =>{
    //     console.log(error);
    //   });
    //   if(connection) {
    //     console.log('connect', connection);
    //     this.walletAddress = connection.data[0];
    //     this.storage.set('address', connection);
    //     this.isMetaMaskConnected = true;
    //     this.walletConnection();
    //     window.location.reload();

    //   }
    // } catch (exception) {
    //     console.log('exception',exception);

    // }

    try {
      this.walletConnection();
      const connection: any = await this.metaMaskService
        .connectMetaMask()
        .catch((error: any) => {
          console.log(error);
          this.toastr.error(error.data.message);
        });
      if (connection) {
        this.storage.set('connection', connection);
        this.walletConnection();
        this.isMetaMaskConnected = true;
        this.storage.set('isWalletConnected', this.isMetaMaskConnected);
        window.location.reload();
      }
    } catch (exception) {
      console.log('Exception ', exception);
    }
  }

  walletConnection() {
    this.metaMaskSubscription =
      this.metaMaskService.connectionListener.subscribe(
        async (response: any) => {
          console.log('response code', response);
          if (response.code == 250601) {
            // window.location.reload();
            this.connectionHandler(response.data);
            this.validateNetwork(response.data.chainId);
            this.setNetworkDetails(response.data);
            this.commonContractService.setAccount({
              walletAddress: this.walletAddress,
              chainId: response.data.chainId,
            });
            // if(!this.isOnInit){
            //   this.isOnInit=true
            //   this.ngOnInit()
            // }
          }

          if (response.code == 250611) {
            window.location.reload();
            this.setNetworkDetails(response.data);
          }

          if (response.code == 250610) {
            // chain changed
            console.log(response, 'chain changed');
            this.storage.set('account', {
              walletAddress: this.walletAddress,
              chainId: response.data.chainId,
            });

            console.log('walletAddress', this.walletAddress);
            // window.location.reload()
            await this.router.navigate(['']);
            window.location.reload();
            this.setNetworkDetails(response.data);
          }
          if (response.code == 250512) {
            this.setNativeCurrencyNull();
          }

          if (response.code == 250641) {
            this.isChooseWrongNetworkShown = true;
          }
          // login or register api call 
          if (!this.storage.get('access_token')) {
            console.log('access token 2');
            const accessToken: any = await this.login({ wallet_address: this.walletAddress }).catch(err => {
              this.toastr.error(err.message);
            });
            if (accessToken.status) {
              this.storage.set('access_token', accessToken.data.session.session_token);
            }
          }
          console.log('oninit', response);
        }
      );
  }

  /**
   * Logins navbar component
   * @param params 
   * @returns  
   */
  private async login(params: any) {
    return new Promise((resolve, reject) => {
      this.authService.login(params).subscribe({
        next: (response: any) => {
          resolve({ status: true, data: response.data });
        },
        error: (error) => {
          reject({ status: false, data: error });
        },
      });
    });
  }

  validateNetwork(chainId: string) {
    if (environment.ALLOWED_NETWORKS.indexOf(chainId) == -1) {
      this.showWrongNetworkPopup = true;
    }
  }

  async changeNetwork(index: any) {
    try {
      // this.loader=true
      // this.isChooseWrongNetworkShown = false
      const chainInfo = await this.metaMaskService.chainDetails(
        environment.ALLOWED_NETWORKS[index]
      );
      console.log('chain info', chainInfo);
      const chainIdToHex = this.commonContractService.ethWeb3.utils.toHex(
        chainInfo.chainId
      );
      const addTokenParam = {
        method: 'wallet_addEthereumChain',
        params: [
          {
            chainId: chainIdToHex,
            chainName: chainInfo.name,
            nativeCurrency: chainInfo.nativeCurrency,
            rpcUrls: chainInfo.rpc,
            blockExplorerUrls: chainInfo.explorers.url,
          },
        ],
      };
      console.log('addTokenParam', addTokenParam);
      this.metaMaskService.addNetwork(addTokenParam);
      if (chainInfo) {
        this.metaMaskService.changeNetwork(environment.ALLOWED_NETWORKS[index]);
      }
    } catch (error) {
      this.toastr.error('error');
    }
  }

  connectionHandler(account: any) {
    this.connectedChainId = account.chainId;
    this.walletAddress = account.account[0];

    this.storage.set('account', {
      walletAddress: this.walletAddress,
      chainId: this.connectedChainId,
    });
    this.commonContractService.sendWalletAddress(this.walletAddress);
    //  this.nativeBalance();
    this.isMetaMaskConnected = true;
    this.storage.set('isWalletConnected', this.isMetaMaskConnected);
  }

  @HostListener('window:scroll')
  scrollEvent() {
    window.scrollY >= 50 ? (this.isScrolled = true) : (this.isScrolled = false);
  }

  chooseNetwork() {
    this.isChooseNetworkShown = true;
  }
  chooseWrongNetwork() {
    this.isChooseWrongNetworkShown = true;
  }
  closeChooseNetwork() {
    this.isChooseNetworkShown = false;
  }
  toggleMobileNav() {
    this.isMobileNavExpanded = !this.isMobileNavExpanded;
  }
  toggleTokenDropdown() {
    this.isTokenDropdownExpanded = !this.isTokenDropdownExpanded;
  }
  toggleTokenDropdownHover() {
    this.isTokenDropdownExpanded = !this.isTokenDropdownExpanded;
    this.isEarnDropdownExpanded = false;
    this.isNftDropdownExpanded = false;
    this.isCommunityDropdownExpanded = false;
  }
  toggleEarnDropdown() {
    this.isEarnDropdownExpanded = !this.isEarnDropdownExpanded;
  }
  toggleEarnDropdownHover() {
    this.isEarnDropdownExpanded = !this.isEarnDropdownExpanded;
    this.isTokenDropdownExpanded = false;
    this.isNftDropdownExpanded = false;
    this.isCommunityDropdownExpanded = false;
  }
  toggleNftDropdown() {
    this.isNftDropdownExpanded = !this.isNftDropdownExpanded;
  }
  toggleNftDropdownHover() {
    this.isNftDropdownExpanded = !this.isNftDropdownExpanded;
    this.isTokenDropdownExpanded = false;
    this.isEarnDropdownExpanded = false;
    this.isCommunityDropdownExpanded = false;
  }
  toggleCommunityDropdown() {
    this.isCommunityDropdownExpanded = !this.isCommunityDropdownExpanded;
  }
  toggleCommunityDropdownHover() {
    this.isCommunityDropdownExpanded = !this.isCommunityDropdownExpanded;
    this.isTokenDropdownExpanded = false;
    this.isEarnDropdownExpanded = false;
    this.isNftDropdownExpanded = false;
  }
  async setNetworkDetails(response: any) {
    let networkDetailsIndex = this.networkDetails.chainId.indexOf(
      response.chainId
    );
    if (networkDetailsIndex >= 0) {
      await this.commonContractService.currentNetworkDetails(
        networkDetailsIndex
      );
      this.currentNativeCurrency =
        this.commonContractService.currentNativeCurrency;
      this.storage.set('nativeCurrency', this.currentNativeCurrency);
      this.currentNetworkName = this.commonContractService.currentNetworkName;
      this.currentNetworkSymbol =
        this.commonContractService.currentNetworkSymbol;
      this.isChooseNetworkShown = false;
    } else {
      // // chain changed
      this.currentNetworkName = 'NotSupport';
      this.currentNetworkSymbol = '/assets/icons/navbar/unsupported.svg';
      this.currentNativeCurrency = '';
      this.isChooseNetworkShown = true;
    }
  }

  out() {
    this.isEarnDropdownExpanded = !this.isEarnDropdownExpanded;
  }

  // async nativeBalance() {
  //   try {
  //     let userNativeBalance =
  //       await this.commonContractService.getNativeCurrencyBalance();
  //     this.userNativeBalance = parseFloat(userNativeBalance).toFixed(4);
  //   } catch {
  //     return;
  //   }
  // }

  async ngOnDestroy() {

    this.connectObservable?.unsubscribe();
    this.userNativeBalanceObservable?.unsubscribe();
    this.observable?.unsubscribe();

  }
  setNativeCurrencyNull() {
    this.storage.remove('connection');
    this.storage.remove('account');
    this.storage.set('nativeCurrency', '-');
    this.storage.set('isWalletConnected', false);
  }
}
