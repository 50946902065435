<!-- This example requires Tailwind CSS v2.0+ -->
<nav class="fixed w-full top-0 left-0 z-50" [ngClass]="{'bg-secondary-black': isScrolled}">
  <div class="max-w-8xl mx-auto px-2 py-2 sm:px-6 lg:px-8 2xl:px-0">
      <div class="relative flex items-center justify-between h-16 lg:h-20 py-8">
          <div class="absolute inset-y-0 left-0 flex items-center sm:hidden">

          </div>
          <div class="flex-1 flex items-center justify-start">
              <div class="flex-shrink-0 flex md:items-center w-40">
                <a routerLink="/coming-soon"><img class="block lg:hidden h-8 w-auto m-logo" src="assets/images/logo-sm.png" alt="bx-finance"></a>
                <a routerLink="/coming-soon"><img class="lg:block hidden w-full h-full" src="assets/images/logo.svg" alt="bx-finance"></a>
              </div>

          </div>
          <div class="absolute space-x-6 inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
              <!-- <button type="button" class="bg-primary text-white font-normal p-2 px-5 rounded-full focus:outline-none text-sm hidden lg:block" (click)="chooseNetwork()">Choose Network </button>

              <button type="button" class="block lg:hidden" (click)="chooseNetwork()">
                  <img src="assets/icons/globe-light.svg" class="w-6 h-6" alt="bx-finance">
              </button> -->
              <div class="ml-0">
                  <button *ngIf="!isMetaMaskConnected" type="button" (click)="connectMetaMask()" class=" bg-[#4E1FA3]/30 border-[#4E1FA3] border border-solid sy--font-medium text-center rounded-lg p-4 text-[#d9d9d9] text-base ">Connect Wallet </button>
                   <button *ngIf="isMetaMaskConnected && !showWrongNetworkPopup" type="button" class="bg-[#4E1FA3]/30 border-[#4E1FA3] border border-solid sy--font-medium text-center rounded-lg p-4 text-[#d9d9d9] text-base  focus:outline-none w-32 md:w-40 truncate"> {{ walletAddress }} </button>
                  <button *ngIf="isMetaMaskConnected && showWrongNetworkPopup" type="button" class=" bg-[#4E1FA3]/30 border-[#4E1FA3] border border-solid sy--font-medium text-center rounded-lg p-4 text-[#d9d9d9] text-base "> Wrong network </button>
              </div>

          </div>
      </div>
  </div>


</nav>

<!-- BEGIN: Farm Wallet Connect Modal -->
<div class="fixed z-50 inset-0 overflow-y-auto" [ngClass]="isChooseNetworkShown == true ? 'block' : 'hidden'" aria-labelledby="modal-title" role="dialog" aria-modal="true">
  <div class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
      <div class="fixed inset-0 bg-[#0f172acc] backdrop-blur-md transition-opacity" aria-hidden="true"></div>

      <!-- This element is to trick the browser into centering the modal contents. -->
      <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
      <div class="relative inline-block align-bottom bg-secondary-dark shadow-md p-6 rounded-xl text-left overflow-hidden transform transition-all sm:my-8 sm:align-middle sm:max-w-md">
          <div class="flex justify-between items-center">
              <div class="flex justify-between items-center">
                  <h2 class="font-bold text-white text-base lg:text-lg 2xl:text-xl">Choose Network
                  </h2>
              </div>
              <div (click)="closeChooseNetwork()" class="bg-secondary-black cursor-pointer w-8 h-8 md:h-10 md:w-10 rounded-lg flex justify-center items-center">
                  <img src="assets/icons/x-light.svg" class="m-auto w-4 h-4 md:w-5 md:h-5" alt="bx-finance" />
              </div>
          </div>
          <hr class="border-grey border-b-2 border-solid my-3" />
          <div class="grid grid-cols-12 items-center gap-4 mt-4">
              <div class="col-span-6">
                 <div (click)="changeEthereumChain()" class="bg-secondary-black p-4 py-6 rounded-xl cursor-pointer hover:scale-105 hover:shadow-md ease-out duration-300">
                  <img src="assets/icons/navbar/ethereum.svg" class="mx-auto w-8 h-8 md:w-12 md:h-12" alt="bx-finance">
                  <p class="text-white text-sm md:text-base font-normal text-center mt-2">Ethereum</p>
                 </div>
              </div>
              <div class="col-span-6">
                  <div (click)="changeBscChain()" class="bg-secondary-black p-4 py-6 rounded-xl cursor-pointer hover:scale-105 hover:shadow-md ease-out duration-300">
                   <img src="assets/icons/navbar/bsc-testnet.png" class="mx-auto w-8 h-8 md:w-12 md:h-12" alt="bx-finance">
                   <p class="text-white text-sm md:text-base font-normal text-center mt-2">BNB Smart Chain</p>
                  </div>
               </div>
               <div class="col-span-6">
                  <div (click)="changePolygonChain()" class="bg-secondary-black p-4 py-6 rounded-xl cursor-pointer hover:scale-105 hover:shadow-md ease-out duration-300">
                   <img src="assets/icons/navbar/polygon.png" class="mx-auto w-8 h-8 md:w-12 md:h-12" alt="bx-finance">
                   <p class="text-white text-sm md:text-base font-normal text-center mt-2">Main (Polygon)</p>
                  </div>
               </div>
               <div class="col-span-6">
                   <div (click)="changeAvalancheChain()" class="bg-secondary-black p-4 py-6 rounded-xl cursor-pointer hover:scale-105 hover:shadow-md ease-out duration-300">
                    <img src="assets/icons/navbar/avalanchee.svg" class="mx-auto w-8 h-8 md:w-12 md:h-12" alt="bx-finance">
                    <p class="text-white text-sm md:text-base font-normal text-center mt-2">Avalanche</p>
                   </div>
                </div>
                <div class="col-span-6">
                  <div (click)="changeFantomChain()" class="bg-secondary-black p-4 py-6 rounded-xl cursor-pointer hover:scale-105 hover:shadow-md ease-out duration-300">
                   <img src="assets/icons/navbar/fantom.svg" class="mx-auto w-8 h-8 md:w-12 md:h-12" alt="bx-finance">
                   <p class="text-white text-sm md:text-base font-normal text-center mt-2">Fantom Opera</p>
                  </div>
               </div>
               <div class="col-span-6">
                   <div (click)="changeCronosChain()" class="bg-secondary-black p-4 py-6 rounded-xl cursor-pointer hover:scale-105 hover:shadow-md ease-out duration-300">
                    <img src="assets/icons/navbar/coronos.svg" class="mx-auto w-8 h-8 md:w-12 md:h-12" alt="bx-finance">
                    <p class="text-white text-sm md:text-base font-normal text-center mt-2">Cronos</p>
                   </div>
                </div>
                <div class="col-span-12">
                  <div class="flex justify-between items-center">
                      <h2 class="font-bold text-white text-sm md:text-base lg:text-lg 2xl:text-xl">Test Net
                      </h2>
                  </div>
                </div>
                <div class="col-span-6">
                  <div (click)="changeBscChain()" class="bg-secondary-black p-4 py-6 rounded-xl cursor-pointer hover:scale-105 hover:shadow-md ease-out duration-300">
                   <img src="assets/icons/navbar/bsc-testnet.png" class="mx-auto w-8 h-8 md:w-12 md:h-12" alt="bx-finance">
                   <p class="text-white text-sm md:text-base font-normal text-center mt-2">BNB Smart Chain</p>
                  </div>
               </div>
               <div class="col-span-6">
                   <div (click)="changePolygonChain()" class="bg-secondary-black p-4 py-6 rounded-xl cursor-pointer hover:scale-105 hover:shadow-md ease-out duration-300">
                    <img src="assets/icons/navbar/polygon.png" class="mx-auto w-8 h-8 md:w-12 md:h-12" alt="bx-finance">
                    <p class="text-white text-sm md:text-base font-normal text-center mt-2">Matic Mumbai</p>
                   </div>
                </div>
          </div>


      </div>
  </div>
</div>
<!-- end: Farm Wallet Connect Modal -->
