import { Component } from '@angular/core';
import { SocialTagsService } from './shared/services/social-tags.service';

// import { TITLE_PROVIDER } from './title-provider';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  public constructor(private tagsService: SocialTagsService) {
    this.tagsService.setTitleAndTags();
  }
}
