import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { StorageService } from 'ng-blockchainx';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from '../services/authentication.service';

@Component({
  selector: 'app-invalid-session-popup',
  templateUrl: './invalid-session-popup.component.html',
  styleUrls: ['./invalid-session-popup.component.css']
})
export class InvalidSessionPopupComponent implements OnInit {

  @Input() invalidSessionPopup: boolean | undefined = false;
  public account: any;


  constructor(
    private http: HttpClient,
    private authService: AuthenticationService,
    private storage: StorageService,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.account = this.storage.get('account');
  }

  /**
   * Clears user session
   */
  public async clearUserSession() {
    await this.clearSession(this.account.walletAddress)
      .then(async (result: any) => {
        this.invalidSessionPopup = false;
        this.setNativeCurrencyNull();
        this.storage.remove('access_token');
        this.storage.set('isWalletConnected', false);
        // wallet connect disconnect
        this.storage.remove('walletconnect');
        this.storage.remove('walletType');
        window.location.reload();
      })
      .catch((err) => {
        this.toastr.error(err.data.error.message);
      });
  }

  private setNativeCurrencyNull() {
    this.storage.remove('connection');
    this.storage.remove('account');
    this.storage.set('nativeCurrency', '-');
    this.storage.set('isWalletConnected', false);
  }

  /**
 * Logins navbar component
 * @param params
 * @returns
 */
  private async clearSession(params: any) {
    return new Promise((resolve, reject) => {
      this.authService.clearAllUserSession(params).subscribe({
        next: (response: any) => {
          resolve({ status: true, data: response.data });
        },
        error: (error) => {
          reject({ status: false, data: error });
        },
      });
    });
  }



}
