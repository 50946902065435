import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';
import { ScullyRoutesService } from '@scullyio/ng-lib';
import { filter, map } from 'rxjs/operators';
import { data } from '../../helpers/dynamicMetadata'
import { environment } from "src/environments/environment"

@Injectable({
    providedIn: 'root'
})
export class SocialTagsService {

    public test: any;
    public META_DATA : any;
    public DEFAULT_META_DATA : any;

    public constructor(private titleService: Title,
        private router: Router,
        private scully: ScullyRoutesService,
        private meta: Meta,
        @Inject(DOCUMENT) private dom: any) {
    }

    setCanonicalURL(url?: string) {
        this.destroyLinkForCanonicalURL()
        const canURL = url == undefined ? this.dom.URL : url;
        const link: HTMLLinkElement = this.dom.createElement('link');
        link.setAttribute('rel', 'canonical');
        this.dom.head.appendChild(link);
        link.setAttribute('href', canURL);
    }
    destroyLinkForCanonicalURL() {
        const els = document.querySelectorAll('link[rel=\'canonical\']');
        for (let i = 0, l = els.length; i < l; i++) {
            const el = els[i];
            el.remove();
        }
    }

    setTitleAndTags() {

        if(environment.production){
            this.META_DATA = data.META_DATA_PROD;
            this.DEFAULT_META_DATA = data.DEFAULT_META_DATA_PROD
       }
       else{
           this.META_DATA = data.META_DATA_DEV;
           this.DEFAULT_META_DATA = data.DEFAULT_META_DATA_DEV
       }
       console.log("environment.production : ",environment.production);

        this.router.events.pipe(
            filter((event) => event instanceof NavigationEnd)
        ).subscribe((event) => {
            this.test = event;
            console.log("current route : ", this.test.url);
            this.scully.getCurrent().subscribe(
                () => {
                    try {
                        this.titleService.setTitle(this.META_DATA[this.test.url].app_title)
                        console.log('app_title', this.META_DATA[this.test.url].app_title);
                        
                        this.meta.updateTag({ name: "keywords", content: this.META_DATA[this.test.url].meta_keywords })
                        this.meta.updateTag({ name: "description", content: this.META_DATA[this.test.url].meta_description })
                        this.meta.updateTag({ name: "twitter:card", content: this.META_DATA[this.test.url].twitter_card });
                        this.meta.updateTag({ name: "twitter:site", content: this.META_DATA[this.test.url].twitter_site });
                        this.meta.updateTag({ name: "twitter:title", content: this.META_DATA[this.test.url].twitter_title });
                        this.meta.updateTag({ name: "twitter:description", content: this.META_DATA[this.test.url].twitter_description });
                        this.meta.updateTag({ name: "twitter:image", content: this.META_DATA[this.test.url].twitter_image });
                        this.meta.updateTag({ property: "twitter:domain", content: this.META_DATA[this.test.url].twitter_domain });
                        this.meta.updateTag({ property: "twitter:url", content: this.META_DATA[this.test.url].twitter_url });
                        this.meta.updateTag({ property: "og:type", content: this.META_DATA[this.test.url].og_type });
                        this.meta.updateTag({ property: "og:title", content: this.META_DATA[this.test.url].og_title });
                        this.meta.updateTag({ property: "og:description", content: this.META_DATA[this.test.url].og_description });
                        this.meta.updateTag({ property: "og:url", content: this.META_DATA[this.test.url].og_url });
                        this.meta.updateTag({ property: "og:image", content: this.META_DATA[this.test.url].og_image });

                        this.setCanonicalURL(this.META_DATA[this.test.url].canonical_url);
                    }
                    catch (error) {
                        console.log("error : ", error);
                        this.titleService.setTitle(this.DEFAULT_META_DATA[this.test.url].app_title)
                        this.meta.updateTag({ name: "keywords", content: this.DEFAULT_META_DATA[this.test.url].meta_keywords })
                        this.meta.updateTag({ name: "description", content: this.DEFAULT_META_DATA[this.test.url].meta_description })
                        this.meta.updateTag({ name: "twitter:card", content: this.DEFAULT_META_DATA[this.test.url].twitter_card });
                        this.meta.updateTag({ name: "twitter:site", content: this.DEFAULT_META_DATA[this.test.url].twitter_site });
                        this.meta.updateTag({ name: "twitter:title", content: this.DEFAULT_META_DATA[this.test.url].twitter_title });
                        this.meta.updateTag({ name: "twitter:description", content: this.DEFAULT_META_DATA[this.test.url].twitter_description });
                        this.meta.updateTag({ name: "twitter:image", content: this.DEFAULT_META_DATA[this.test.url].twitter_image });
                        this.meta.updateTag({ property: "twitter:domain", content: this.DEFAULT_META_DATA[this.test.url].twitter_domain });
                        this.meta.updateTag({ property: "twitter:url", content: this.DEFAULT_META_DATA[this.test.url].twitter_url });
                        this.meta.updateTag({ property: "og:type", content: this.DEFAULT_META_DATA[this.test.url].og_type });
                        this.meta.updateTag({ property: "og:title", content: this.DEFAULT_META_DATA[this.test.url].og_title });
                        this.meta.updateTag({ property: "og:description", content: this.DEFAULT_META_DATA[this.test.url].og_description });
                        this.meta.updateTag({ property: "og:url", content: this.DEFAULT_META_DATA[this.test.url].og_url });
                        this.meta.updateTag({ property: "og:image", content: this.DEFAULT_META_DATA[this.test.url].og_image });

                        this.setCanonicalURL(this.DEFAULT_META_DATA[this.test.url].canonical_url);
                    }
                })
        });
    }
}