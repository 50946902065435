import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { PopupService } from '../services/popup.service';

@Component({
  selector: 'app-waiting-popup',
  templateUrl: './waiting-popup.component.html',
  styleUrls: ['./waiting-popup.component.css']
})
export class WaitingPopupComponent implements OnInit, OnChanges {

  @Input() isWaitingForConfirmationShown: boolean | undefined = false;

  constructor(private popupService: PopupService) { }

  ngOnChanges(changes: SimpleChanges): void {
    console.log(changes);
  }

  ngOnInit(): void {
    this.popupService.popupState$.subscribe((popupState: any) => {
      this.isWaitingForConfirmationShown = popupState;
    })
  }

  /**
   *
   * @param value
   */
  toggleModal(value: boolean) { this.isWaitingForConfirmationShown = value; }
}
