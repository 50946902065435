import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { MetaMaskService, StorageService } from 'ng-blockchainx';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { CommonContractService } from '../services/common-contract.service';

@Component({
  selector: 'app-navbarc',
  templateUrl: './navbarc.component.html',
  styleUrls: ['./navbarc.component.css']
})
export class NavbarcComponent implements OnInit, OnDestroy {
  public isScrolled: boolean = false;
  public isMetaMaskConnected: boolean = false;
  public showWrongNetworkPopup: boolean = false;
  public connectedChainId:string = '';
  public walletAddress:string = '';
  public metaMaskSubscription: any;
  public innerWidth!:number;
  public isMobileNavExpanded: boolean = false;
  public isTokenDropdownExpanded: boolean = false;
  public isEarnDropdownExpanded: boolean = false;
  public isNftDropdownExpanded: boolean = false;
  public isCommunityDropdownExpanded: boolean = false;
  public isMobTokenExpanded: boolean = false;
  public isMobNftExpanded: boolean = false;
  public isMobEarnExpanded: boolean = false;
  public isMobCommunityExpanded: boolean = false;
  public isChooseNetworkShown : boolean = false;
  public ethWeb3: any;

  constructor(private toastr: ToastrService,private metaMaskService: MetaMaskService, private storage: StorageService, private commonContractService: CommonContractService) {
  }

  ngOnInit(): void {
    this.metaMaskService.setSupportedChains(['0x61','11155111','0x3ec','0x152','0xfa2','0xa869','0x13881','0x635ae020','0x507']);
    this.metaMaskSubscription = this.metaMaskService.connectionListener.subscribe((response: any) => {
      if (response.code == 250601) {
        this.connectionHandler(response.data);
        this.validateNetwork(response.data.chainId);
      }
      if (response.code == 250610) {
        // chain changed
        console.log(response, 'chain changed')
        this.storage.set('account', {
          walletAddress: this.walletAddress,
          chainId: response.data.chainId
        });
        window.location.reload();
      }
      console.log('oninit',response);
    })
  }

  /**
   * connect metamask
   */
  async connectMetaMask() {
    try {
      const connection: any = await this.metaMaskService.connectMetaMask().catch((error: any) => {
        console.log(error);
        this.toastr.error(error.data.message);
      });
      if (connection) {
        window.location.reload();
      }
    } catch (exception) {
      console.log('Exception ', exception);
    }
  }

  validateNetwork(chainId: string) {
    if (environment.ALLOWED_NETWORKS.indexOf(chainId) == -1) {
      this.showWrongNetworkPopup = true;
    }
  }

  async changeBscChain() {
    try {
      const chainInfo = await this.metaMaskService.chainDetails(environment.ALLOWED_NETWORKS[0]);
      console.log('chain info',chainInfo);
      const chainIdToHex = this.commonContractService.ethWeb3.utils.toHex(chainInfo.chainId);
      const addTokenParam = {
        method:'wallet_addEthereumChain',
        params: [{
          chainId: chainIdToHex,
          chainName: chainInfo.name,
          nativeCurrency: chainInfo.nativeCurrency,
          rpcUrls: chainInfo.rpc,
          blockExplorerUrls: chainInfo.explorers.url
        }]
      }
      console.log('addTokenParam',addTokenParam);
      this.metaMaskService.addNetwork(addTokenParam);
      if(chainInfo) {
        this.metaMaskService.changeNetwork(environment.ALLOWED_NETWORKS[0]);
      }
    } catch (error) {
      this.toastr.error('error');
    }
  }

  async changeEthereumChain() {
    try {
      const chainInfo = await this.metaMaskService.chainDetails(environment.ALLOWED_NETWORKS[1]);
      console.log('chain info',chainInfo);
      const chainIdToHex = this.commonContractService.ethWeb3.utils.toHex(chainInfo.chainId);
      const addTokenParam = {
        method:'wallet_addEthereumChain',
        params: [{
          chainId: chainIdToHex,
          chainName: chainInfo.name,
          nativeCurrency: chainInfo.nativeCurrency,
          rpcUrls: chainInfo.rpc,
          blockExplorerUrls: chainInfo.explorers.url
        }]
      }
      console.log('addTokenParam',addTokenParam);
      this.metaMaskService.addNetwork(addTokenParam);
      if(chainInfo) {
        this.metaMaskService.changeNetwork(environment.ALLOWED_NETWORKS[1]);
      }
    } catch (error) {
      this.toastr.error('error');
    }
  }

  async changePolygonChain() {
    try {
      const chainInfo = await this.metaMaskService.chainDetails(environment.ALLOWED_NETWORKS[9]);
      console.log('chain info',chainInfo);
      const chainIdToHex = this.commonContractService.ethWeb3.utils.toHex(chainInfo.chainId);
      const addTokenParam = {
        method:'wallet_addEthereumChain',
        params: [{
          chainId: chainIdToHex,
          chainName: chainInfo.name,
          nativeCurrency: chainInfo.nativeCurrency,
          rpcUrls: chainInfo.rpc,
          blockExplorerUrls: chainInfo.explorers.url
        }]
      }
      console.log('addTokenParam',addTokenParam);
      this.metaMaskService.addNetwork(addTokenParam);
      if(chainInfo) {
        this.metaMaskService.changeNetwork(environment.ALLOWED_NETWORKS[9]);
      }
    } catch (error) {
        this.toastr.error('error');
      }
  }

  async changeAvalancheChain() {
    try {
      const chainInfo = await this.metaMaskService.chainDetails(environment.ALLOWED_NETWORKS[8]);
      console.log('chain info',chainInfo);
      const chainIdToHex = this.commonContractService.ethWeb3.utils.toHex(chainInfo.chainId);
      const addTokenParam = {
        method:'wallet_addEthereumChain',
        params: [{
          chainId: chainIdToHex,
          chainName: chainInfo.name,
          nativeCurrency: chainInfo.nativeCurrency,
          rpcUrls: chainInfo.rpc,
          blockExplorerUrls: chainInfo.explorers.url
        }]
      }
      console.log('addTokenParam',addTokenParam);
      this.metaMaskService.addNetwork(addTokenParam);
      if(chainInfo) {
        this.metaMaskService.changeNetwork(environment.ALLOWED_NETWORKS[8]);
      }
    } catch (error) {
      this.toastr.error('error');
    }
  }

  async changeFantomChain() {
    try {
      const chainInfo = await this.metaMaskService.chainDetails(environment.ALLOWED_NETWORKS[7]);
      console.log('chain info',chainInfo);
      const chainIdToHex = this.commonContractService.ethWeb3.utils.toHex(chainInfo.chainId);
      const addTokenParam = {
        method:'wallet_addEthereumChain',
        params: [{
          chainId: chainIdToHex,
          chainName: chainInfo.name,
          nativeCurrency: chainInfo.nativeCurrency,
          rpcUrls: chainInfo.rpc,
          blockExplorerUrls: chainInfo.explorers.url
        }]
      }
      console.log('addTokenParam',addTokenParam);
      this.metaMaskService.addNetwork(addTokenParam);
      if(chainInfo) {
        this.metaMaskService.changeNetwork(environment.ALLOWED_NETWORKS[7]);
      }
    } catch (error) {
      this.toastr.error('error');
    }
  }

  async changeCronosChain() {
    try {
      const chainInfo = await this.metaMaskService.chainDetails(environment.ALLOWED_NETWORKS[4]);
      console.log('chain info',chainInfo);
      const chainIdToHex = this.commonContractService.ethWeb3.utils.toHex(chainInfo.chainId);
      const addTokenParam = {
        method:'wallet_addEthereumChain',
        params: [{
          chainId: chainIdToHex,
          chainName: chainInfo.name,
          nativeCurrency: chainInfo.nativeCurrency,
          rpcUrls: chainInfo.rpc,
          blockExplorerUrls: chainInfo.explorers.url
        }]
      }
      console.log('addTokenParam',addTokenParam);
      this.metaMaskService.addNetwork(addTokenParam);
      if(chainInfo) {
        this.metaMaskService.changeNetwork(environment.ALLOWED_NETWORKS[4]);
      }
    } catch (error) {
      this.toastr.error('error');
    }
  }

  connectionHandler(account: any) {
    this.connectedChainId = account.chainId;
    this.walletAddress = account.account[0];
    console.log('this.walletAddress',this.walletAddress);

    this.storage.set('account', {
      walletAddress: this.walletAddress,
      chainId: this.connectedChainId
    });
    this.isMetaMaskConnected = true;
  }

  // addNewNetwork() {
  //   this.metaMaskService.addNetwork();
  // }

  @HostListener("window:scroll")
  scrollEvent() {
    window.scrollY >= 50 ? (this.isScrolled = true) : (this.isScrolled = false);
  }

  chooseNetwork() {
    this.isChooseNetworkShown = true;
  }
  closeChooseNetwork() {
    this.isChooseNetworkShown = false;
  }
  toggleMobileNav(){
    this.isMobileNavExpanded = !this.isMobileNavExpanded;
  }
  toggleTokenDropdown(){
    this.isTokenDropdownExpanded = !this.isTokenDropdownExpanded;
  }
  toggleTokenDropdownHover(){
    this.isTokenDropdownExpanded = !this.isTokenDropdownExpanded;
    this.isEarnDropdownExpanded = false;
    this.isNftDropdownExpanded = false;
    this.isCommunityDropdownExpanded = false;
  }
  toggleEarnDropdown(){
    this.isEarnDropdownExpanded = !this.isEarnDropdownExpanded;
  }
  toggleEarnDropdownHover(){
    this.isEarnDropdownExpanded = !this.isEarnDropdownExpanded;
    this.isTokenDropdownExpanded = false;
    this.isNftDropdownExpanded = false;
    this.isCommunityDropdownExpanded = false;
  }
  toggleNftDropdown(){
    this.isNftDropdownExpanded = !this.isNftDropdownExpanded;
  }
  toggleNftDropdownHover(){
    this.isNftDropdownExpanded = !this.isNftDropdownExpanded;
    this.isTokenDropdownExpanded = false;
    this.isEarnDropdownExpanded = false;
    this.isCommunityDropdownExpanded = false;
  }
  toggleCommunityDropdown(){
    this.isCommunityDropdownExpanded = !this.isCommunityDropdownExpanded;
  }
  toggleCommunityDropdownHover(){
    this.isCommunityDropdownExpanded = !this.isCommunityDropdownExpanded;
    this.isTokenDropdownExpanded = false;
    this.isEarnDropdownExpanded = false;
    this.isNftDropdownExpanded = false;
  }

  out(){
    this.isEarnDropdownExpanded = !this.isEarnDropdownExpanded;
  }

  ngOnDestroy(): void {
    this.metaMaskSubscription?.unsubscribe();
  }
}
