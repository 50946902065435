import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PopupService {

  private popup$ = new BehaviorSubject<any>(false);
  popupState$ = this.popup$.asObservable();

  constructor() {}

  setPopupState(state: any) {
    this.popup$.next(state);
  }

  get close() {
    this.popup$.next(false);
    return true;
  }

  get open() {
    this.popup$.next(true);
    return true;
  }
}
