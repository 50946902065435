<!-- This example requires Tailwind CSS v2.0+ -->
<nav class="fixed w-full top-0 left-0 z-50" [ngClass]="isScrolled?'bg-secondary-black ':''">
  <div class="max-w-8xl mx-auto px-2 py-2 sm:px-6 lg:px-8 2xl:px-0">
    <div class="relative flex items-center justify-between h-16 lg:h-20 py-8">
      <div class="absolute inset-y-0 left-0 flex items-center sm:hidden">
        <!-- Mobile menu button-->
        <button (click)="toggleMobileNav()" type="button"
          class="inline-flex items-center justify-center p-2 rounded-md hover:text-white  focus:outline-none mobile-toggle"
          aria-controls="mobile-menu" aria-expanded="false">
          <span class="sr-only">Open main menu</span>
          <svg class="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="#fff"
            aria-hidden="true">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
          </svg>

          <svg class="hidden h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
            stroke="currentColor" aria-hidden="true">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
      </div>
      <div class="flex-1 flex items-center justify-start">
        <div class="flex-shrink-0 flex md:items-center w-40">
          <a routerLink="/"><img class="block lg:hidden h-8 w-auto m-logo" src="assets/images/logo-sm.png"
              alt="bx-finance"></a>
          <a routerLink="/"><img class="lg:block hidden w-full h-full" src="assets/images/logo.svg"
              alt="bx-finance"></a>
        </div>
        <div class="hidden sm:block sm:ml-0 md:ml-20">
          <div class="flex items-center space-x-4">
            <!-- Current: "text-white bg-primary", Default: "text-secondary hover:bg-primary hover:text-white" -->

            <div class="relative dropdown" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
              <!-- <a (click)="toggleTokenDropdown()" class="cursor-pointer text-secondary hover:text-white hover:bg-primary px-5 py-2 rounded-full font-medium">Token</a>  -->
              <a
                class="cursor-pointer text-secondary hover:text-white hover:bg-[#4E1FA3] px-5 py-2 rounded-full font-medium">Token</a>
              <div [ngClass]="{'hidden' : !isTokenDropdownExpanded, 'block' : 'isTokenDropdownExpanded'}"
                class="dropdown-menu absolute top-12 bg-secondary-dark p-6 rounded-xl mt-2 w-[42rem]">
                <div class="grid grid-cols-12 gap-6">
                  <div class="col-span-12 md:col-span-6">
                    <a routerLink="/token-generator" routerLinkActive="active"
                      [routerLinkActiveOptions]="{ exact: true }">
                      <div class="flex items-center gap-3 pb-4">
                        <img src="assets/icons/navbar/token-generator.svg" class="mr-2 w-12 h-12" alt="bx-finance" />
                        <span class="cursor-pointer">
                          <h2 class="font-bold text-white text-base lg:text-lg">Token
                            Generator</h2>
                          <p class="font-bold text-secondary text-xs lg:text-sm">Create an
                            ERC20 Token in a minute</p>
                        </span>
                      </div>
                    </a>
                    <a routerLink="/launchpad" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
                      <div class="flex items-center gap-3 pb-4">
                        <img src="assets/icons/navbar/token-launchpad.svg" class="mr-2 w-12 h-12" alt="bx-finance" />
                        <span class="cursor-pointer">
                          <h2 class="font-bold text-white text-base lg:text-lg">Token
                            Launchpad</h2>
                          <p class="font-bold text-secondary text-xs lg:text-sm">Get in early on latest gems</p>
                        </span>
                      </div>
                    </a>
                    <a routerLink="/airdrop" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
                      <div class="flex items-center gap-3">
                        <img src="assets/icons/navbar/airdrop-tokens.svg" class="mr-2 w-12 h-12" alt="bx-finance" />
                        <div>
                          <span class="cursor-pointer">
                            <h2 class="font-bold text-white text-base lg:text-lg">Airdrop
                              Tokens</h2>
                            <p class="font-bold text-secondary text-xs lg:text-sm">Send tokens to multiple users</p>
                          </span>
                        </div>
                      </div>
                    </a>
                  </div>
                  <div class="col-span-12 md:col-span-6">
                    <a routerLink="/locker" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
                      <div class="flex items-center gap-3 pb-4">
                        <img src="assets/icons/navbar/token-generator.svg" class="mr-2 w-12 h-12" alt="bx-finance" />
                        <div>
                          <span class="cursor-pointer">
                            <h2 class="font-bold text-white text-base lg:text-lg">Token/LP
                              Locker</h2>
                            <p class="font-bold text-secondary text-xs lg:text-sm">Lock your tokens securely</p>
                          </span>
                        </div>
                      </div>
                    </a>
                    <a routerLink="/migration" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
                      <div class="flex items-center gap-3 pb-4">
                        <img src="assets/icons/navbar/token-launchpad.svg" class="mr-2 w-12 h-12" alt="bx-finance" />
                        <div>
                          <h2 class="font-bold text-white text-base lg:text-lg">Token Migrator
                          </h2>
                          <p class="font-bold text-secondary text-xs lg:text-sm">Convert old tokens to new tokens</p>
                        </div>
                      </div>
                    </a>
                    <!-- <div class="flex items-center gap-3">
                                            <img src="assets/icons/navbar/airdrop-tokens.svg" class="mr-2 w-12 h-12"
                                                alt="bx-finance" />
                                            <div>
                                                <span tooltip="Coming Soon" placement="top" class="cursor-pointer">
                                                    <h2 class="font-bold text-white text-base lg:text-lg">Token Vesting
                                                    </h2>
                                                    <p class="font-bold text-secondary text-xs lg:text-sm">Locking and
                                                        Distributing</p>
                                                </span>
                                            </div>
                                        </div> -->
                  </div>
                </div>

              </div>
            </div>


            <div class="relative dropdown" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
              <a
                class="cursor-pointer text-secondary hover:bg-[#4E1FA3] hover:text-white px-5 py-2 rounded-full font-medium">NFT</a>
              <div [ngClass]="{'hidden' : !isNftDropdownExpanded, 'block' : 'isNftDropdownExpanded'}"
                class="absolute top-12 bg-secondary-dark p-6 rounded-xl mt-2 w-80 dropdown-menu">
                <a routerLink="/nft/collection" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
                  <div class="flex items-center gap-3 pb-4">
                    <img src="assets/icons/navbar/nft-generator.svg" class="mr-2 w-12 h-12" alt="bx-finance" />
                    <div>
                      <span class="cursor-pointer">
                        <h2 class="font-bold text-white text-base 2xl:text-lg">NFT Collection</h2>
                        <p class="font-bold text-secondary text-xs 2xl:text-sm">creators together on
                          a single platform</p>
                      </span>
                    </div>
                  </div>
                </a>
                <a routerLink="/nft-single-mint" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
                  <div class="flex items-center gap-3 pb-4">
                    <img src="assets/icons/navbar/nft-generator.svg" class="mr-2 w-12 h-12" alt="bx-finance" />
                    <div>
                      <span class="cursor-pointer">
                        <h2 class="font-bold text-white text-base 2xl:text-lg">NFT Generator</h2>
                        <p class="font-bold text-secondary text-xs 2xl:text-sm">creators together on
                          a single platform</p>
                      </span>
                    </div>
                  </div>
                </a>
                <a routerLink="/nft-bulk-mint" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
                  <div class="flex items-center gap-3 pb-4">
                    <img src="assets/icons/navbar/nft-generator.svg" class="mr-2 w-12 h-12" alt="bx-finance" />
                    <div>
                      <span class="cursor-pointer">
                        <h2 class="font-bold text-white text-base 2xl:text-lg">NFT Bulk Mint</h2>
                        <p class="font-bold text-secondary text-xs 2xl:text-sm">creators together on
                          a single platform</p>
                      </span>
                    </div>
                  </div>
                </a>
                <div class="flex items-center gap-3 pb-4">
                  <img src="assets/icons/navbar/nft-launchpad.svg" class="mr-2 w-12 h-12" alt="bx-finance" />
                  <div>
                    <span tooltip="Coming Soon" placement="top" class="cursor-pointer">
                      <h2 class="font-bold text-white text-base 2xl:text-lg">NFT Launchpad</h2>
                      <p class="font-bold text-secondary text-xs 2xl:text-sm">Buy premium and
                        exclusive NFTs</p>
                    </span>
                  </div>
                </div>
                <div class="flex items-center gap-3 pb-4">
                  <img src="assets/icons/navbar/nft-locker.svg" class="mr-2 w-12 h-12" alt="bx-finance" />
                  <div>
                    <span tooltip="Coming Soon" placement="top" class="cursor-pointer">
                      <h2 class="font-bold text-white text-base 2xl:text-lg">NFT Locker</h2>
                      <p class="font-bold text-secondary text-xs 2xl:text-sm">Easy Stake and High
                        Returns</p>
                    </span>
                  </div>
                </div>
                <a routerLink="/nft-airdrop">
                  <div class="flex items-center gap-3">
                    <img src="assets/icons/navbar/airdrop-nfts.svg" class="mr-2 w-12 h-12" alt="bx-finance" />
                    <div>
                      <span tooltip="Coming Soon" placement="top" class="cursor-pointer">
                        <h2 class="font-bold text-white text-base 2xl:text-lg">Airdrop NFTs</h2>
                        <p class="font-bold text-secondary text-xs 2xl:text-sm">A distribution
                          of NFTs assets</p>
                      </span>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div class="relative dropdown" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
              <a
                class="cursor-pointer text-secondary hover:text-white hover:bg-[#4E1FA3] px-5 py-2 rounded-full font-medium">Earn</a>
              <div [ngClass]="{'hidden' : !isEarnDropdownExpanded, 'block' : 'isEarnDropdownExpanded'}"
                class="absolute top-12 bg-secondary-dark p-6 rounded-xl mt-2 w-80 dropdown-menu ">
                <!-- <div class="flex items-center gap-3 pb-4">
                                    <img src="assets/icons/navbar/trade.svg" class="mr-2 w-12 h-12" alt="bx-finance" />
                                    <div>
                                        <span tooltip="Coming Soon" placement="top" class="cursor-pointer">
                                            <h2 class="font-bold text-white text-base lg:text-lg">Trade</h2>
                                            <p class="font-bold text-secondary text-xs lg:text-sm">Trade and Start
                                                Earning From Fees</p>
                                        </span>

                                    </div>
                                </div> -->
                <a routerLink="/farming" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
                  <div class="flex items-center gap-3 pb-4">
                    <img src="assets/icons/navbar/farming.svg" class="mr-2 w-12 h-12" alt="bx-finance" />
                    <span class="cursor-pointer">
                      <h2 class="font-bold text-white text-base lg:text-lg">Farming</h2>
                      <p class="font-bold text-secondary text-xs lg:text-sm">Earn double reward on
                        BX-Finance</p>
                    </span>
                  </div>
                </a>
                <a routerLink="/stake" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
                  <div class="flex items-center gap-3">
                    <img src="assets/icons/navbar/staking.svg" class="mr-2 w-12 h-12" alt="bx-finance" />
                    <span placement="top" class="cursor-pointer">
                      <h2 class="font-bold text-white text-base lg:text-lg">Staking</h2>
                      <p class="font-bold text-secondary text-xs lg:text-sm">Easy Stake and High
                        Returns</p>
                    </span>
                  </div>
                </a>
              </div>
            </div>

            <div class="relative dropdown" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
              <a
                class="cursor-pointer text-secondary hover:bg-[#4E1FA3] hover:text-white px-5 py-2 rounded-full font-medium">Community</a>
              <!-- <div [ngClass]="{'hidden' : !isCommunityDropdownExpanded, 'block' : 'isCommunityDropdownExpanded'}"
                                class="absolute top-12 bg-secondary-dark p-6 rounded-xl mt-2 w-80 dropdown-menu">
                                <div class="flex items-center gap-3 pb-4">
                                    <img src="assets/icons/navbar/dao.svg" class="mr-2 w-12 h-12" alt="bx-finance" />
                                    <div>
                                        <span tooltip="Coming Soon" placement="top" class="cursor-pointer">
                                            <h2 class="font-bold text-white text-base lg:text-lg">DAO</h2>
                                            <p class="font-bold text-secondary text-xs lg:text-sm">Encoded as a computer
                                                program</p>
                                        </span>
                                    </div>
                                </div>
                                <div class="flex items-center gap-3 pb-4">
                                    <img src="assets/icons/navbar/blog.svg" class="mr-2 w-12 h-12" alt="bx-finance" />
                                    <div>
                                        <span tooltip="Coming Soon" placement="top" class="cursor-pointer">
                                            <h2 class="font-bold text-white text-base lg:text-lg">Blog</h2>
                                            <p class="font-bold text-secondary text-xs lg:text-sm">Get the latest
                                                information</p>
                                        </span>
                                    </div>
                                </div>

                            </div> -->
            </div>
          </div>
        </div>
      </div>
      <div class="absolute space-x-6 inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">

        <button *ngIf="isMetaMaskConnected" type="button"
          class="bg-[#4E1FA3]/30 border-[#4E1FA3] border-solid border-2 text-white font-normal p-2 pr-4 rounded-full focus:outline-none text-sm hidden lg:flex gap-2 items-center"
          (click)="chooseNetwork()"><img class="w-6 h-6 rounded-full" src="{{currentNetworkSymbol}}" alt="bx-finance">
          {{currentNetworkName}}</button>
        <button type="button" class="block lg:hidden" (click)="chooseNetwork()">
          <img src="assets/icons/globe-light.svg" class="w-6 h-6" alt="bx-finance">
        </button>
        <!-- <button type="button" class="block lg:hidden" (click)="chooseWrongNetwork()">
                    <img src="assets/icons/globe-light.svg" class="w-6 h-6" alt="bx-finance">
                </button> -->
        <div class="ml-0">
          <button *ngIf="!isMetaMaskConnected" type="button" (click)="connectMetaMask()"
            class="bg-[#4E1FA3]/30 border-[#4E1FA3] border-solid border-2 text-white font-normal text-sm p-2 px-5 lg:px-8 rounded-full focus:outline-none hidden lg:flex">Connect
            Wallet
          </button>
          <button *ngIf="!isMetaMaskConnected" class="flex lg:hidden" type="button" (click)="connectMetaMask()">
            <img src="assets/icons/connect-wallet.svg" class="w-5 h-5 m-2" alt="">
          </button>
          <button *ngIf="isMetaMaskConnected && !showWrongNetworkPopup" type="button"
            class="bg-[#4E1FA3]/30 border border-[#4E1FA3] border-solid hidden lg:flex items-center text-white font-normal text-sm rounded-full focus:outline-none w-32 md:w-48 truncate">
            <div class="w-10 h-10 border-l-0 border border-[#4E1FA3] border-solid rounded-full">
              <img src="assets/icons/connect-wallet.svg" class="w-5 h-5 m-2" alt="">
            </div>
            <div>
              <p class="w-32 truncate text-primary-light">
                {{walletAddress.slice(0, 5)}}...{{walletAddress.slice(walletAddress.length - 5)}}
              </p>
              <p class="text-xs text-white font-normal">{{userNativeBalance}} {{currentNativeCurrency}}</p>
            </div>
          </button>


          <button *ngIf="isMetaMaskConnected && !showWrongNetworkPopup" type="button" placement="bottom"
            class="block lg:hidden"
            tooltip="<p class='w-32 truncate text-primary-light'>{{walletAddress.slice(0, 5)}}...{{walletAddress.slice(walletAddress.length - 5)}}</p><p class='text-xs text-white font-normal'>{{userNativeBalance}} {{currentNativeCurrency}}</p>">
            <img src="assets/icons/connect-wallet.svg" class=" w-5 h-5 m-2" alt="">
          </button>
        </div>
        <button type="button" *ngIf="isMetaMaskConnected" (click)="logout()">
          <img src="assets/icons/log-out.svg" class="w-5 h-5 lg:w-8 lg:h-8 m-2" alt="">
        </button>
        <!-- <button *ngIf="walletDetails" class="text-white hidden lg:flex" (click)="logout()">Logout</button> -->
      </div>
    </div>
  </div>

  <!-- Mobile menu, show/hide based on menu state. -->
  <div [ngClass]="{'hidden': !isMobileNavExpanded, 'block overflow-y-auto': isMobileNavExpanded}" class="sm:hidden"
    id="mobile-menu">
    <div class="px-2 pt-2 pb-3 space-y-1 bg-secondary-black rounded-xl">
      <!-- Current: "bg-primary text-white", Default: "text-secondary hover:bg-primary hover:text-white" -->
      <div routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
        <a (click)="isMobTokenExpanded = !isMobTokenExpanded"
          [ngClass]="{'bg-secondary-black text-secondary' : !isMobTokenExpanded, 'bg-secondary-light text-white rounded-md' : 'isMobTokenExpanded'}"
          class="flex justify-between items-center px-3 py-2 rounded-md text-base font-medium" aria-current="page">
          Token
          <img src="assets/icons/chevron-down-light.svg" class="w-5 h-5" alt="bx-finance">
        </a>
        <div [ngClass]="{'hidden' : !isMobTokenExpanded, 'block bg-secondary-light rounded-md' : 'isMobTokenExpanded'}">
          <span class="cursor-pointer">
            <a routerLink="/token-generator">
              <div class="flex items-center px-3 py-3 gap-3" routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }">
                <img src="assets/icons/navbar/token-generator.svg" class="w-6 h-6" alt="bx-finance" />
                <div>

                  <p class="text-white hover:text-white block  rounded-md text-base font-medium">Token
                    Generator </p>
                </div>

              </div>
            </a>
          </span>

          <span class="cursor-pointer">
            <a routerLink="/launchpad">
              <div class="flex items-center px-3 py-3 gap-3" routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }">
                <img src="assets/icons/navbar/token-launchpad.svg" class="w-6 h-6" alt="bx-finance" />
                <div class="text-white hover:text-white block  rounded-md text-base font-medium">
                  Token
                  Launchpad
                </div>
              </div>
            </a>
          </span>
          <span>
            <a routerLink="/airdrop" class="cursor-pointer">
              <div class="flex items-center px-3 py-3 gap-3" routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }">
                <img src="assets/icons/navbar/airdrop-tokens.svg" class="w-6 h-6" alt="bx-finance" />
                <div class="text-white hover:text-white block  rounded-md text-base font-medium">
                  Airdrop Tokens
                </div>
              </div>
            </a>
          </span>
          <span class="cursor-pointer">
            <div class="flex items-center px-3 py-3 gap-3">
              <img src="assets/icons/navbar/token-locker.svg" class="w-6 h-6" alt="bx-finance" />
              <div>
                <h2 class="text-white hover:text-white block  rounded-md text-base font-medium">Token LP
                  Locker</h2>
              </div>
            </div>
          </span>
          <span class="cursor-pointer">
            <a routerLink="/migration">
              <div class="flex items-center px-3 py-3 gap-3" routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }">
                <img src="assets/icons/navbar/token-migrator.svg" class="w-6 h-6" alt="bx-finance" />
                <div class="text-white hover:text-white block  rounded-md text-base font-medium">
                  Token Migrator
                </div>
              </div>
            </a>
          </span>
          <!-- <span tooltip="Coming Soon" trigger="click" placement="top" class="cursor-pointer">
                        <div class="flex items-center px-3 py-3 gap-3">
                            <img src="assets/icons/navbar/token-vesting.svg" class="w-6 h-6" alt="bx-finance" />
                            <div>
                                <h2 class="text-white hover:text-white block  rounded-md text-base font-medium">Token
                                    Vesting</h2>
                            </div>
                        </div>
                    </span> -->
        </div>
      </div>

      <div routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
        <a (click)="isMobNftExpanded = !isMobNftExpanded"
          [ngClass]="{'bg-secondary-black text-secondary' : !isMobNftExpanded, 'bg-secondary-light text-white rounded-md' : 'isMobNftExpanded'}"
          class="flex justify-between items-center px-3 py-2 rounded-md text-base font-medium" aria-current="page">
          <!-- <a [ngClass]="{'bg-secondary-black text-secondary' : !isMobNftExpanded, 'bg-secondary-light text-white rounded-md' : 'isMobNftExpanded'}" class="flex justify-between items-center px-3 py-2 rounded-md text-base font-medium" aria-current="page"> -->
          NFT
          <img src="assets/icons/chevron-down-light.svg" class="w-5 h-5" alt="bx-finance">
        </a>
        <!-- <div
                    [ngClass]="{'hidden' : !isMobNftExpanded, 'block bg-secondary-light rounded-md' : 'isMobNftExpanded'}">
                    <span tooltip="Coming Soon" trigger="click" placement="top" class="cursor-pointer">
                        <div class="flex items-center px-3 py-3 gap-3">
                            <img src="assets/icons/navbar/nft-generator.svg" class="w-6 h-6" alt="bx-finance" />
                            <div>
                                <h2 class="text-white hover:text-white block  rounded-md text-base font-medium">NFT
                                    Generator</h2>
                            </div>
                        </div>
                    </span>

                    <span tooltip="Coming Soon" trigger="click" placement="top" class="cursor-pointer">
                        <div class="flex items-center px-3 py-3 gap-3">
                            <img src="assets/icons/navbar/nft-launchpad.svg" class="w-6 h-6" alt="bx-finance" />
                            <div>
                                <h2 class="text-white hover:text-white block  rounded-md text-base font-medium">NFT
                                    Launchpad</h2>
                            </div>
                        </div>
                    </span>
                    <span tooltip="Coming Soon" trigger="click" placement="top" class="cursor-pointer">
                        <div class="flex items-center px-3 py-3 gap-3">
                            <img src="assets/icons/navbar/nft-locker.svg" class="w-6 h-6" alt="bx-finance" />
                            <div>
                                <h2 class="text-white hover:text-white block  rounded-md text-base font-medium">NFT
                                    Locker</h2>
                            </div>
                        </div>
                    </span>
                    <span tooltip="Coming Soon" trigger="click" placement="top" class="cursor-pointer">
                        <div class="flex items-center px-3 py-3 gap-3">
                            <img src="assets/icons/navbar/airdrop-nfts.svg" class="w-6 h-6" alt="bx-finance" />
                            <div>
                                <h2 class="text-white hover:text-white block  rounded-md text-base font-medium">Airdrop
                                    NFTs</h2>
                            </div>
                        </div>
                    </span>
                </div> -->
      </div>

      <div routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
        <a (click)="isMobEarnExpanded = !isMobEarnExpanded"
          [ngClass]="{'bg-secondary-black text-secondary' : !isMobEarnExpanded, 'bg-secondary-light text-white rounded-md' : 'isMobEarnExpanded'}"
          class="flex justify-between items-center px-3 py-2 rounded-md text-base font-medium" aria-current="page">
          <!-- <a [ngClass]="{'bg-secondary-black text-secondary' : !isMobEarnExpanded, 'bg-secondary-light text-white rounded-md' : 'isMobEarnExpanded'}" class="flex justify-between items-center px-3 py-2 rounded-md text-base font-medium" aria-current="page"> -->
          Earn
          <img src="assets/icons/chevron-down-light.svg" class="w-5 h-5" alt="bx-finance">
        </a>
        <div [ngClass]="{'hidden' : !isMobEarnExpanded, 'block bg-secondary-light rounded-md' : 'isMobEarnExpanded'}">
          <!-- <span tooltip="Coming Soon" trigger="click" placement="top" class="cursor-pointer">
                        <div class="flex items-center px-3 py-3 gap-3">
                            <img src="assets/icons/navbar/trade.svg" class="w-6 h-6" alt="bx-finance" />
                            <div>
                                <h2 class="text-white hover:text-white block  rounded-md text-base font-medium">Trade
                                </h2>
                            </div>
                        </div>
                    </span> -->

          <span class="cursor-pointer">
            <a routerLink="/farming">
              <div class="flex items-center px-3 py-3 gap-3" routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }">
                <img src="assets/icons/navbar/farming.svg" class="w-6 h-6" alt="bx-finance" />
                <div class="text-white hover:text-white block  rounded-md text-base font-medium">
                  Farming
                </div>
              </div>
            </a>
          </span>
          <span trigger="click" placement="top" class="cursor-pointer">
            <a routerLink="/stake">
              <div class="flex items-center px-3 py-3 gap-3" routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }">
                <img src="assets/icons/navbar/staking.svg" class="w-6 h-6" alt="bx-finance" />
                <div class="text-white hover:text-white block  rounded-md text-base font-medium">
                  Staking
                </div>
              </div>
            </a>
          </span>

        </div>
      </div>

      <div routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
        <a (click)="isMobCommunityExpanded = !isMobCommunityExpanded"
          [ngClass]="{'bg-secondary-black text-secondary' : !isMobCommunityExpanded, 'bg-secondary-light text-white rounded-md' : 'isMobCommunityExpanded'}"
          class="flex justify-between items-center px-3 py-2 rounded-md text-base font-medium" aria-current="page">
          <!-- <a [ngClass]="{'bg-secondary-black text-secondary' : !isMobCommunityExpanded, 'bg-secondary-light text-white rounded-md' : 'isMobCommunityExpanded'}" class="flex justify-between items-center px-3 py-2 rounded-md text-base font-medium" aria-current="page"> -->
          Community
          <img src="assets/icons/chevron-down-light.svg" class="w-5 h-5" alt="bx-finance">
        </a>
        <!-- <div
                    [ngClass]="{'hidden' : !isMobCommunityExpanded, 'block bg-secondary-light rounded-md' : 'isMobEarnExpanded'}">
                    <span tooltip="Coming Soon" trigger="click" placement="top" class="cursor-pointer">
                        <div class="flex items-center px-3 py-3 gap-3">
                            <img src="assets/icons/navbar/dao.svg" class="w-6 h-6" alt="bx-finance" />
                            <div>
                                <h2 class="text-white hover:text-white block  rounded-md text-base font-medium">DAO</h2>
                            </div>
                        </div>
                    </span>

                    <span tooltip="Coming Soon" trigger="click" placement="top" class="cursor-pointer">
                        <div class="flex items-center px-3 py-3 gap-3">
                            <img src="assets/icons/navbar/blog.svg" class="w-6 h-6" alt="bx-finance" />
                            <div>
                                <h2 class="text-white hover:text-white block  rounded-md text-base font-medium">Blog
                                </h2>
                            </div>
                        </div>
                    </span>
                </div> -->
      </div>
    </div>
  </div>
</nav>

<!-- BEGIN: Farm Wallet Connect Modal -->
<div class="fixed z-50 inset-0 overflow-y-auto" [ngClass]="isChooseNetworkShown == true ? 'block' : 'hidden'"
  aria-labelledby="modal-title" role="dialog" aria-modal="true">
  <div class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
    <div class="fixed inset-0 bg-[#0f172acc] backdrop-blur-md transition-opacity" aria-hidden="true"></div>

    <!-- This element is to trick the browser into centering the modal contents. -->
    <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
    <div
      class="relative inline-block align-bottom bg-secondary-dark shadow-md p-6 rounded-xl text-left overflow-hidden transform transition-all sm:my-8 sm:align-middle sm:w-[38rem] sm:max-w-lg">
      <div class="flex justify-between items-center">
        <div class="flex justify-between items-center">
          <h2 class="font-bold text-white text-base lg:text-lg 2xl:text-xl">Choose Network
          </h2>
        </div>
        <div (click)="closeChooseNetwork()"
          class="bg-secondary-black cursor-pointer w-8 h-8 md:h-10 md:w-10 rounded-lg flex justify-center items-center">
          <img src="assets/icons/x-light.svg" class="m-auto w-4 h-4 md:w-5 md:h-5" alt="bx-finance" />
        </div>
      </div>
      <hr class="border-grey border-b-2 border-solid my-3" />
      <div class="grid grid-cols-12 items-center gap-4 mt-4">
        <div class="col-span-12 md:col-span-6">
          <div (click)="changeNetwork(7)"
            class="bg-secondary-black flex items-center gap-3 px-4 py-3 rounded-lg border border-secondary-dark cursor-pointer hover:scale-105 hover:shadow-md hover:border-primary-light hover:border ease-out duration-300">
            <img src="assets/icons/navbar/ethereum.svg" class="w-6 h-6 rounded-full" alt="bx-finance">
            <p class="text-white text-sm md:text-lg font-normal text-center">Ethereum MainNet</p>
          </div>
        </div>
        <div class="col-span-12 md:col-span-6">
          <div (click)="changeNetwork(8)"
            class="bg-secondary-black flex items-center gap-3 px-4 py-3 rounded-lg border border-secondary-dark cursor-pointer hover:scale-105 hover:shadow-md hover:border-primary-light hover:border ease-out duration-300">
            <img src="assets/icons/navbar/bsc.svg" class="w-6 h-6 rounded-full" alt="bx-finance">
            <p class="text-white text-sm md:text-lg font-normal text-center">BSC MainNet</p>
          </div>
        </div>
        <div class="col-span-12 md:col-span-6">
          <div (click)="changeNetwork(9)"
            class="bg-secondary-black flex items-center gap-3 px-4 py-3 rounded-lg border border-secondary-dark cursor-pointer hover:scale-105 hover:shadow-md hover:border-primary-light hover:border ease-out duration-300">
            <img src="assets/icons/navbar/polygon.svg" class="w-6 h-6 rounded-full" alt="bx-finance">
            <p class="text-white text-sm md:text-lg font-normal text-center">Polygon MainNet</p>
          </div>
        </div>
         <div class="col-span-12 md:col-span-6">
          <div (click)="changeNetwork(10)"
            class="bg-secondary-black flex items-center gap-3 px-4 py-3 rounded-lg border border-secondary-dark cursor-pointer hover:scale-105 hover:shadow-md hover:border-primary-light hover:border ease-out duration-300">
            <img src="assets/icons/navbar/avalanchee.svg" class="w-6 h-6 rounded-full" alt="bx-finance">
            <p class="text-white text-sm md:text-lg font-normal text-center">Avalanche MainNet</p>
          </div>
        </div>
        <div class="col-span-12 md:col-span-6">
          <div (click)="changeNetwork(11)"
            class="bg-secondary-black flex items-center gap-3 px-4 py-3 rounded-lg border border-secondary-dark cursor-pointer hover:scale-105 hover:shadow-md hover:border-primary-light hover:border ease-out duration-300">
            <img src="assets/icons/navbar/fantom.svg" class="w-6 h-6 rounded-full" alt="bx-finance">
            <p class="text-white text-sm md:text-lg font-normal text-center">Fantom Opera MainNet</p>
          </div>
        </div>
        <div class="col-span-12 md:col-span-6">
          <div (click)="changeNetwork(12)"
            class="bg-secondary-black flex items-center gap-3 px-4 py-3 rounded-lg border border-secondary-dark cursor-pointer hover:scale-105 hover:shadow-md hover:border-primary-light hover:border ease-out duration-300">
            <img src="assets/icons/navbar/coronos.svg" class="w-6 h-6 rounded-full" alt="bx-finance">
            <p class="text-white text-sm md:text-lg font-normal text-center">Cronos MainNet</p>
          </div>
        </div>
        <div class="col-span-12 md:col-span-6">
          <div (click)="changeNetwork(13)"
            class="bg-secondary-black flex items-center gap-3 px-4 py-3 rounded-lg border border-secondary-dark cursor-pointer hover:scale-105 hover:shadow-md hover:border-primary-light hover:border ease-out duration-300">
            <img src="assets/icons/navbar/moonbeam.png" class="w-6 h-6 rounded-full" alt="bx-finance">
            <p class="text-white text-sm md:text-lg font-normal text-center">Moonbeam MainNet</p>
          </div>
        </div>
        <div class="col-span-12 md:col-span-6">
          <div  tooltipClass="tooltip-truncate" tooltip="Coming Soon" placement="top"
            class="bg-secondary-black flex items-center gap-3 px-4 py-3 rounded-lg border border-secondary-dark cursor-pointer hover:scale-105 hover:shadow-md hover:border-primary-light hover:border ease-out duration-300">
            <img src="assets/icons/navbar/arbitrum.png" class="w-6 h-6 rounded-full" alt="bx-finance">
            <p class="text-white text-sm md:text-lg font-normal text-center">Arbitrum</p>
          </div>
        </div>
        <div class="col-span-12 md:col-span-6">
          <div tooltipClass="tooltip-truncate" tooltip="Coming Soon" placement="top"
            class="bg-secondary-black flex items-center gap-3 px-4 py-3 rounded-lg border border-secondary-dark cursor-pointer hover:scale-105 hover:shadow-md hover:border-primary-light hover:border ease-out duration-300">
            <img src="assets/icons/navbar/arbitrum-nova.png" class="w-6 h-6 rounded-full" alt="bx-finance">
            <p class="text-white text-sm md:text-lg font-normal text-center">Arbitrum Nova</p>
          </div>
        </div>
       
       
        <div class="col-span-12 md:col-span-6">
          <div tooltipClass="tooltip-truncate" tooltip="Coming Soon" placement="top"
            class="bg-secondary-black flex items-center gap-3 px-4 py-3 rounded-lg border border-secondary-dark cursor-pointer hover:scale-105 hover:shadow-md hover:border-primary-light hover:border ease-out duration-300">
            <img src="assets/icons/navbar/gnosis.png" class="w-6 h-6 rounded-full" alt="bx-finance">
            <p class="text-white text-sm md:text-lg font-normal text-center">Gnosis</p>
          </div>
        </div>
       
        <div class="col-span-12 md:col-span-6">
          <div tooltipClass="tooltip-truncate" tooltip="Coming Soon" placement="top"
            class="bg-secondary-black flex items-center gap-3 px-4 py-3 rounded-lg border border-secondary-dark cursor-pointer hover:scale-105 hover:shadow-md hover:border-primary-light hover:border ease-out duration-300">
            <img src="assets/icons/navbar/moonriver.png" class="w-6 h-6 rounded-full" alt="bx-finance">
            <p class="text-white text-sm md:text-lg font-normal text-center">Moonriver</p>
          </div>
        </div>
        <div class="col-span-12 md:col-span-6">
          <div tooltipClass="tooltip-truncate" tooltip="Coming Soon" placement="top"
            class="bg-secondary-black flex items-center gap-3 px-4 py-3 rounded-lg border border-secondary-dark cursor-pointer hover:scale-105 hover:shadow-md hover:border-primary-light hover:border ease-out duration-300">
            <img src="assets/icons/navbar/harmonyone.jpg" class="w-6 h-6 rounded-full" alt="bx-finance">
            <p class="text-white text-sm md:text-lg font-normal text-center">Harmony</p>
          </div>
        </div>
        <div class="col-span-12 md:col-span-6">
          <div tooltipClass="tooltip-truncate" tooltip="Coming Soon" placement="top"
            class="bg-secondary-black flex items-center gap-3 px-4 py-3 rounded-lg border border-secondary-dark cursor-pointer hover:scale-105 hover:shadow-md hover:border-primary-light hover:border ease-out duration-300">
            <img src="assets/icons/navbar/optimism.png" class="w-6 h-6 rounded-full" alt="bx-finance">
            <p class="text-white text-sm md:text-lg font-normal text-center">Optimism</p>
          </div>
        </div>
        <!-- <div class="col-span-12 md:col-span-6">
          <div tooltipClass="tooltip-truncate" tooltip="Coming Soon" placement="top"
            class="bg-secondary-black flex items-center gap-3 px-4 py-3 rounded-lg border border-secondary-dark cursor-pointer hover:scale-105 hover:shadow-md hover:border-primary-light hover:border ease-out duration-300">
            <img src="assets/icons/navbar/moonbeam.png" class="w-6 h-6 rounded-full" alt="bx-finance">
            <p class="text-white text-sm md:text-lg font-normal text-center">Moonbeam</p>
          </div>
        </div> -->
        <div class="col-span-12 md:col-span-6">
          <div tooltipClass="tooltip-truncate" tooltip="Coming Soon" placement="top"
            class="bg-secondary-black flex items-center gap-3 px-4 py-3 rounded-lg border border-secondary-dark cursor-pointer hover:scale-105 hover:shadow-md hover:border-primary-light hover:border ease-out duration-300">
            <img src="assets/icons/navbar/fuse.png" class="w-6 h-6 rounded-full" alt="bx-finance">
            <p class="text-white text-sm md:text-lg font-normal text-center">Fuse</p>
          </div>
        </div>
        <div class="col-span-12 md:col-span-6">
          <div tooltipClass="tooltip-truncate" tooltip="Coming Soon" placement="top"
            class="bg-secondary-black flex items-center gap-3 px-4 py-3 rounded-lg border border-secondary-dark cursor-pointer hover:scale-105 hover:shadow-md hover:border-primary-light hover:border ease-out duration-300">
            <img src="assets/icons/navbar/kava.png" class="w-6 h-6 rounded-full" alt="bx-finance">
            <p class="text-white text-sm md:text-lg font-normal text-center">Kava</p>
          </div>
        </div>
        <div class="col-span-12 md:col-span-6">
          <div tooltipClass="tooltip-truncate" tooltip="Coming Soon" placement="top"
            class="bg-secondary-black flex items-center gap-3 px-4 py-3 rounded-lg border border-secondary-dark cursor-pointer hover:scale-105 hover:shadow-md hover:border-primary-light hover:border ease-out duration-300">
            <img src="assets/icons/navbar/metis.png" class="w-6 h-6 rounded-full object-cover" alt="bx-finance">
            <p class="text-white text-sm md:text-lg font-normal text-center">Metis</p>
          </div>
        </div>
        <div class="col-span-12 md:col-span-6">
          <div tooltipClass="tooltip-truncate" tooltip="Coming Soon" placement="top"
            class="bg-secondary-black flex items-center gap-3 px-4 py-3 rounded-lg border border-secondary-dark cursor-pointer hover:scale-105 hover:shadow-md hover:border-primary-light hover:border ease-out duration-300">
            <img src="assets/icons/navbar/telos.png" class="w-6 h-6 rounded-full" alt="bx-finance">
            <p class="text-white text-sm md:text-lg font-normal text-center">Telos EVM</p>
          </div>
        </div>
        <div class="col-span-12 md:col-span-6">
          <div tooltipClass="tooltip-truncate" tooltip="Coming Soon" placement="top"
            class="bg-secondary-black flex items-center gap-3 px-4 py-3 rounded-lg border border-secondary-dark cursor-pointer hover:scale-105 hover:shadow-md hover:border-primary-light hover:border ease-out duration-300">
            <img src="assets/icons/navbar/celo.jpg" class="w-6 h-6 rounded-full" alt="bx-finance">
            <p class="text-white text-sm md:text-lg font-normal text-center">Celo</p>
          </div>
        </div>
        <div class="col-span-12 md:col-span-6">
          <div tooltipClass="tooltip-truncate" tooltip="Coming Soon" placement="top"
            class="bg-secondary-black flex items-center gap-3 px-4 py-3 rounded-lg border border-secondary-dark cursor-pointer hover:scale-105 hover:shadow-md hover:border-primary-light hover:border ease-out duration-300">
            <img src="assets/icons/navbar/bobaavax.png" class="w-6 h-6 rounded-full" alt="bx-finance">
            <p class="text-white text-sm md:text-lg font-normal text-center">Boba Avax</p>
          </div>
        </div>
       

        <div class="col-span-12">
          <div class="flex justify-between items-center">
            <h2 class="font-bold text-white text-sm md:text-base lg:text-lg 2xl:text-xl">Test Net
            </h2>
          </div>
        </div>
        <div class="col-span-12 md:col-span-6">
          <div (click)="changeNetwork(0)"
            class="bg-secondary-black flex items-center gap-3 px-4 py-3 rounded-lg border border-secondary-dark cursor-pointer hover:scale-105 hover:shadow-md hover:border-primary-light hover:border ease-out duration-300">
            <img src="assets/icons/navbar/bsc-testnet.png" class="w-6 h-6 rounded-full" alt="bx-finance">
            <p class="text-white text-sm md:text-lg font-normal text-center">BSC TestNet</p>
          </div>
        </div>
        <div class="col-span-12 md:col-span-6">
          <div (click)="changeNetwork(1)"
            class="bg-secondary-black flex items-center gap-3 px-4 py-3 rounded-lg border border-secondary-dark cursor-pointer hover:scale-105 hover:shadow-md hover:border-primary-light hover:border ease-out duration-300">
            <img src="assets/icons/navbar/ethereum.svg" class="w-6 h-6 rounded-full" alt="bx-finance">
            <p class="text-white text-sm md:text-lg font-normal text-center">Ethereum Goerli TestNet</p>
          </div>
        </div>
        <div class="col-span-12 md:col-span-6">
          <div (click)="changeNetwork(2)"
            class="bg-secondary-black flex items-center gap-3 px-4 py-3 rounded-lg border border-secondary-dark cursor-pointer hover:scale-105 hover:shadow-md hover:border-primary-light hover:border ease-out duration-300">
            <img src="assets/icons/navbar/polygon.png" class="w-6 h-6 rounded-full" alt="bx-finance">
            <p class="text-white text-sm md:text-lg font-normal text-center">Mumbai TestNet</p>
          </div>
        </div>
        <div class="col-span-12 md:col-span-6">
          <div (click)="changeNetwork(3)"
          class="bg-secondary-black flex items-center gap-3 px-4 py-3 rounded-lg border border-secondary-dark cursor-pointer hover:scale-105 hover:shadow-md hover:border-primary-light hover:border ease-out duration-300">
          <img src="assets/icons/navbar/avalanchee.svg" class="w-6 h-6 rounded-full" alt="bx-finance">
          <p class="text-white text-sm md:text-lg font-normal text-center">Avalanche TestNet</p>
           
          </div>
        </div>
        <div class="col-span-12 md:col-span-6">
          <div (click)="changeNetwork(4)"
            class="bg-secondary-black flex items-center gap-3 px-4 py-3 rounded-lg border border-secondary-dark cursor-pointer hover:scale-105 hover:shadow-md hover:border-primary-light hover:border ease-out duration-300">
            <img src="assets/icons/navbar/fantom.svg" class="w-6 h-6 rounded-full" alt="bx-finance">
            <p class="text-white text-sm md:text-lg font-normal text-center">Fantom Opera TestNet</p>
          </div>
        </div>
        <div class="col-span-12 md:col-span-6">
          <div (click)="changeNetwork(5)"
          class="bg-secondary-black flex items-center gap-3 px-4 py-3 rounded-lg border border-secondary-dark cursor-pointer hover:scale-105 hover:shadow-md hover:border-primary-light hover:border ease-out duration-300">
          <img src="assets/icons/navbar/coronos.svg" class="w-6 h-6 rounded-full" alt="bx-finance">
          <p class="text-white text-sm md:text-lg font-normal text-center">Cronos TestNet</p>
          </div>
        </div>
        
        <!-- <div class="col-span-12 md:col-span-6">
                    <div
                        class="bg-secondary-black flex items-center gap-3 px-4 py-3 rounded-lg border border-secondary-dark cursor-pointer hover:scale-105 hover:shadow-md hover:border-primary-light hover:border ease-out duration-300">
                        <img src="assets/icons/navbar/harmonyone.jpg" class="w-6 h-6 rounded-full" alt="bx-finance">
                        <p class="text-white text-sm md:text-lg font-normal text-center">Harmony</p>
                    </div>
                </div> -->
        <div class="col-span-12 md:col-span-6">
          <div (click)="changeNetwork(6)"
            class="bg-secondary-black flex items-center gap-3 px-4 py-3 rounded-lg border border-secondary-dark cursor-pointer hover:scale-105 hover:shadow-md hover:border-primary-light hover:border ease-out duration-300">
            <img src="assets/icons/navbar/moonbeam.png" class="w-6 h-6 rounded-full" alt="bx-finance">
            <p class="text-white text-sm md:text-lg font-normal text-center">Moonbeam TestNet</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- end: Farm Wallet Connect Modal -->

<!-- BEGIN: Wrong Wallet Connect Modal -->

<!-- end: Wrong Wallet Connect Modal -->
<!-- BEGIN: Waiting for confirmation modal -->
<div class="fixed z-50 inset-0 overflow-y-auto" *ngIf="loader" aria-labelledby="modal-title" role="dialog"
  aria-modal="true">
  <div class="flex items-center md:items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
    <div class="fixed inset-0 bg-[#0f172acc] backdrop-blur-md transition-opacity" aria-hidden="true"></div>

    <!-- This element is to trick the browser into centering the modal contents. -->
    <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
    <div
      class="relative inline-block align-bottom overflow-hidden transform transition-all delay-700 sm:my-8 sm:align-middle sm:max-w-md">

      <div class="w-full flex justify-center my-8">
        <div class="loader animate-spin h-20 w-20"></div>
      </div>

      <div class="text-secondary flex justify-center font-semibold text-lg mt-2 2xl:text-xl text-center">
        Loading details Please wait.....</div>
    </div>
  </div>
</div>
<!-- END: Waiting for confirmation modal -->