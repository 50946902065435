import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavbarComponent } from './navbar/navbar.component';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { WaitingPopupComponent } from './waiting-popup/waiting-popup.component';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from '@danielmoncada/angular-datetime-picker';
import { NavbarcComponent } from './navbarc/navbarc.component';
import { ScrolltopComponent } from './scrolltop/scrolltop.component';
import { TooltipModule } from 'ng2-tooltip-directive';
import { ClickOutsideDirective } from './directives/click-outside.directive';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NotFoundComponent } from './not-found/not-found.component';
import { NavbarNewComponent } from './navbar-new/navbar-new.component';
import { FooterComponent } from './footer/footer.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { TermsOfServiceComponent } from './terms-of-service/terms-of-service.component';
import { InvalidSessionPopupComponent } from './invalid-session-popup/invalid-session-popup.component';
import { NewTokenNavbarComponent } from './new-token-navbar/new-token-navbar.component';

@NgModule({
  declarations: [
    NavbarComponent,
    WaitingPopupComponent,
    NavbarcComponent,
    ScrolltopComponent,
    ClickOutsideDirective,
    NotFoundComponent,
    NavbarNewComponent,
    FooterComponent,
    TermsOfServiceComponent,
    InvalidSessionPopupComponent,
    NewTokenNavbarComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    TooltipModule,
    InfiniteScrollModule,
    CarouselModule
  ],
  exports: [
    NavbarComponent,
    NavbarcComponent,
    WaitingPopupComponent,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    ScrolltopComponent,
    TooltipModule,
    ClickOutsideDirective,
    InfiniteScrollModule,
    NavbarNewComponent,
    FooterComponent,
    CarouselModule,
    NewTokenNavbarComponent
  ]
})
export class SharedModule { }
