<div class="card__main--web w-full">
  <app-navbar-new class="absolute"></app-navbar-new>
    <div class="flex flex-col max-w-8xl inset-x-0 mx-auto relative justify-start items-start flex-1 space-y-8 px-6 pb-6 px-8 pb-8 md:px-16 md:pb-16 pt-32 coming-soon">
        <div class="my-6 relative">
            <!-- <img src="assets/landing-new/icons/star-lg.svg" class="hidden xl:block xl:absolute animate-blink -top-40 left-0 w-16 h-16" alt=""> -->
            <!-- <img src="assets/landing-new/icons/star-lg.svg" class="hidden xl:block xl:absolute animate-blink top-80 right-0" alt=""> -->
            <div class="grid grid-cols-12 gap-4">
                <div class="col-span-12 space-y-6 mb-20">
                    <h2 class="text__yg--gradient text-lg lg:text-2xl mi--font-regular font-normal">Terms of Service </h2>
                    <p class="text-[#D9D9D9] text-lg ep--font-light">Welcome to BX Finance, the complete No-Code Platform form for web3 founders (“Platform,” “Interface,” “Website,” “Dapp,” “App”) provided to you by BlockchainX (“We,” “Our,” “Us”). Our platform provides users with multiple tools and modules related to Web3 and Cryptocurrencies, including but not limited to Ethereum and other chains based on the Ethereum Virtual Machine (EVM). Our website, “<a target="_blank" class="underline font-bold text__yg--gradient" href="https://www.blockchainx.finance">https://www.blockchainx.finance</a>” is one but not the only means to access our services. We might introduce new modules and services on different domains and platforms and users are expected to check the official notification on various official communication channels and/or documentation provided by the BX Finance team. To use our services, you must be a non-custodial wallet user on any of the wallets our platform (s) support and have to be of legal age to use such services or products. BX Finance may use existing solutions and/or proprietary solutions to provide different services to our users. Your relationship with the non-custodial wallet provider or any other service provider other than BX Finance is governed by the terms and conditions of that service provider. We are not liable to any loss of funds, delays, and/or problem resulting from the use of such services/products, including but not limited to, any changes, issues, and disputes pertaining to such services or service providers.
                    </p>
                    <p class="text-[#D9D9D9] text-lg ep--font-light">This document outlines the Terms & Conditions a user has to accept before using our services. By using the BX Finance website and/or our services, you accept that you agree to these terms and conditions. If you do not agree to these terms and conditions, you should not use our services. </p>
                    <ol class="list-decimal lg:pl-12 space-y-4">
                        <li>
                            <h4 class="text-[#D9D9D9] text-base mi--font-regular font-semibold pb-4">1. Changes to the Agreement &nbsp;</h4>
                            <p class="text-[#D9D9D9] text-lg ep--font-light indent-12"> We retain the right to make changes to this Agreement at our sole discretion. Any changes made to the Agreement will reflect in the concerned documentation and be publicly annonouced when deemed fit on our social media channels. Some changes may be sent to your provided email if you have subscribed to our newsletters. However, we cannot guarantee that all policy changes will be directly comunicated to our users. Each user is expected to check our Terms and Conditions, Policies, and other relevant releases prior to using our platform. </p>
                        </li>
                        <li>
                            <h4 class="text-[#D9D9D9] text-base mi--font-regular font-semibold pb-4">2. Eligibility &nbsp;</h4>
                            <p class="text-[#D9D9D9] text-lg ep--font-light indent-12"> To use our services or products, you must be able to form a legally binding agreement with us. All users that use our services or product confirm that they are of legal age to use such services or products in their jurisdiction from where they access our services. Use of Crypto-related products and services are subject to country-wise regulation, and in some cases, state/province-wise regulations. A user is expected to make sure that by using our services or products, they are doing so in compliance with their local laws and regulatuions. BlockchainX Finance will not be liable for any losses, unlawful actions, or any other malicious or unfavorable occurrences resulting from your use of our platform. </p>
                        </li>
                        <li>
                            <h4 class="text-[#D9D9D9] text-base mi--font-regular font-semibold pb-4">3. Third-Parties &nbsp; </h4>
                            <ul class="space-y-4">
                                <li class="text-[#D9D9D9] text-lg ep--font-light indent-12"> <span class="text-base mi--font-regular font-semibold">a) &nbsp; </span>  BlockchainX Finance may use third-party services or may feature other projects as a result of our partnerships, collaborations, or to enable additional/existing features. We will not be held liable for any unfavorable occurrences such as loss of funds, delays, outages, or any other such events. All third-party services providers operate on their own policies and terms of service, and any user making use of these services or products, even through our platform or platforms associated with us will have to make sure that they comply with the policies and terms and conditions of third-party (s). </li>
                                <li class="text-[#D9D9D9] text-lg ep--font-light indent-12"> <span class="text-base mi--font-regular font-semibold">b) &nbsp; </span>  As a result of our partnerships and collaborations, we may feature other project, which may include but not limited to, tokens, dapps, apps, ans services. While BlockchainX Finance does it’s best to ensure that these third-parties are legitimate and trustworthy, we cannot/do not, however, endorse these third-parties or take responsibility for their actions, policies, or any unfavorable occurances resulting from your use of their services or product. You are expected to DO YOUR OWN RESEARCH (DYOR) before using cryptocurrencies, decentralized applications, or crypto related services.  </li>
                            </ul>
                        </li>
                        <li>
                            <h4 class="text-[#D9D9D9] text-base mi--font-regular font-semibold pb-4">4. Tax Liablities & Compliance &nbsp;</h4>
                            <p class="text-[#D9D9D9] text-lg ep--font-light indent-12">  Cryptocurrencies and taxation of cryptocurrencies are subject to different tax laws in different jurisdictions. By using our services or products, you confirm that you are acting in accordance with the taxation laws in your jurisdiction. BlockchainX Finance might comply with audorities for providing information if the information they request warrants such cooperation. We will not, however, provide any legal documentation, legal support, and/or statements to users pertaining to their use of our platform or anything out outside of our platform. </p>
                        </li>
                        <li>
                            <h4 class="text-[#D9D9D9] text-base mi--font-regular font-semibold pb-4">5. No sollicitation and/or Investment Advise &nbsp;</h4>
                            <p class="text-[#D9D9D9] text-lg ep--font-light indent-12">   Every user that uses our services through the website or any other platform related to us, does so at their own will. Although we might feature third-parties on our website and/or on any of our official communication channels, we do not, however, solicit or endorse these third-parties. By using our services or products, you understand and confirm that BlockchainX Finance has not provided you with any investment advise and/or endorsements of any sort, and are doing so at your own risk, which includes but not limited to, investments, token sales, airdrops, trades, and/or any other actions that may or may not contain monetary components.</p>
                        </li>
                        <li>
                            <h4 class="text-[#D9D9D9] text-base mi--font-regular font-semibold pb-4">6. Assumptions of Risk &nbsp;  </h4>
                            <span class="text-[#D9D9D9] text-lg ep--font-light indent-12 mb-4">By using our platform and its services/products, you confirm that:</span>
                            <ul class="space-y-4 mt-4">
                                <li class="text-[#D9D9D9] text-lg ep--font-light indent-12"> <span class="text-base mi--font-regular font-semibold">a) &nbsp; </span>  You are financially and technically sophisticated enough to understand cryptocurrencies, blockchains, decentralized applications, investments, and/or any other related and relevant factors. </li>
                                <li class="text-[#D9D9D9] text-lg ep--font-light indent-12"> <span class="text-base mi--font-regular font-semibold">b) &nbsp; </span> You understand that cryptocurrencies are a relatively new and highly volatile class of assets and that BlockchainX Finance is not involved and/or has no control over how the market conditions pan out. </li>
                                <li class="text-[#D9D9D9] text-lg ep--font-light indent-12"> <span class="text-base mi--font-regular font-semibold">c) &nbsp; </span> Our products and services work on blockchains and their standards, which include but not limited to, token and other standards of Ethereum and/or other platforms based on the Ethereum Virtual Machine (EVM), such as ERC-20, ERC-721, ERC-1155, etc. By using our services and products, you confirm that you possess the technical understanding of these standards and related technology, and that BlockchainX Finance will not be held liable for losses, delays, outages, and/or any other unfavorable occurrences pertaining to these standards, technologies, and/or improper usage of these elements. </li>
                                <li class="text-[#D9D9D9] text-lg ep--font-light indent-12"> <span class="text-base mi--font-regular font-semibold">d) &nbsp; </span> All smart contracts on BX Finance platform are audited. However, audits only test for known vulnerabilities and code in consistencies. By using our services or products, you confirm that you understand the risks associated with smart contracts and that BX Finance will not be liable for any loss of funds pertaining to improper use of the smart contracts and/or the platform, and will not owe the affected user any refunds or compensations for the same. </li>
                                <li class="text-[#D9D9D9] text-lg ep--font-light indent-12"> <span class="text-base mi--font-regular font-semibold">e) &nbsp; </span> If you have any questions regarding the use of our platform (s) and/or smart contracts associated with the platform, you can refer to the corresponding documentation. Always make sure that you understand our platform, how it works, and if it will benefit your particular use case. If you have further questions regarding how our platform/smart contracts work, you can reach out to our team through one of the communication channels provided on the Contact Us page. By using our services and/or our products, you also confirm that you are doing so after conducting your own research, and that any additional service requests, feature requests to support/help your project, and any other requests that require changes to the platform and/or its smart contracts will carry a fee. Any losses incurred and/or unfavorable occurrences resulting from your use of our platform and/or its smart contracts does not us liable for any refunds and/or compensations. </li>
                            </ul>
                        </li>

                      </ol>
                </div>
           </div>

        </div>
    </div>
</div>
<app-footer></app-footer>
