<div class="bg-[#fff]/[.06]">
  <footer class="p-6 md:p-12 lg:py-20 container mx-auto">
    <div class="grid grid-cols-12 gap-4 lg:gap-8 xl:gap-16" data-aos="fade-up" data-aos-delay="300">
      <div class="col-span-12 md:col-span-7 space-y-6">
        <a routerLink="/">
          <img class="w-32 md:w-auto" src="assets/images/logo.svg" alt="bx-finance" />
        </a>
        <p class="text-[#D9D9D9] text-sm lg:text-base ep--font-light break-words text-justify">
          BX Finance is a complete no-code platform that helps you build web3
          projects in minutes. Everything you need from token creation to
          fundraising is right at your fingertips, built by industry
          professionals.
        </p>
      </div>
      <div class="col-span-12 md:col-span-5 flex justify-start md:justify-end lg:justify-evenly">
        <div class="flex justify-end flex-col">
          <ul class="space-y-4 mb-3">
            <li>
              <a href="https://medium.com/@blockchainxfinance" target="_blank"
                class="ep--font-light font-normal text-[#d9d9d9] text-base footer__menu--item">Blog</a>
            </li>
            <li>
              <a routerLink="/privacy-policy"
                class="ep--font-light font-normal text-[#d9d9d9] text-base footer__menu--item">Privacy Policy</a>
            </li>
            <li>
              <a routerLink="/terms-of-service" href="#"
                class="ep--font-light font-normal text-[#d9d9d9] text-base footer__menu--item">Terms and Conditions</a>
            </li>
            <!-- <li>
                                <div class="flex items-center gap-4 mt-4">
                                    <a class="cursor-pointer text-xs md:text-sm xl:text-lg sy--font-medium text-white border-solid border-[#D9D9D9] border-b py-1 lg:py-3 animate-slide" href="https://docs.google.com/forms/d/16t83GnB8e3ZC3IZK-PmQkfjPgsO2btQu2eoPSTFi2E0/viewform?edit_requested=true" target="_blank">Become a partner
                                        <img class="inline  ml-2" src="assets/landing-new/icons/arrow-right.svg" alt="">
                                    </a>
                                </div>
                            </li> -->
          </ul>
          <div class="flex mt-4 pt-3 space-x-6 justify-start">
            <a href="https://t.me/+tdk1G16Fs8RhZGJl" target="_blank"><img
                src="assets/landing-new/icons/social-media/telegram.svg" class="w-7 h-7" alt="" /></a>
            <a href="https://discord.gg/5BBkdEQrVT" target="_blank"><img
                src="assets/landing-new/icons/social-media/discord.svg" class="w-7 h-7" alt="" /></a>
            <a href="https://twitter.com/bxfinance" target="_blank"><img
                src="assets/landing-new/icons/social-media/twitter.svg" class="w-7 h-7" alt="" /></a>
            <a href="https://www.youtube.com/@bxfinanceweb/about" target="_blank"><img
                src="assets/landing-new/icons/social-media/youtube.svg" class="w-7 h-7" alt="" /></a>
            <a href="https://medium.com/@blockchainxfinance" target="_blank"><img
                src="assets/landing-new/icons/social-media/medium.svg" class="w-7 h-7" alt="" /></a>
          </div>
        </div>

        <!-- <div class="grid grid-cols-12 justify-between gap-4 lg:gap-8">
                   <div class="col-span-12 md:col-span-6">
                        <ul class="space-y-4">
                            <li>
                                <a href="#" class="ep--font-light font-normal text-[#d9d9d9] text-base footer__menu--item">Cryptocurrency Development</a>
                            </li>
                            <li>
                                <a href="#" class="ep--font-light font-normal text-[#d9d9d9] text-base footer__menu--item">ICO Coin Development</a>
                            </li>
                            <li>
                                <a href="#" class="ep--font-light font-normal text-[#d9d9d9] text-base footer__menu--item">STO Coin Development</a>
                            </li>
                            <li>
                                <a href="#" class="ep--font-light font-normal text-[#d9d9d9] text-base footer__menu--item">ICO/STO Marketing</a>
                            </li>
                            <li>
                                <a href="#" class="ep--font-light font-normal text-[#d9d9d9] text-base footer__menu--item">ERC20 Token Development</a>
                            </li>
                        </ul>
                    </div>
                    <div class="col-span-12 md:col-span-6">

                    </div>
                </div>-->
      </div>
    </div>
  </footer>
</div>
<div class="border-[#D9D9D9]/50 border-b-[.75px] border-solid mb-3">
  <div class="mx-auto px-4 py-6 md:py-6 md:px-12 container mx-auto md:flex md:items-center md:justify-between">
    <span class="text-sm text-[#D9D9D9] ep--font-medium">© 2023 <a class="ep--font-light" href="#">BX Finance</a>. All
      Rights
      Reserved.
    </span>
    <div>
      <app-scrolltop></app-scrolltop>
      <!-- <a href="#" class="text-lg text-[#D9D9D9] ep--font-light">Back to top
                    <img class="inline  ml-2" src="assets/landing-new/icons/arrow-right-up.svg" alt="">
                </a> -->
    </div>
  </div>
</div>
