export const data: any = {
    DEFAULT_META_DATA_PROD: {
        "app_title": 'BX Finance | A Complete No-code Web3 Ecosystem',
        "meta_title": 'A complete No-Code web3 ecosystem for founders - Build projects with prebuilt tools | BX Finance',
        "meta_description": 'BX Finance is the last No-Code web3 development platform you’ll ever need if you’re a web3 founder. Create your very first token in minutes, fundraise capital, drop nfts, and much more. Build a web3 project in literally minutes.',
        "meta_keywords": 'Low code no code, No code web3 platform, No code web3 eco systems, No code web3',
        "meta_author": 'default meta_author',
        "canonical_url": 'https://www.blockchainx.finance',
        "og_locale": 'default og:locale',
        "og_type": 'website',
        "og_title": 'BX Finance',
        "og_description": 'Default sample meta description Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
        "og_url": 'default og:url',
        "og_site_name": 'default og:site_name',
        "og_image": 'https://blockchainx.finance/assets/images/og/landing-og.png',
        "og_image_width": 'default og:image:width',
        "og_image_height": 'default og:image:height',
        "og_image_alt": 'default og:image:alt',
        "og_image_type": 'default og:image:type',
        "twitter_card": 'summary_large_image',
        "twitter_url": 'https://blockchainx.finance',
        "twitter_domain": 'blockchainx.finance',
        "twitter_site": 'default twitter site',
        "twitter_title": 'A complete No-Code web3 ecosystem for founders - Build projects with prebuilt tools | BX Finance',
        "twitter_description": 'BX Finance is the last No-Code web3 development platform you’ll ever need if you’re a web3 founder. Create your very first token in minutes, fundraise capital, drop nfts, and much more. Build a web3 project in literally minutes.',
        "twitter_image": 'https://blockchainx.finance/assets/images/og/landing-og.png',
    },

    META_DATA_PROD: {
        "/": {
            "app_title": 'BX Finance | A Complete No-code Web3 Ecosystem',
            "meta_title": 'A complete No-Code web3 ecosystem for founders - Build projects with prebuilt tools | BX Finance',
            "meta_description": 'BX Finance is the last No-Code web3 development platform you’ll ever need if you’re a web3 founder. Create your very first token in minutes, fundraise capital, drop nfts, and much more. Build a web3 project in literally minutes.',
            "meta_keywords": 'Low code no code, No code web3 platform, No code web3 eco systems, No code web3',
            "meta_author": 'sample meta_author',
            "canonical_url": 'https://www.blockchainx.finance',
            "og_locale": 'sample og:locale',
            "og_type": 'website',
            "og_title": 'A complete No-Code web3 ecosystem for founders - Build projects with prebuilt tools | BX Finance',
            "og_description": 'BX Finance is the last No-Code web3 development platform you’ll ever need if you’re a web3 founder. Create your very first token in minutes, fundraise capital, drop nfts, and much more. Build a web3 project in literally minutes.',
            "og_url": 'sample og:url',
            "og_site_name": 'sample og:site_name',
            "og_image": 'https://blockchainx.finance/assets/images/og/landing-og.png',
            "og_image_width": 'sample og:image:width',
            "og_image_height": 'sample og:image:height',
            "og_image_alt": 'sample og:image:alt',
            "og_image_type": 'sample og:image:type',
            "twitter_card": 'summary_large_image',
            "twitter_url": 'https://blockchainx.finance',
            "twitter_domain": 'blockchainx.finance',
            "twitter_site": 'sample twitter site',
            "twitter_title": 'A complete No-Code web3 ecosystem for founders - Build projects with prebuilt tools | BX Finance',
            "twitter_description": 'BX Finance is the last No-Code web3 development platform you’ll ever need if you’re a web3 founder. Create your very first token in minutes, fundraise capital, drop nfts, and much more. Build a web3 project in literally minutes.',
            "twitter_image": 'https://blockchainx.finance/assets/images/og/landing-og.png',
        },
        "/about": {
            "app_title": 'about page app_title',
            "meta_title": 'sample meta_title',
            "meta_description": 'About sample meta description Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
            "meta_keywords": 'sample meta_keywords',
            "meta_author": 'sample meta_author',
            "canonical_url": 'https://www.blockchainx.finance',
            "og_locale": 'sample og:locale',
            "og_type": 'website',
            "og_title": 'sample og:title',
            "og_description": 'About sample meta description Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
            "og_url": 'sample og:url',
            "og_site_name": 'sample og:site_name',
            "og_image": 'https://st2.depositphotos.com/3591429/10566/i/600/depositphotos_105666254-stock-photo-business-people-at-meeting-and.jpg',
            "og_image_width": 'sample og:image:width',
            "og_image_height": 'sample og:image:height',
            "og_image_alt": 'sample og:image:alt',
            "og_image_type": 'sample og:image:type',
            "twitter_card": 'summary_large_image',
            "twitter_url": 'https://blockchainx.finance',
            "twitter_domain": 'blockchainx.finance',
            "twitter_site": 'sample twitter site',
            "twitter_title": 'About page application title',
            "twitter_description": 'sample twitter description',
            "twitter_image": 'https://st2.depositphotos.com/3591429/10566/i/600/depositphotos_105666254-stock-photo-business-people-at-meeting-and.jpg',
        },
        "/token-generator": {
            "app_title": 'Create Custom Token | Token Generator - BX Finance',
            "meta_title": 'Create your first token token in minutes - No-Code Multi-feature Token Generator | BX Finance',
            "meta_description": 'Our multi-feature token generator allows users to easily create erc20 tokens across multiple chains. Choose everything about your tokens, including advanced features such as tax and buybacks.',
            "meta_keywords": 'create your own crypto token, create a token, create crypto token, create your own token, token generator crypto, create crypto token bep20, create crypto token bsc, crypto token generator, create own token, create token on binance smart chain, create your own erc20 token, crypto token generator, crypto token creation, create a token on bsc',
            "meta_author": 'sample meta_author',
            "canonical_url": 'https://www.blockchainx.finance/token-generator',
            "og_locale": 'sample og:locale',
            "og_type": 'website',
            "og_title": 'Create your first token token in minutes - No-Code Multi-feature Token Generator | BX Finance',
            "og_description": 'Our multi-feature token generator allows users to easily create erc20 tokens across multiple chains. Choose everything about your tokens, including advanced features such as tax and buybacks.',
            "og_url": 'sample og:url',
            "og_site_name": 'sample og:site_name',
            "og_image": 'https://blockchainx.finance/assets/images/og/token-generator-og.png',
            "og_image_width": 'sample og:image:width',
            "og_image_height": 'sample og:image:height',
            "og_image_alt": 'sample og:image:alt',
            "og_image_type": 'sample og:image:type',
            "twitter_card": 'summary_large_image',
            "twitter_url": 'https://blockchainx.finance/token-generator',
            "twitter_domain": 'blockchainx.finance',
            "twitter_site": 'sample twitter site',
            "twitter_title": 'Create your first token token in minutes - No-Code Multi-feature Token Generator | BX Finance',
            "twitter_description": 'Our multi-feature token generator allows users to easily create erc20 tokens across multiple chains. Choose everything about your tokens, including advanced features such as tax and buybacks.',
            "twitter_image": 'https://blockchainx.finance/assets/images/og/token-generator-og.png',
        },
        "/new-token-generator": {
            "app_title": 'Create Custom Token | Token Generator - BX Finance',
            "meta_title": 'Create your first token token in minutes - No-Code Multi-feature Token Generator | BX Finance',
            "meta_description": 'Our multi-feature token generator allows users to easily create erc20 tokens across multiple chains. Choose everything about your tokens, including advanced features such as tax and buybacks.',
            "meta_keywords": 'create your own crypto token, create a token, create crypto token, create your own token, token generator crypto, create crypto token bep20, create crypto token bsc, crypto token generator, create own token, create token on binance smart chain, create your own erc20 token, crypto token generator, crypto token creation, create a token on bsc',
            "meta_author": 'sample meta_author',
            "canonical_url": 'https://www.blockchainx.finance/token-generator',
            "og_locale": 'sample og:locale',
            "og_type": 'website',
            "og_title": 'Create your first token token in minutes - No-Code Multi-feature Token Generator | BX Finance',
            "og_description": 'Our multi-feature token generator allows users to easily create erc20 tokens across multiple chains. Choose everything about your tokens, including advanced features such as tax and buybacks.',
            "og_url": 'sample og:url',
            "og_site_name": 'sample og:site_name',
            "og_image": 'https://blockchainx.finance/assets/images/og/token-generator-og.png',
            "og_image_width": 'sample og:image:width',
            "og_image_height": 'sample og:image:height',
            "og_image_alt": 'sample og:image:alt',
            "og_image_type": 'sample og:image:type',
            "twitter_card": 'summary_large_image',
            "twitter_url": 'https://blockchainx.finance/token-generator',
            "twitter_domain": 'blockchainx.finance',
            "twitter_site": 'sample twitter site',
            "twitter_title": 'Create your first token token in minutes - No-Code Multi-feature Token Generator | BX Finance',
            "twitter_description": 'Our multi-feature token generator allows users to easily create erc20 tokens across multiple chains. Choose everything about your tokens, including advanced features such as tax and buybacks.',
            "twitter_image": 'https://blockchainx.finance/assets/images/og/token-generator-og.png',
        },
        "/migration": {
            "app_title": 'Create your own Migrator platform | Swap Old to New Token - BX Finance',
            "meta_title": 'Upgrade your project tokens without code - Switch from V1 tokens to V2 tokens | BX Finance',
            "meta_description": 'Launch your own token migration event with our No-Code migration platform. Allow users to switch from your V1 tokens to V2 tokens, hassle free with the power of No-Code.',
            "meta_keywords": 'token migration, token migration to v2, token migration binance, migrate tokens to new contract',
            "meta_author": 'sample meta_author',
            "canonical_url": 'https://www.blockchainx.finance/migration',
            "og_locale": 'sample og:locale',
            "og_type": 'website',
            "og_title": 'Upgrade your project tokens without code - Switch from V1 tokens to V2 tokens | BX Finance',
            "og_description": 'Launch your own token migration event with our No-Code migration platform. Allow users to switch from your V1 tokens to V2 tokens, hassle free with the power of No-Code.',
            "og_url": 'sample og:url',
            "og_site_name": 'sample og:site_name',
            "og_image": 'https://blockchainx.finance/assets/images/og/migration-og.png',
            "og_image_width": 'sample og:image:width',
            "og_image_height": 'sample og:image:height',
            "og_image_alt": 'sample og:image:alt',
            "og_image_type": 'sample og:image:type',
            "twitter_card": 'summary_large_image',
            "twitter_url": 'https://blockchainx.finance/migration',
            "twitter_domain": 'blockchainx.finance',
            "twitter_site": 'sample twitter site',
            "twitter_title": 'Upgrade your project tokens without code - Switch from V1 tokens to V2 tokens | BX Finance',
            "twitter_description": 'Launch your own token migration event with our No-Code migration platform. Allow users to switch from your V1 tokens to V2 tokens, hassle free with the power of No-Code.',
            "twitter_image": 'https://blockchainx.finance/assets/images/og/migration-og.png',
        },
        "/airdrop": {
            "app_title": 'Airdrop Your Tokens | Token Bulk Transfer to Multiple Addresses - BX Finance',
            "meta_title": 'No-Code Token Airdrop Platform  - Bulk send token to multiple addresses | BX Finance',
            "meta_description": 'Run airdrop campaigns with our token airdrop platform. Send tokens to multiple wallet addresses with BX Finance airdrops.',
            "meta_keywords": 'Token Airdrop, Token airdrop platform, Airdrop coin',
            "meta_author": 'sample meta_author',
            "canonical_url": 'https://www.blockchainx.finance/airdrop',
            "og_locale": 'sample og:locale',
            "og_type": 'website',
            "og_title": 'No-Code Token Airdrop Platform  - Bulk send token to multiple addresses | BX Finance',
            "og_description": 'Run airdrops and bounties easily with our token airdrop platform. Send tokens to multiple addresses in under a minute.',
            "og_url": 'sample og:url',
            "og_site_name": 'sample og:site_name',
            "og_image": 'https://blockchainx.finance/assets/images/og/airdrop-og.png',
            "og_image_width": 'sample og:image:width',
            "og_image_height": 'sample og:image:height',
            "og_image_alt": 'sample og:image:alt',
            "og_image_type": 'sample og:image:type',
            "twitter_card": 'summary_large_image',
            "twitter_url": 'https://blockchainx.finance/airdrop',
            "twitter_domain": 'blockchainx.finance',
            "twitter_site": 'sample twitter site',
            "twitter_title": 'No-Code Token Airdrop Platform  - Bulk send token to multiple addresses | BX Finance',
            "twitter_description": 'Run airdrop campaigns with our token airdrop platform. Send tokens to multiple wallet addresses with BX Finance airdrops.',
            "twitter_image": 'https://blockchainx.finance/assets/images/og/airdrop-og.png',
        }
    },
    DEFAULT_META_DATA_DEV: {
        "app_title": 'BX Finance | A Complete No-code Web3 Ecosystem',
        "meta_title": 'A complete No-Code web3 ecosystem for founders - Build projects with prebuilt tools | BX Finance',
        "meta_description": 'BX Finance is the last No-Code web3 development platform you’ll ever need if you’re a web3 founder. Create your very first token in minutes, fundraise capital, drop nfts, and much more. Build a web3 project in literally minutes.',
        "meta_keywords": 'Low code no code, No code web3 platform, No code web3 eco systems, No code web3',
        "meta_author": 'default meta_author',
        "canonical_url": 'https://devuser.blockchainx.finance',
        "og_locale": 'default og:locale',
        "og_type": 'website',
        "og_title": 'BX Finance',
        "og_description": 'Default sample meta description Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
        "og_url": 'default og:url',
        "og_site_name": 'default og:site_name',
        "og_image": 'https://devuser.blockchainx.finance/assets/images/og/landing-og.png',
        "og_image_width": 'default og:image:width',
        "og_image_height": 'default og:image:height',
        "og_image_alt": 'default og:image:alt',
        "og_image_type": 'default og:image:type',
        "twitter_card": 'summary_large_image',
        "twitter_url": 'https://devuser.blockchainx.finance',
        "twitter_domain": 'devuser.blockchainx.finance',
        "twitter_site": 'default twitter site',
        "twitter_title": 'A complete No-Code web3 ecosystem for founders - Build projects with prebuilt tools | BX Finance',
        "twitter_description": 'BX Finance is the last No-Code web3 development platform you’ll ever need if you’re a web3 founder. Create your very first token in minutes, fundraise capital, drop nfts, and much more. Build a web3 project in literally minutes.',
        "twitter_image": 'https://devuser.blockchainx.finance/assets/images/og/landing-og.png',
    },


    META_DATA_DEV: {
        "/": {
            "app_title": 'BX Finance | A Complete No-code Web3 Ecosystem',
            "meta_title": 'A complete No-Code web3 ecosystem for founders - Build projects with prebuilt tools | BX Finance',
            "meta_description": 'BX Finance is the last No-Code web3 development platform you’ll ever need if you’re a web3 founder. Create your very first token in minutes, fundraise capital, drop nfts, and much more. Build a web3 project in literally minutes.',
            "meta_keywords": 'Low code no code, No code web3 platform, No code web3 eco systems, No code web3',
            "meta_author": 'sample meta_author',
            "canonical_url": 'https://devuser.blockchainx.finance',
            "og_locale": 'sample og:locale',
            "og_type": 'website',
            "og_title": 'A complete No-Code web3 ecosystem for founders - Build projects with prebuilt tools | BX Finance',
            "og_description": 'BX Finance is the last No-Code web3 development platform you’ll ever need if you’re a web3 founder. Create your very first token in minutes, fundraise capital, drop nfts, and much more. Build a web3 project in literally minutes.',
            "og_url": 'sample og:url',
            "og_site_name": 'sample og:site_name',
            "og_image": 'https://devuser.blockchainx.finance/assets/images/og/landing-og.png',
            "og_image_width": 'sample og:image:width',
            "og_image_height": 'sample og:image:height',
            "og_image_alt": 'sample og:image:alt',
            "og_image_type": 'sample og:image:type',
            "twitter_card": 'summary_large_image',
            "twitter_url": 'https://devuser.blockchainx.finance',
            "twitter_domain": 'devuser.blockchainx.finance',
            "twitter_site": 'sample twitter site',
            "twitter_title": 'A complete No-Code web3 ecosystem for founders - Build projects with prebuilt tools | BX Finance',
            "twitter_description": 'BX Finance is the last No-Code web3 development platform you’ll ever need if you’re a web3 founder. Create your very first token in minutes, fundraise capital, drop nfts, and much more. Build a web3 project in literally minutes.',
            "twitter_image": 'https://devuser.blockchainx.finance/assets/images/og/landing-og.png',
        },
        "/about": {
            "app_title": 'about page app_title',
            "meta_title": 'sample meta_title',
            "meta_description": 'About sample meta description Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
            "meta_keywords": 'sample meta_keywords',
            "meta_author": 'sample meta_author',
            "canonical_url": 'https://devuser.blockchainx.finance',
            "og_locale": 'sample og:locale',
            "og_type": 'website',
            "og_title": 'sample og:title',
            "og_description": 'About sample meta description Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
            "og_url": 'sample og:url',
            "og_site_name": 'sample og:site_name',
            "og_image": 'https://st2.depositphotos.com/3591429/10566/i/600/depositphotos_105666254-stock-photo-business-people-at-meeting-and.jpg',
            "og_image_width": 'sample og:image:width',
            "og_image_height": 'sample og:image:height',
            "og_image_alt": 'sample og:image:alt',
            "og_image_type": 'sample og:image:type',
            "twitter_card": 'summary_large_image',
            "twitter_url": 'https://devuser.blockchainx.finance',
            "twitter_domain": 'devuser.blockchainx.finance',
            "twitter_site": 'sample twitter site',
            "twitter_title": 'About page application title',
            "twitter_description": 'sample twitter description',
            "twitter_image": 'https://st2.depositphotos.com/3591429/10566/i/600/depositphotos_105666254-stock-photo-business-people-at-meeting-and.jpg',
        },
        "/token-generator": {
            "app_title": 'Create Custom Token | Token Generator - BX Finance',
            "meta_title": 'Create your first token token in minutes - No-Code Multi-feature Token Generator | BX Finance',
            "meta_description": 'Our multi-feature token generator allows users to easily create erc20 tokens across multiple chains. Choose everything about your tokens, including advanced features such as tax and buybacks.',
            "meta_keywords": 'create your own crypto token, create a token, create crypto token, create your own token, token generator crypto, create crypto token bep20, create crypto token bsc, crypto token generator, create own token, create token on binance smart chain, create your own erc20 token, crypto token generator, crypto token creation, create a token on bsc',
            "meta_author": 'sample meta_author',
            "canonical_url": 'https://devuser.blockchainx.finance/token-generator',
            "og_locale": 'sample og:locale',
            "og_type": 'website',
            "og_title": 'Create your first token token in minutes - No-Code Multi-feature Token Generator | BX Finance',
            "og_description": 'Our multi-feature token generator allows users to easily create erc20 tokens across multiple chains. Choose everything about your tokens, including advanced features such as tax and buybacks.',
            "og_url": 'sample og:url',
            "og_site_name": 'sample og:site_name',
            "og_image": 'https://devuser.blockchainx.finance/assets/images/og/token-generator-og.png',
            "og_image_width": 'sample og:image:width',
            "og_image_height": 'sample og:image:height',
            "og_image_alt": 'sample og:image:alt',
            "og:image_type": 'sample og:image:type',
            "twitter_card": 'summary_large_image',
            "twitter_url": 'https://devuser.blockchainx.finance/token-generator',
            "twitter_domain": 'devuser.blockchainx.finance',
            "twitter_site": 'sample twitter site',
            "twitter_title": 'Create your first token token in minutes - No-Code Multi-feature Token Generator | BX Finance',
            "twitter_description": 'Our multi-feature token generator allows users to easily create erc20 tokens across multiple chains. Choose everything about your tokens, including advanced features such as tax and buybacks.',
            "twitter_image": 'https://devuser.blockchainx.finance/assets/images/og/token-generator-og.png',
        },
        "/new-token-generator": {
            "app_title": 'Create Custom Token | Token Generator - BX Finance',
            "meta_title": 'Create your first token token in minutes - No-Code Multi-feature Token Generator | BX Finance',
            "meta_description": 'Our multi-feature token generator allows users to easily create erc20 tokens across multiple chains. Choose everything about your tokens, including advanced features such as tax and buybacks.',
            "meta_keywords": 'create your own crypto token, create a token, create crypto token, create your own token, token generator crypto, create crypto token bep20, create crypto token bsc, crypto token generator, create own token, create token on binance smart chain, create your own erc20 token, crypto token generator, crypto token creation, create a token on bsc',
            "meta_author": 'sample meta_author',
            "canonical_url": 'https://devuser.blockchainx.finance/token-generator',
            "og_locale": 'sample og:locale',
            "og_type": 'website',
            "og_title": 'Create your first token token in minutes - No-Code Multi-feature Token Generator | BX Finance',
            "og_description": 'Our multi-feature token generator allows users to easily create erc20 tokens across multiple chains. Choose everything about your tokens, including advanced features such as tax and buybacks.',
            "og_url": 'sample og:url',
            "og_site_name": 'sample og:site_name',
            "og_image": 'https://devuser.blockchainx.finance/assets/images/og/token-generator-og.png',
            "og_image_width": 'sample og:image:width',
            "og_image_height": 'sample og:image:height',
            "og_image_alt": 'sample og:image:alt',
            "og:image_type": 'sample og:image:type',
            "twitter_card": 'summary_large_image',
            "twitter_url": 'https://devuser.blockchainx.finance/token-generator',
            "twitter_domain": 'devuser.blockchainx.finance',
            "twitter_site": 'sample twitter site',
            "twitter_title": 'Create your first token token in minutes - No-Code Multi-feature Token Generator | BX Finance',
            "twitter_description": 'Our multi-feature token generator allows users to easily create erc20 tokens across multiple chains. Choose everything about your tokens, including advanced features such as tax and buybacks.',
            "twitter_image": 'https://devuser.blockchainx.finance/assets/images/og/token-generator-og.png',
        },
        "/migration": {
            "app_title": 'Create your own Migrator platform | Swap Old to New Token - BX Finance',
            "meta_title": 'Upgrade your project tokens without code - Switch from V1 tokens to V2 tokens | BX Finance',
            "meta_description": 'Launch your own token migration event with our No-Code migration platform. Allow users to switch from your V1 tokens to V2 tokens, hassle free with the power of No-Code.',
            "meta_keywords": 'token migration, token migration to v2, token migration binance, migrate tokens to new contract',
            "meta_author": 'sample meta_author',
            "canonical_url": 'https://devuser.blockchainx.finance/migration',
            "og_locale": 'sample og:locale',
            "og_type": 'website',
            "og_title": 'Upgrade your project tokens without code - Switch from V1 tokens to V2 tokens | BX Finance',
            "og_description": 'Launch your own token migration event with our No-Code migration platform. Allow users to switch from your V1 tokens to V2 tokens, hassle free with the power of No-Code.',
            "og_url": 'sample og:url',
            "og_site_name": 'sample og:site_name',
            "og_image": 'https://devuser.blockchainx.finance/assets/images/og/migration-og.png',
            "og_image_width": 'sample og:image:width',
            "og_image_height": 'sample og:image:height',
            "og_image_alt": 'sample og:image:alt',
            "og_image_type": 'sample og:image:type',
            "twitter_card": 'summary_large_image',
            "twitter_url": 'https://devuser.blockchainx.finance/migration',
            "twitter_domain": 'devuser.blockchainx.finance',
            "twitter_site": 'sample twitter site',
            "twitter_title": 'Upgrade your project tokens without code - Switch from V1 tokens to V2 tokens | BX Finance',
            "twitter_description": 'Launch your own token migration event with our No-Code migration platform. Allow users to switch from your V1 tokens to V2 tokens, hassle free with the power of No-Code.',
            "twitter_image": 'https://devuser.blockchainx.finance/assets/images/og/migration-og.png',
        },
        "/airdrop": {
            "app_title": 'Airdrop Your Tokens | Token Bulk Transfer to Multiple Addresses - BX Finance',
            "meta_title": 'No-Code Token Airdrop Platform  - Bulk send token to multiple addresses | BX Finance',
            "meta_description": 'Run airdrop campaigns with our token airdrop platform. Send tokens to multiple wallet addresses with BX Finance airdrops.',
            "meta_keywords": 'Token Airdrop, Token airdrop platform, Airdrop coin',
            "meta_author": 'sample meta_author',
            "canonical_url": 'https://devuser.blockchainx.finance/airdrop',
            "og_locale": 'sample og:locale',
            "og_type": 'website',
            "og_title": 'No-Code Token Airdrop Platform  - Bulk send token to multiple addresses | BX Finance',
            "og_description": 'Run airdrops and bounties easily with our token airdrop platform. Send tokens to multiple addresses in under a minute.',
            "og_url": 'sample og:url',
            "og_site_name": 'sample og:site_name',
            "og_image": 'https://devuser.blockchainx.finance/assets/images/og/airdrop-og.png',
            "og_image_width": 'sample og:image:width',
            "og_image_height": 'sample og:image:height',
            "og_image_alt": 'sample og:image:alt',
            "og_image_type": 'sample og:image:type',
            "twitter_card": 'summary_large_image',
            "twitter_url": 'https://devuser.blockchainx.finance/airdrop',
            "twitter_domain": 'devuser.blockchainx.finance',
            "twitter_site": 'sample twitter site',
            "twitter_title": 'No-Code Token Airdrop Platform  - Bulk send token to multiple addresses | BX Finance',
            "twitter_description": 'Run airdrop campaigns with our token airdrop platform. Send tokens to multiple wallet addresses with BX Finance airdrops.',
            "twitter_image": 'https://devuser.blockchainx.finance/assets/images/og/airdrop-og.png',
        }
    }
}

