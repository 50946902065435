<div class="bg__dots h-screen card__main--web w-full">
    <nav class="fixed w-full top-0 left-0 z-50" [ngClass]="{'bg-secondary-black': isScrolled}">
        <div class="max-w-8xl mx-auto px-2 py-2 sm:px-6 lg:px-8 2xl:px-0">
            <div class="relative flex items-center justify-between h-16 lg:h-20 py-8">
                <div class="absolute inset-y-0 left-0 flex items-center sm:hidden">
      
                </div>
                <div class="flex-1 flex items-center justify-start">
                    <div class="flex-shrink-0 flex md:items-center w-40">
                      <a routerLink="/"><img class="block lg:hidden h-8 w-auto m-logo" src="assets/images/logo-sm.png" alt="bx-finance"></a>
                      <a routerLink="/"><img class="lg:block hidden w-full h-full" src="assets/images/logo.svg" alt="bx-finance"></a>
                    </div>      
                </div>
                <div class="absolute space-x-6 inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">                   
                    <div class="ml-0">
                    <button (click)="contactUs()" class="bg-[#4E1FA3]/30 border-[#4E1FA3] border border-solid flex justify-center items-center hover:shadow-btn transition ease-in-out duration-700 text-white sy--font-medium p-2 px-4 md:py-2 h-11 md:px-8 rounded-lg focus:outline-none text-xs md:text-sm xl:text-lg">
                        Contact Us</button>
                        <!-- <button *ngIf="!isMetaMaskConnected" type="button" (click)="connectMetaMask()" class="bg-primary text-white font-normal text-sm p-2 px-5 rounded-full focus:outline-none">Connect Wallet </button> -->
                         <!-- <button *ngIf="isMetaMaskConnected && !showWrongNetworkPopup" type="button" class="bg-primary text-white font-normal text-sm p-2 px-5 2xl:px-8 rounded-full focus:outline-none w-32 md:w-40 truncate"> {{ walletAddress }} </button> -->
                        <!-- <button *ngIf="isMetaMaskConnected && showWrongNetworkPopup" type="button" class="bg-primary text-white font-normal text-sm p-2 px-5 rounded-full focus:outline-none"> Wrong network </button> -->
                    </div>      
                </div>
            </div>
        </div>
      </nav>
    <div class="flex flex-col max-w-8xl inset-x-0 mx-auto absolute top-0 justify-center items-center z-10 flex-1 space-y-8 min-h-screen coming-soon">
        <div class="my-6 relative">
            <img src="assets/landing-new/icons/star-lg.svg" class="hidden xl:block xl:absolute animate-blink top-40 left-0 w-16 h-16" alt="">
            <img src="assets/landing-new/icons/star-lg.svg" class="hidden xl:block xl:absolute animate-blink top-80 right-0" alt="">
            <div class="banner__content w-full lg:w-7/12 mx-auto md:mt-24 pt-20 lg:my-0 lg:py-20">
                <h2 class="text-3xl xl:text-5xl text-center pb-4 mi--font-regular text-white leading-snug lg:!leading-[1.16]"> <span class="text__yg--gradient">404</span>
                 
                <img src="assets/landing-new/icons/star-md.svg" class="animate-blink inline pl-2" alt=""></h2>
                <p class="font-medium text-secondary text-base lg:text-lg 2xl:text-2xl text-center py-2 ">Oops! Why are you're here? 
                </p>
                <p class="font-medium text-secondary text-base lg:text-lg 2xl:text-2xl text-center py-4 2xl:py-4">We are very sorry for inconvinence. It looks like you're try to access a page that either has been deleted or never existed.</p>
                
                <div class="flex justify-center items-center gap-4 my-6">
                    <a routerLink="/"  class="bg-[#4E1FA3]/30 cursor-pointer border-[#4E1FA3] border border-solid flex justify-center items-center hover:shadow-btn transition ease-in-out duration-700 text-white sy--font-medium p-2 px-4 md:py-2 h-11 md:px-8 rounded-lg focus:outline-none text-xs md:text-sm xl:text-lg" type="submit">
                        Back to Home
                    </a>
                </div>

                <!-- <p class="font-medium text-secondary text-sm lg:text-base 2xl:text-lg text-center">Are you part of the community yet?
                </p> -->
                <!-- <div class="py-6 px-4 bg-gray-100 dark:bg-gray-700 md:flex md:items-center md:justify-between"> -->
                   
                    <div class="flex mt-4 space-x-6 sm:justify-center md:mt-8">
                        <a href="https://twitter.com/bxfinance" target="_blank">
                            <img src="assets/icons/telegram.png" class="w-8 h-8 object-contain" alt="">
                        </a>
                        <a href="https://twitter.com/bxfinance" target="_blank">
                            <img src="assets/icons/twitter.png"  class="w-8 h-8 object-contain" alt="">
                        </a>
                        <a href="https://twitter.com/bxfinance" target="_blank">
                            <img src="assets/icons/discord.png"  class="w-8 h-8 object-contain" alt="">
                        </a>

                        <!-- <a href="https://twitter.com/bxfinance" target="_blank" class="text-secondary hover:text-primary-light">
                            <svg class="w-8 h-8" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true"><path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" /></svg>
                            <span class="sr-only">Twitter page</span>
                        </a> -->
                      
                    </div>
               
            <!-- </div> -->
        </div>
    </div>
</div>