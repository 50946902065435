import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ImageValidationService {
  constructor() { }

  /**
   * Validates file
   * @param name
   * @returns
   */
  public validateFile(name: String) : Boolean{
    var ext = name.substring(name.lastIndexOf('.') + 1);
    if (ext.toLowerCase() == 'png' || ext.toLowerCase() == 'jpeg' || ext.toLowerCase() == 'jpg' || ext.toLowerCase() == 'webp' || ext.toLowerCase() == 'gif' || ext.toLowerCase() == 'svg') {
      return true;
    }else if (ext.toLowerCase() == 'mp3' || ext.toLowerCase() == 'wav' || ext.toLowerCase() == 'ogg') {
      return true;
    } else if (ext.toLowerCase() == 'glb' || ext.toLowerCase() == 'gltf') {
      return true;
    }else if (ext.toLowerCase() == 'webm' || ext.toLowerCase() == 'mp4') {
      return true;
    }
    else {
      return false;
    }
  }

  public validatePreviewFile(name: String){
    var ext = name.substring(name.lastIndexOf('.') + 1);
    if (ext.toLowerCase() == 'png' || ext.toLowerCase() == 'jpeg' || ext.toLowerCase() == 'jpg' || ext.toLowerCase() == 'webp' || ext.toLowerCase() == 'gif' || ext.toLowerCase() == 'svg') {
      return true;
    }
    else {
      return false;
    }
  }

  public getExtension(name : string){
    var ext = name.substring(name.lastIndexOf('.') + 1);
    return ext.toLowerCase();
  }

  /**
   * Validates zip file
   * @param files
   * @returns
   */
  public validateZipFile(files: string): boolean {
    var ext = files.substring(files.lastIndexOf('.') + 1);
    if (ext.toLowerCase() == 'zip') {
      return true;
    } else {
      return false;
    }
  }

  /**
   * Validates excel sheet
   * @param sheet
   * @returns true if excel sheet
   */
  public validateExcelSheet(sheet: string): boolean {
    var ext = sheet.substring(sheet.lastIndexOf('.') + 1);
    if (ext.toLowerCase() == 'xlsx') {
      return true;
    } else {
      return false;
    }
  }

  /**
   * Determines whether empty object is
   * @param obj
   * @returns
   */
  public isEmptyObject(obj: any) {
    return JSON.stringify(obj) === '{}'
  }
}
