import { AbstractControl, ValidatorFn } from '@angular/forms';

export function emailDomainValidator(allowedDomains: string[]): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const email: string = control.value;
    const domain = email.substring(email.lastIndexOf('@') + 1);

    if (email === '') {
      return null;
    }

    const isAllowed = allowedDomains.some((allowedDomain) => {
      return domain.toLowerCase() === allowedDomain.toLowerCase();
    });

    if (isAllowed) {
      return { 'emailDomain': true };
    } else {
      return null;
    }
  };
}
