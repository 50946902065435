<!-- BEGIN: Invalid Session Modal -->
<div class="fixed z-50 inset-0 overflow-y-auto" [ngClass]="invalidSessionPopup == true ? 'block' : 'hidden'"
  aria-labelledby="modal-title" role="dialog" aria-modal="true">
  <div class="flex items-center md:items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
    <div class="fixed inset-0 bg-[#0f172acc] backdrop-blur-md transition-opacity" aria-hidden="true"></div>

    <!-- This element is to trick the browser into centering the modal contents. -->
    <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
    <div
      class="relative inline-block align-bottom bg-secondary-dark p-6 rounded-[20px] text-left overflow-hidden transform transition-all sm:my-8 sm:align-middle sm:max-w-sm">
      <div class="flex justify-between items-center">
        <div class="flex justify-between items-center">
          <h2 class="font-bold text-primary-light text-base lg:text-lg 2xl:text-xl text__yg--gradient mi--font-regular">
            Login Limit Exceeded
          </h2>
        </div>
        <!-- <div (click)="toggleModal(false)" class="bg-secondary-black cursor-pointer h-8 w-8 md:h-10 md:w-10 rounded-lg flex justify-center items-center">
                  <img src="assets/icons/x-light.svg" class="m-auto w-4 h-4 md:w-5 md:h-5" alt="bx-finance" />
              </div> -->
      </div>
      <hr class="border-grey border-b-2 border-solid my-3" />


      <div class="text-white flex justify-center gap-4 font-semibold text-lg mt-2 2xl:text-xl text-center">
        This wallet address is logged in on multiple sessions. Terminate other sessions to continue.
      </div>
      <button (click)="clearUserSession()" type="button"
        class="inline-block mt-10 btn__primary bg-[#4E1FA3]/30 border border-solid border-[#4e1fa3] w-full shadow-none">Terminate
        All Other Sessions</button>
    </div>
  </div>
</div>
<!-- END: Invalid Session Modal -->
