// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment: any = {
  production: false,
  MORALIS_API_KEY: '4JnAwyHhESf8bxp5aSwcCIcuO9ezpu7HDhibNZDgdmZT3oUAhckNJapVHMcCR8E9',
  PANCAKE_SWAP_BASE_URL: 'https://api.pancakeswap.info/api/v2/tokens/',
  API_BASE_URL: 'https://devapi.blockchainx.finance/api/v1/',
  IPFS_BASE_URL: 'https://gateway.pinata.cloud/ipfs/',
//do not change the order,double check the network logo  and symbol name,it should match the order
ALLOWED_NETWORKS: [
  '0x61', // BSC Testnet
  '0xaa36a7',
  '0x13881', // Polygon Mumbai Testnet
  '0xa869', // Avalanche Fuji Testnet
  '0xfa2', // Fantom Testnet
  '0x152', // Cronos Testnet
  '0x507', // Moonbase Alpha Testnet
  '0x1', // ETH Mainnet
  '0x38', // BSC Mainnet
  '0x89', // Polygon Mainnet
  '0xa86a', // Avalanche C-Chain Mainnet
  '0xfa', // Fantom Opera Mainnet
  '0x19', // Cronos Mainnet
  '0x504', // Moonbeam Mainnet
],
  // @dev do not change the array order,double check the network logo  and symbol name,it should match the order
  NETWORK_SYMBOL: [
    'bsc',
    'ethereum',
    'polygon',
    'avalanchee',
    'fantom',
    'coronos',
    'MoonBase',
    'ethereum',
    'bsc',
    'polygon',
    'avalanchee',
    'fantom',
    'coronos',
    'MoonBase',
  ],
  // @dev do not change the array order,double check the network logo  and symbol name,it should match the order
  NETWORK_NAME: [
    'Bsc TestNet',
    'Ethereum TestNet',
    'Polygon TestNet',
    'Avalanchee TestNet',
    'Fantom TestNet',
    'Coronos TestNet',
    'Moonbase Alpha TestNet',
    'Ethereum MainNet',
    'Bsc MainNet',
    'Polygon MainNet',
    'Avalanchee MainNet',
    'Fantom MainNet',
    'Coronos MainNet',
    'MoonBeam MainNet',
  ],
  // @dev do not change the array order,double check the network logo  and symbol name,it should match the order
  NETWORK_CURRENCY: [
    'BNB',
    'ETH',
    'MATIC',
    'AVAX',
    'FTM',
    'CRO',
    'MOON',
    'ETH',
    'BNB',
    'MATIC',
    'AVAX',
    'FTM',
    'CRO',
    'GLMR',
  ],
  transactionURI: {
    '0x61': 'https://testnet.bscscan.com/tx/',
    '0x5': 'https://goerli.etherscan.io/tx/',
    '0x13881': 'https://mumbai.polygonscan.com/tx/',
    '0xa869': 'https://testnet.snowtrace.io/tx/',
    '0xfa2': 'https://testnet.ftmscan.com/tx/',
    '0x152': 'https://testnet.cronoscan.com/tx/',
    '0x507': 'https://moonbase.moonscan.io/tx/',
    '0x1': 'https://etherscan.io/tx/',
    '0x38': 'https://bscscan.com/tx/',
    '0x89': 'https://polygonscan.com/tx/',
    '0xa86a': 'https://snowtrace.io/.tx/',
    '0xfa': 'https://ftmscan.com/tx/',
    '0x19': 'https://cronoscan.com/tx/',
    '0x504': 'https://moonscan.io/tx/',
  },
  contractURI : {
    '0x61': 'https://testnet.bscscan.com/address/',
    '0x5': 'https://goerli.etherscan.io/address/',
    '0x13881': 'https://mumbai.polygonscan.com/address/',
    '0xa869': 'https://testnet.snowtrace.io/address/',
    '0xfa2': 'https://testnet.ftmscan.com/address/',
    '0x152': 'https://testnet.cronoscan.com/address/',
    '0x507': 'https://moonbase.moonscan.io/address/',
    '0x1': 'https://etherscan.io/address/',
    '0x38': 'https://bscscan.com/address/',
    '0x89': 'https://polygonscan.com/address/',
    '0xa86a': 'https://snowtrace.io/.address/',
    '0xfa': 'https://ftmscan.com/address/',
    '0x19': 'https://cronoscan.com/address/',
    '0x504': 'https://moonscan.io/address/',
  },
  CONTRACT_ADDRESS: {
    // BSC Testnet
    '0x61': {
      TRANSACTION_URI: 'https://testnet.bscscan.com/tx/',
      STANDARD_TOKEN_CONTRACT: '0xB34f24b7b860A64ffbaB6C67b8fc47FFa861178a',
      LIQUIDITY_TOKEN_CONTRACT: '0x2eBcD880D10FB8Ad0DEcCC7443CcA483BecFAa13',
      STANDARD_TOKEN: '0x047Ad0Fed9d00AeB70A72711414328304a0Cc4E9',
      LIQUIDITY_TOKEN: '0xaefcD367a520E2295EB39F0947391a9F64baaeC1',
      LAUNCHPAD_FACTORY_CONTRACT: '0xAF9ed0370E4D098DE03BfacBf7549E644157802c',
      PRE_SALE_CONTRACT: '0x156F18e56ECCa720a1B1F79CF2F9dc338cf716B8',
      PRIVATE_SALE_CONTRACT: '0x786d3ebE18F831f5a5a1e2f387Fdec1E6365707B',
      FARMING_CONTRACT: '0x32c14855f4bCc03Ffd14e58EF7e2008488d1A3E6',
      FARMING_LP_TOKEN_CONTRACT: '0x7b7Fb5cA7c3202F005a84adc2e2bFE6416cD4bD1',
      MIGRATION_FACTORY_CONTRACT_ADDRESS: '0xd385AFbdB8E79fBc978fC8e88852ae04c23141a4',
      AIRDROP_CONTRACT: '0xC89232edeaCf9Aa546b2681A74c3f2A002C23Fa6',
      LOCKER_CONTRACT: '0x2499892e616823EB73Eb890565Bf88D5037371C7',
      STAKING_CONTRACT: '0x661076020ffe65C29E368956C1F02d2249054177',
      PANCAKE_SWAP: '0xd99d1c33f9fc3444f8101754abc46c52416550d1',
      PANCAKE_SWAP_FACTORY:'',
      SUSHI_SWAP: '0x1b02dA8Cb0d097eB8D57A175b88c7D8b47997506',
      SUSHI_SWAP_FACTORY:'',
      TOKEN0: '0x53035E4e14fb3f82C02357B35d5cC0C5b53928B4',
      TOKEN1: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
      TEST_CHAIN: 'bsc',
      NFT_GENERATOR_CONTRACT: '0x766eD0FCF6B1c71f5F017DC72aF7E4e39027D9b1',
      RPC_URL: "https://data-seed-prebsc-2-s3.binance.org:8545"
    },
    '0xaa36a7': {
      TRANSACTION_URI: 'https://sepolia.etherscan.io/tx/',
      // STANDARD_TOKEN_CONTRACT: '0xB34f24b7b860A64ffbaB6C67b8fc47FFa861178a',
      // LIQUIDITY_TOKEN_CONTRACT: '0x2eBcD880D10FB8Ad0DEcCC7443CcA483BecFAa13',
      // STANDARD_TOKEN: '0x047Ad0Fed9d00AeB70A72711414328304a0Cc4E9',
      // LIQUIDITY_TOKEN: '0xaefcD367a520E2295EB39F0947391a9F64baaeC1',
      // LAUNCHPAD_FACTORY_CONTRACT: '0xD960091e49B985A51EaB1351ee242e4EB04529ba',
      // PRE_SALE_CONTRACT: '0x0cb0bD7B36fAe14E2c1F9B7787a23F444667b3A7',
      // PRIVATE_SALE_CONTRACT: '0x7e907eB5F9D1F646b8F3B7E450Ab9337f32f12F8',
      // FARMING_CONTRACT: '0xe743019f56349D691008798F1CcE4A3895876a22',
      // STAKING_CONTRACT: '0xbBF4d00aA91f15D863Bf6b7d56FF6038deCb4198',
      // FARMING_LP_TOKEN_CONTRACT: '',
      MIGRATION_FACTORY_CONTRACT_ADDRESS: '0xf67f5a06f1e5dE5266DC3D9979D2C530FeC6cAFE',
      // AIRDROP_CONTRACT: '0x98829fFAB3106985CC4bB3a75eCe3941Fd3Ff4Ee',
      // UNI_SWAP: '0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D',
      // SUSHI_SWAP: '0x1b02dA8Cb0d097eB8D57A175b88c7D8b47997506',
      // LOCKER_CONTRACT: '0xBe0916e4Ac7Cab926Bef776d7723f6Ef3f36CFF1',
      // TOKEN0: '0x0F5D2fB29fb7d3CFeE444a200298f468908cC942',
      // TOKEN1: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
      // TEST_CHAIN: 'eth',
      // NFT_GENERATOR_CONTRACT: '0xFCb63E477F28d29D0C8340Dc9AB39E09cB6e29b6',
      RPC_URL: "https://ethereum-sepolia.blockpi.network/v1/rpc/public"
    },
    '0x5': {
      // ETH Goerli Testnet
      TRANSACTION_URI: 'https://goerli.etherscan.io/tx/',
      STANDARD_TOKEN_CONTRACT: '0xB34f24b7b860A64ffbaB6C67b8fc47FFa861178a',
      LIQUIDITY_TOKEN_CONTRACT: '0x2eBcD880D10FB8Ad0DEcCC7443CcA483BecFAa13',
      STANDARD_TOKEN: '0x047Ad0Fed9d00AeB70A72711414328304a0Cc4E9',
      LIQUIDITY_TOKEN: '0xaefcD367a520E2295EB39F0947391a9F64baaeC1',
      LAUNCHPAD_FACTORY_CONTRACT: '0xD960091e49B985A51EaB1351ee242e4EB04529ba',
      PRE_SALE_CONTRACT: '0x0cb0bD7B36fAe14E2c1F9B7787a23F444667b3A7',
      PRIVATE_SALE_CONTRACT: '0x7e907eB5F9D1F646b8F3B7E450Ab9337f32f12F8',
      FARMING_CONTRACT: '0xe743019f56349D691008798F1CcE4A3895876a22',
      STAKING_CONTRACT: '0xbBF4d00aA91f15D863Bf6b7d56FF6038deCb4198',
      FARMING_LP_TOKEN_CONTRACT: '',
      MIGRATION_FACTORY_CONTRACT_ADDRESS:'0xA5479D5ced9AbbDaC08FFeEf3dABe6b90f464F08',
      AIRDROP_CONTRACT: '0x3835b4FC9114f3f0B4aB4C8fa1b4f3F5b0a6d201',
      UNI_SWAP: '0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D',
      UNI_SWAP_FACTORY:'0x5C69bEe701ef814a2B6a3EDD4B1652CB9cc5aA6f',
      SUSHI_SWAP: '0x1b02dA8Cb0d097eB8D57A175b88c7D8b47997506',
      SUSHI_SWAP_FACTORY:'0xc35DADB65012eC5796536bD9864eD8773aBc74C4',
      LOCKER_CONTRACT: '0xC89232edeaCf9Aa546b2681A74c3f2A002C23Fa6',
      TOKEN0: '0x0F5D2fB29fb7d3CFeE444a200298f468908cC942',
      TOKEN1: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
      TEST_CHAIN: 'eth',
      NFT_GENERATOR_CONTRACT : '0xFCb63E477F28d29D0C8340Dc9AB39E09cB6e29b6',
      RPC_URL: "https://goerli.blockpi.network/v1/rpc/public"
    },
    '0x13881': {
      // Polygon Mumbai Testnet
      TRANSACTION_URI: 'https://mumbai.polygonscan.com/tx/',
      STANDARD_TOKEN_CONTRACT: '0xB34f24b7b860A64ffbaB6C67b8fc47FFa861178a',

      // 0x34D1884a00670aF97C36B594332d21036aF8036d previous addrress
      LIQUIDITY_TOKEN_CONTRACT: '0x2eBcD880D10FB8Ad0DEcCC7443CcA483BecFAa13',
      STANDARD_TOKEN: '0x047Ad0Fed9d00AeB70A72711414328304a0Cc4E9',
      LIQUIDITY_TOKEN: '0xaefcD367a520E2295EB39F0947391a9F64baaeC1',
      LAUNCHPAD_FACTORY_CONTRACT: '0xBe0916e4Ac7Cab926Bef776d7723f6Ef3f36CFF1',
      PRE_SALE_CONTRACT: '0x7469abE15006D324abBaC133302938A1151846Cc',
      PRIVATE_SALE_CONTRACT: '0xCFe4aDb4F42fC8B360919459D8E49E7C14C69Fa5',
      FARMING_CONTRACT: '0x4f3a98E0218cfA12C6f78684A3cA99deba2cffEE',
      FARMING_LP_TOKEN_CONTRACT: '',
      MIGRATION_FACTORY_CONTRACT_ADDRESS: '0xA5479D5ced9AbbDaC08FFeEf3dABe6b90f464F08',
      QUICK_SWAP: '0xa5E0829CaCEd8fFDD4De3c43696c57F7D7A678ff',
      SUSHI_SWAP: '0x1b02dA8Cb0d097eB8D57A175b88c7D8b47997506',
      AIRDROP_CONTRACT:"0x3835b4FC9114f3f0B4aB4C8fa1b4f3F5b0a6d201",
      LOCKER_CONTRACT: '0xC89232edeaCf9Aa546b2681A74c3f2A002C23Fa6',
      TOKEN0: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
      TOKEN1: '0x28424507fefb6f7f8E9D3860F56504E4e5f5f390',
      TEST_CHAIN: 'polygon',
      RPC_URL: "https://endpoints.omniatech.io/v1/matic/mumbai/public"
    },
    '0xa869': {
      // Avalanche Fuji Testnet
      TRANSACTION_URI: 'https://testnet.snowtrace.io/tx/',
      STANDARD_TOKEN_CONTRACT: '0x2eBcD880D10FB8Ad0DEcCC7443CcA483BecFAa13',
      LIQUIDITY_TOKEN_CONTRACT: '0x36A98A0362acB0B08Ab8E403dbFA3086adbA9099',
      STANDARD_TOKEN: '0xb3603130a0E8EC7Dc413F6639cF08200e442aA5B',
      LIQUIDITY_TOKEN: '0xea61a9617468304288c9332531b6F6ce102AA57c',
      LAUNCHPAD_FACTORY_CONTRACT: '0x9c4151813FD2Cf6F070A79e7e1100DDf81c7E4e7',
      PRE_SALE_CONTRACT: '0x0A8a33fb9225075c35743649869070A2E3bc6c83',
      PRIVATE_SALE_CONTRACT: '0x1367a2e2fc579841177ADfffbA886cB5bF460D3d',
      FARMING_CONTRACT: '0xEe1fA37c75E440b5fB66424d291047dC36f95A4b',
      FARMING_LP_TOKEN_CONTRACT: '',
      MIGRATION_FACTORY_CONTRACT_ADDRESS:'0xA5479D5ced9AbbDaC08FFeEf3dABe6b90f464F08',
      SUSHI_SWAP: '0x1b02dA8Cb0d097eB8D57A175b88c7D8b47997506',
      AIRDROP_CONTRACT: '0xa52b49197366844d74A5f070d56569CbCdfBd405',
      LOCKER_CONTRACT: '0xA07FF60ba4bcAE965D2aeDdA56A42118B56e23E3',
      TOKEN0: '0xd586E7F844cEa2F87f50152665BCbc2C279D8d70',
      TOKEN1: '0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7',
      TEST_CHAIN: 'avalanche',
      RPC_URL: "https://endpoints.omniatech.io/v1/avax/fuji/public"
    },
    '0xfa2': {
      // Fantom Testnet
      TRANSACTION_URI: 'https://testnet.ftmscan.com/tx/',
      AIRDROP_CONTRACT: '0x3835b4FC9114f3f0B4aB4C8fa1b4f3F5b0a6d201',
      STANDARD_TOKEN_CONTRACT: '0xB34f24b7b860A64ffbaB6C67b8fc47FFa861178a',
      LIQUIDITY_TOKEN_CONTRACT: '0x2eBcD880D10FB8Ad0DEcCC7443CcA483BecFAa13',
      STANDARD_TOKEN: '0x047Ad0Fed9d00AeB70A72711414328304a0Cc4E9',
      LIQUIDITY_TOKEN: '0xaefcD367a520E2295EB39F0947391a9F64baaeC1',
      LAUNCHPAD_FACTORY_CONTRACT: '0x635c947aEdB01814d31d47D7D9Ca661559180556',
      PRE_SALE_CONTRACT: '0x473B5F1cCb30a885133176a8664c7435cfe20A19',
      PRIVATE_SALE_CONTRACT: '0xD3C39C18658d65b9249D89c727bb5792ce4804b8',
      STAKING_CONTRACT : '0xd5CCa368dd86294a84567B8CEE47B29FCED970DE',
      FARMING_CONTRACT: '0xEe1fA37c75E440b5fB66424d291047dC36f95A4b',
      FARMING_LP_TOKEN_CONTRACT: '',
      MIGRATION_FACTORY_CONTRACT_ADDRESS:'0xA5479D5ced9AbbDaC08FFeEf3dABe6b90f464F08',
      SPOOKY_SWAP: '0xa6AD18C2aC47803E193F75c3677b14BF19B94883',
      LOCKER_CONTRACT: '0xC89232edeaCf9Aa546b2681A74c3f2A002C23Fa6',
      NFT_GENERATOR_CONTRACT : '0x43c7445bB3A7531CC5d009360D029B350B3c9DFD',
      TOKEN0: '',
      TOKEN1: '',
      TEST_CHAIN: '',
      RPC_URL: "https://rpc.ankr.com/fantom_testnet"
    },
    '0x152': {
      // Cronos Testnet
      TRANSACTION_URI: 'https://cronoscan.com/tx/',
      AIRDROP_CONTRACT: '0x3835b4FC9114f3f0B4aB4C8fa1b4f3F5b0a6d201',
      STANDARD_TOKEN_CONTRACT: '0xB34f24b7b860A64ffbaB6C67b8fc47FFa861178a',
      LIQUIDITY_TOKEN_CONTRACT: '0x2eBcD880D10FB8Ad0DEcCC7443CcA483BecFAa13',
      STANDARD_TOKEN: '0x047Ad0Fed9d00AeB70A72711414328304a0Cc4E9',
      LIQUIDITY_TOKEN: '0xaefcD367a520E2295EB39F0947391a9F64baaeC1',
      LAUNCHPAD_FACTORY_CONTRACT: '0xE8A61F0913625a49a2813ddf0679a4cCe576A61D',
      PRE_SALE_CONTRACT: '0x4f3a98E0218cfA12C6f78684A3cA99deba2cffEE',
      PRIVATE_SALE_CONTRACT: '0x0A8a33fb9225075c35743649869070A2E3bc6c83',
      FARMING_CONTRACT: '0x58941B3b28dd9E72Ff43C311AB5e4aFB59b96291',
      FARMING_LP_TOKEN_CONTRACT: '',

      // 0x0ce8932Aabb37D0b15Ec1dC5Fcd7AB4401Ec9d55 previous adress
      MIGRATION_FACTORY_CONTRACT_ADDRESS:'0xA5479D5ced9AbbDaC08FFeEf3dABe6b90f464F08',
      PHOTON_SWAP: '0x2fFAa0794bf59cA14F268A7511cB6565D55ed40b',
      LOCKER_CONTRACT: '0xC89232edeaCf9Aa546b2681A74c3f2A002C23Fa6',
      TOKEN0: '',
      TOKEN1: '',
      TEST_CHAIN: '',
      RPC_URL: "https://evm-t3.cronos.org"
    },
    '0x507': {
      // Moonbase Alpha Testnet
      TRANSACTION_URI: 'https://moonbase.moonscan.io/tx/',
      STANDARD_TOKEN_CONTRACT: '0xB34f24b7b860A64ffbaB6C67b8fc47FFa861178a',
      LIQUIDITY_TOKEN_CONTRACT: '0x2eBcD880D10FB8Ad0DEcCC7443CcA483BecFAa13',
      STANDARD_TOKEN: '0x047Ad0Fed9d00AeB70A72711414328304a0Cc4E9',
      LIQUIDITY_TOKEN: '0xaefcD367a520E2295EB39F0947391a9F64baaeC1',
      LAUNCHPAD_FACTORY_CONTRACT: '0x1367a2e2fc579841177ADfffbA886cB5bF460D3d',
      PRE_SALE_CONTRACT: '0x0FE6853499f957FE8fa71c5Ca87dEDb75F691420',
      PRIVATE_SALE_CONTRACT: '0x4f3a98E0218cfA12C6f78684A3cA99deba2cffEE',
      FARMING_CONTRACT: '0xf9d3BF61a4c18D5433943a385cdF5A41b2fA51DC',
      FARMING_LP_TOKEN_CONTRACT: '',
      MIGRATION_FACTORY_CONTRACT_ADDRESS:'0xA5479D5ced9AbbDaC08FFeEf3dABe6b90f464F08',
      SOLARBEAM: '0xaac55436adC045E14163e700D9d668F79F9a3800',
      AIRDROP_CONTRACT: '0x3835b4FC9114f3f0B4aB4C8fa1b4f3F5b0a6d201',
      LOCKER_CONTRACT: '0x851F40a5f8B7950cd041Faf2b906023cf1dE2eeB',
      TOKEN0: '',
      TOKEN1: '',
      TEST_CHAIN: '',
      RPC_URL: "https://rpc.testnet.moonbeam.network"
    },
    '0x1': {
      // ETH Mainnet
      TRANSACTION_URI: 'https://etherscan.io/tx/',
      STANDARD_TOKEN_CONTRACT: '',
      LIQUIDITY_TOKEN_CONTRACT: '',
      LAUNCHPAD_FACTORY_CONTRACT: '',
      PRE_SALE_CONTRACT: '',
      PRIVATE_SALE_CONTRACT: '',
      FARMING_CONTRACT: '',
      STAKING_CONTRACT: '',
      FARMING_LP_TOKEN_CONTRACT: '',
      MIGRATION_FACTORY_CONTRACT_ADDRESS:'',
      AIRDROP_CONTRACT: '',
      UNI_SWAP: '0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D',
      SUSHI_SWAP: '0xd9e1cE17f2641f24aE83637ab66a2cca9C378B9F',
      LOCKER_CONTRACT: '',
      TOKEN0: '',
      TOKEN1: '',
      TEST_CHAIN: 'eth',
      NFT_GENERATOR_CONTRACT : '',
      RPC_URL: "https://eth-rpc.gateway.pokt.network"
    },
    '0x38': {
      // BSC Mainnet
      TRANSACTION_URI: 'https://bscscan.com/tx/',
      STANDARD_TOKEN_CONTRACT: '0xBbA26E1ACC58BDA55226e08373fF0d9Df1369CB6',
      LIQUIDITY_TOKEN_CONTRACT: '0x5AD03514bA8be555Fc821318aA735362d5B44c8e',
      LAUNCHPAD_FACTORY_CONTRACT: '',
      PRE_SALE_CONTRACT: '',
      PRIVATE_SALE_CONTRACT: '',
      FARMING_CONTRACT: '',
      FARMING_LP_TOKEN_CONTRACT: '',
      MIGRATION_FACTORY_CONTRACT_ADDRESS: '0xc95f5eA615E52985dd03ac818BaD515F0e2A9A65',
      AIRDROP_CONTRACT: '0xA6955300123F32524829786Df55C0aC0e5e954Ca',
      LOCKER_CONTRACT: '',
      STAKING_CONTRACT: '',
      PANCAKE_SWAP: '0x10ED43C718714eb63d5aA57B78B54704E256024E',
      SUSHI_SWAP: '0x1b02dA8Cb0d097eB8D57A175b88c7D8b47997506',
      TOKEN0: '',
      TOKEN1: '',
      TEST_CHAIN: 'bsc',
      NFT_GENERATOR_CONTRACT: '',
      RPC_URL: "https://bsc.blockpi.network/v1/rpc/public"
    },
    '0x89': {
      // Polygon Mainnet
      TRANSACTION_URI: 'https://polygonscan.com/tx/',
      STANDARD_TOKEN_CONTRACT: '0xBbA26E1ACC58BDA55226e08373fF0d9Df1369CB6',
      LIQUIDITY_TOKEN_CONTRACT: '0x5AD03514bA8be555Fc821318aA735362d5B44c8e',
      LAUNCHPAD_FACTORY_CONTRACT: '',
      PRE_SALE_CONTRACT: '',
      PRIVATE_SALE_CONTRACT: '',
      FARMING_CONTRACT: '',
      FARMING_LP_TOKEN_CONTRACT: '',
      MIGRATION_FACTORY_CONTRACT_ADDRESS: '0x34871244A35FA2D50F6743D161627F3433466010',
      QUICK_SWAP: '0xa5E0829CaCEd8fFDD4De3c43696c57F7D7A678ff',
      SUSHI_SWAP: '0x1b02dA8Cb0d097eB8D57A175b88c7D8b47997506',
      AIRDROP_CONTRACT:'0x0f1409E55acA10f26a7C603CD3aA47dd422A46bD',
      LOCKER_CONTRACT: '',
      TOKEN0: '',
      TOKEN1: '',
      TEST_CHAIN: 'polygon',
      RPC_URL: "https://polygon.blockpi.network/v1/rpc/public"
    },
    '0xa86a': {
      // Avalanche C Chain Mainnet
      TRANSACTION_URI: 'https://snowtrace.io/tx/',
      STANDARD_TOKEN_CONTRACT: '0xBbA26E1ACC58BDA55226e08373fF0d9Df1369CB6',
      LIQUIDITY_TOKEN_CONTRACT: '0x5AD03514bA8be555Fc821318aA735362d5B44c8e',
      LAUNCHPAD_FACTORY_CONTRACT: '',
      PRE_SALE_CONTRACT: '',
      PRIVATE_SALE_CONTRACT: '',
      FARMING_CONTRACT: '',
      FARMING_LP_TOKEN_CONTRACT: '',
      MIGRATION_FACTORY_CONTRACT_ADDRESS:'0x34871244A35FA2D50F6743D161627F3433466010',
      SUSHI_SWAP: '0x1b02dA8Cb0d097eB8D57A175b88c7D8b47997506',
      AIRDROP_CONTRACT: '0xFFB83B08E66208315480F334813169A0b22033AB',
      LOCKER_CONTRACT: '',
      TOKEN0: '',
      TOKEN1: '',
      TEST_CHAIN: 'avalanche',
      RPC_URL: "https://avalanche.blockpi.network/v1/rpc/public"
    },
    '0xfa': {
      // Fantom Mainnet
      TRANSACTION_URI: 'https://ftmscan.com/tx/',
      AIRDROP_CONTRACT: '0xFFB83B08E66208315480F334813169A0b22033AB',
      STANDARD_TOKEN_CONTRACT: '0xBbA26E1ACC58BDA55226e08373fF0d9Df1369CB6',
      LIQUIDITY_TOKEN_CONTRACT: '0x5AD03514bA8be555Fc821318aA735362d5B44c8e',
      LAUNCHPAD_FACTORY_CONTRACT: '',
      PRE_SALE_CONTRACT: '',
      PRIVATE_SALE_CONTRACT: '',
      FARMING_CONTRACT: '',
      FARMING_LP_TOKEN_CONTRACT: '',
      MIGRATION_FACTORY_CONTRACT_ADDRESS:'0x34871244A35FA2D50F6743D161627F3433466010',
      SPOOKY_SWAP: '0xF491e7B69E4244ad4002BC14e878a34207E38c29',
      LOCKER_CONTRACT: '',
      TOKEN0: '',
      TOKEN1: '',
      TEST_CHAIN: '',
      RPC_URL: "https://fantom-mainnet.public.blastapi.io"
    },
    '0x19': {
      // Cronos Mainnet
      TRANSACTION_URI: 'https://cronoscan.com/tx/',
      AIRDROP_CONTRACT: '0xB4c7BA517400922809692F7993917Fa658a91cd5',
      STANDARD_TOKEN_CONTRACT: '0xBbA26E1ACC58BDA55226e08373fF0d9Df1369CB6',
      LIQUIDITY_TOKEN_CONTRACT: '0x5AD03514bA8be555Fc821318aA735362d5B44c8e',
      LAUNCHPAD_FACTORY_CONTRACT: '',
      PRE_SALE_CONTRACT: '',
      PRIVATE_SALE_CONTRACT: '',
      FARMING_CONTRACT: '',
      FARMING_LP_TOKEN_CONTRACT: '',
      MIGRATION_FACTORY_CONTRACT_ADDRESS:'0x34871244A35FA2D50F6743D161627F3433466010',
      PHOTON_SWAP: '0x69004509291F4a4021fA169FafdCFc2d92aD02Aa',
      LOCKER_CONTRACT: '',
      TOKEN0: '',
      TOKEN1: '',
      TEST_CHAIN: '',
      RPC_URL: "https://evm.cronos.org"
    },
    '0x504': {
      // Moonbeam Mainnet
      TRANSACTION_URI: 'https://moonscan.io/tx/',
      STANDARD_TOKEN_CONTRACT: '0xBbA26E1ACC58BDA55226e08373fF0d9Df1369CB6',
      LIQUIDITY_TOKEN_CONTRACT: '0x5AD03514bA8be555Fc821318aA735362d5B44c8e',
      LAUNCHPAD_FACTORY_CONTRACT: '',
      PRE_SALE_CONTRACT: '',
      PRIVATE_SALE_CONTRACT: '',
      FARMING_CONTRACT: '',
      FARMING_LP_TOKEN_CONTRACT: '',
      MIGRATION_FACTORY_CONTRACT_ADDRESS:'0x34871244A35FA2D50F6743D161627F3433466010',
      SOLARBEAM: '0xd3B02Ff30c218c7f7756BA14bcA075Bf7C2C951e', // SolarFlare
      AIRDROP_CONTRACT: '0xFFB83B08E66208315480F334813169A0b22033AB',
      LOCKER_CONTRACT: '',
      TOKEN0: '',
      TOKEN1: '',
      TEST_CHAIN: '',
      RPC_URL: "https://rpc.ankr.com/moonbeam"
    },

    '0x635ae020': {
      // Harmony Testnet
      TRANSACTION_URI: 'https://explorer.ps.hmny.io/tx/',
      AIRDROP_CONTRACT: '0x851F40a5f8B7950cd041Faf2b906023cf1dE2eeB',
      STANDARD_TOKEN_CONTRACT: '0xb4bea068f34269c47d6696b350259ed7d5800e12',
      LIQUIDITY_TOKEN_CONTRACT: '0xf7cbF710fb09b7c0B6bEBf3aE15E95f5f55bBe3f',

      LAUNCHPAD_FACTORY_CONTRACT: '0x126B66d239Be59Da324823Dc4A8A00Ae3427ca41',
      PRE_SALE_CONTRACT: '0xaB4B1c310e72c0cb0A9049232B3a6fe743a27F50',
      PRIVATE_SALE_CONTRACT: '0xf8E3e20D19809af180cb9803e7f6a4C3e789a2bA',
      FARMING_CONTRACT: '0x19D84556CFF4F0E4418dc1bcA7b6C80dB4331e10',
      FARMING_LP_TOKEN_CONTRACT: '',
      MIGRATION_FACTORY_CONTRACT_ADDRESS:
        '0x4a1Cc4a6b226818A3e4335eB32Bd67ee1d81EF66',
      LOCKER_CONTRACT: '0x1554f07E77CDADc61920afD63E10729B585953a4',
      TOKEN0: '',
      TOKEN1: '',
      TEST_CHAIN: '',
      RPC_URL: "https://data-seed-prebsc-2-s3.binance.org:8545"
    },
  },
};
