import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NotFoundComponent } from './shared/not-found/not-found.component';
import { TermsOfServiceComponent } from './shared/terms-of-service/terms-of-service.component';

const routes: Routes = [
  { path: 'landing', loadChildren: () => import('./modules/landing/landing.module').then(m => m.LandingModule)},
  { path: 'token-generator', loadChildren: () => import('./modules/token-generator/token-generator.module').then(m => m.TokenGeneratorModule),data:{'title': "Create Custom Token | Token Generator - BX Finance"} },
  { path: 'launchpad', loadChildren: () => import('./modules/launchpad/launchpad.module').then(m => m.LaunchpadModule),data:{'title':""}},
  { path: 'migration', loadChildren: () => import('./modules/migration/migration.module').then(m => m.MigrationModule),data:{'title': "Create your own Migrator platform | Swap Old to New Token - BX Finance"} },
  { path: 'farming', loadChildren: () => import('./modules/farming/farming.module').then(m => m.FarmingModule),data:{'title':""}},
  { path: 'airdrop', loadChildren: () => import('./modules/airdrop/airdrop.module').then(m => m.AirdropModule),data:{'title': "Airdrop Your Tokens | Token Bulk Transfer to Multiple Addresses - BX Finance"} },
  { path: 'locker', loadChildren: () => import('./modules/locker/locker.module').then(m => m.LockerModule),data:{'title':""}},
  { path: 'stake', loadChildren: () => import('./modules/staking/staking.module').then(m => m.StakingModule),data:{'title':""}},
  { path: 'nft-airdrop', loadChildren: () => import('./modules/nft/airdrop/airdrop.module').then(m => m.AirdropModule),data:{'title':""}},
  { path: 'nft', loadChildren: () => import('./modules/nft/nft-generator/nft-generator.module').then(m => m.NftGeneratorModule),data:{'title':""}},
  { path: 'nft-bulk-mint', loadChildren: () => import('./modules/nft/bulk-mint/bulk-mint.module').then(m => m.BulkMintModule),data:{'title':""}},
  { path: 'nft-single-mint', loadChildren: () => import('./modules/nft/single-mint/single-mint.module').then(m => m.SingleMintModule),data:{'title':""}},
  { path: '', loadChildren: () => import('./modules/landing-new/landing-new.module').then(m => m.LandingNewModule) },
  { path: '404', component: NotFoundComponent},
  { path: 'terms-of-service', component: TermsOfServiceComponent},
  { path: 'privacy-policy', loadChildren: () => import('./modules/privacy-policy/privacy-policy.module').then(m => m.PrivacyPolicyModule) },
  { path: 'vesting', loadChildren: () => import('./modules/token-vesting/token-vesting.module').then(m => m.TokenVestingModule) },
  { path: 'web-stories', loadChildren: () => import('./modules/web-stories/web-stories.module').then(m => m.WebStoriesModule) },
  { path: 'new-token-generator', loadChildren: () => import('./modules/new-token-generator/new-token-generator.module').then(m => m.NewTokenGeneratorModule),data:{'title': "Create Custom Token | Token Generator - BX Finance"} },
  { path: '**', redirectTo: '/404'}

];
// { path: 'coming-soon', loadChildren: () => import('./modules/coming-soon/coming-soon.module').then(m => m.ComingSoonModule) },

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled',anchorScrolling:'enabled'})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
